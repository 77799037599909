import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'enable-schedule-dialog',
  templateUrl: '../templates/enable-schedule-dialog.html',
})
export class EnableScheduleDialog {
  constructor(
    public dialogRef: MatDialogRef<EnableScheduleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
