import { Component, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Validators, FormControl } from "@angular/forms";

import {AuthService} from "../services/auth-service";
import {NotifyService} from "../../lib/services/notify.service";

@Component({
  selector: "reset-password-cmp",
  templateUrl: "../templates/reset-password.html",
  styleUrls: ["../styles/reset-password.scss"]
})

export class ResetPasswordCmp implements OnDestroy{
  title: string = "Reset Password";
  email: string;
  loading = false;
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  isEmailSent: boolean = false;

  constructor(
    private _route: ActivatedRoute,
    private _authService: AuthService,
    private _router: Router,
    private _notifyService: NotifyService
  ) {  }

  ngOnDestroy() {

  }

  resetPassword() {
    this._authService.resetPassword(this.email)
      .subscribe(
        () => {
          this.isEmailSent = true;
        },
        (result) => {
          const error = result.error
          console.log('error1 - ', error);
          this.loading = false;
          let message = 'Сталася помилка. Спробуйте ще раз пізніше';

          if (error.customError) {
            if (error.customError === 'USER_NOT_FOUND') {
              message = 'Користувача з таким емейлом не знайдено';
            }
          }

          this._notifyService.showMessage({
            text: message || 'Incorrect password or email',
            type: "error",
            duration: 15000
          });

        }
      )
  }
}
