import {Component, OnInit, OnDestroy } from "@angular/core";
import {AuthService} from "../../../auth/services/auth-service";
import {Router} from '@angular/router';

import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Socket } from 'ng-socket-io';

import { MatDialog } from '@angular/material';

import { NotifyService } from '../../../lib/services/notify.service';
import {getToken} from '../../../utils/authTokens';
import {Observable} from "rxjs";
import {ALL_RELAY_TYPES} from "../../../relay/constants";
import {TelegramIntegrationModal} from "../../../relay/components/modals/telegram-integration.modal";
import {AskFirmwareModal} from "./ask-firmware.modal";
import {UserService} from "../../../user/services/user-service";
import {PRODUCER_ROLE} from "../../constants";

@Component({
  selector: "test-relay-list",
  templateUrl: "../templates/test-relay-list.html",
  styleUrls: ["../styles/test-relay-list.scss"]
})
export class TestRelayListComponent implements OnInit, OnDestroy {
  user: any = {};
  relays: any;
  relaysLength: number = 0;
  page: number = 1;
  pages: number = 1;
  relaysInterval: any;
  ENDPOINT: string = 'api/users/';
  loading: boolean = true;
  creatingRelay: boolean = false;
  error: any;
  loadingFirmware: boolean = false;
  serialNumberFilter: string = '';
  countOfCreatingRelay: number = 0;
  showCountRelayField: boolean = false;
  firmwareFilter: string = ''
  firmwares: any = [];
  choosenFirmware: string = '';
  choosenRelaySN: string[] = [];
  typesRelay: string[] = Object.values(ALL_RELAY_TYPES);
  isBroken: boolean | null = null;
  typeRelay: string = '';
  onlineStatus: boolean | null = null
  isProducer: boolean = true;

  constructor(private _authService: AuthService,
              private _authHttp: HttpClient,
              private _router: Router,
              private socket: Socket,
              private _notifyService: NotifyService,
              private _dialog: MatDialog,
              private _userService: UserService,
  ) {
    this.user = {};
    this.relays = [];
  }

  ngOnInit() {
    this._userService.getMe()
      .subscribe(
        (user) => {
          this.user = user;
          this.isProducer = this.user.adminRole === PRODUCER_ROLE;
        }
      );

    this.getRelays(1, 50);

    this.relaysInterval = setInterval(() => {
      this.getRelays();
    }, 60000);
  }

  ngOnDestroy() {
    if (this.relaysInterval) {
      clearInterval(this.relaysInterval);
    }
  }

  createNewRelay(): void {
    let endpoint = 'api/admin/relay';
    this.creatingRelay = true;
    this._authHttp.post(endpoint, {
      countRelays: this.countOfCreatingRelay,
    })
      .subscribe(
        (success)=> {
          this.creatingRelay = true;
          console.log('success', success);

          if (success && success['relays'] && success['relays'].length > 0) {
            this._notifyService.showMessage({
              message: `Created relays: ${success['acceptedRelays'] || 0}, failed: ${success['rekectedRelays'] || 0}`,
              type: 'success'
            });
            this.getRelays();
          }else {
            this._notifyService.showMessage({
              message: `Some errors`,
              type: 'error'
            })
          }

        },
        (error) => {
          this.creatingRelay = true;
          console.log(error);
          this.error = error && error.message || error;
          this._notifyService.showMessage({
            message: this.error,
            type: 'error'
          })
        }
      )
  }

  submitFilter(event: KeyboardEvent) {
    if (event.key === "Enter") {
      this.loading = true;
      this.resetFilters();
      this.getRelays();
    }
  }

  prepareCreateNewRelay(event: KeyboardEvent) {
    if (typeof this.countOfCreatingRelay !== "number") {
      return;
    }

    if (event.key === "Enter") {
      this.loading = true;
      this.showCountRelayField = false;
      this.createNewRelay();
    }
  }

  resetFilters() {
    this.choosenRelaySN = [];
  }

  getRelays(page:number = 1, limit: number = 50) {
    this.loading = true;
    this.relays = []
    let endpoint = 'api/admin/relay?page=' + page + '&limit=' + limit +
      '&serialNumber=' + this.serialNumberFilter.toLowerCase() + '&firmware=' + this.firmwareFilter.toLowerCase();

    if (typeof this.onlineStatus === 'boolean') {
      endpoint += `&online=${this.onlineStatus}`
    }

    this._authHttp.get(endpoint)
      .subscribe(
        (success) => {

          this.relays = success["relays"]?success["relays"]:[];
          this.relaysLength = success["length"]?success["length"]:0;
          this.firmwares = success["firmwares"]? success["firmwares"]: [];

          this.page = page;
          this.pages = Math.ceil(this.relaysLength/limit);

          this.loading = false;

          console.log('this.relays ', this.relays);
          this.loading = false;
        },
        (error) => {
          console.log('this.relays error ', error);
          this.loading = false;
          this._notifyService.showMessage({
            message: 'Сталася помилка',
            type: 'error'
          });
        }
      );
  }

  sortRelaysBy(type: string) {
    this.relays = this.relays.sort(function(a, b) {
      return a[type] < b[type];
    })
  }

  checkOnline(relay: any) {
    const now = +new Date();
    const diff = 3 * 60 * 1000;
    const relayDate = +new Date(relay.lastConnection);

    return now - relayDate > diff? 'red': 'green';
  }

  addFirmware(event) {
    const UPLOAD_ENDPOINT = '/api/admin/uploadFirmware';

    let fileList: FileList = event.target.files;
    if(fileList.length > 0) {
      let file: File = fileList[0];
      let formData:FormData = new FormData();
      formData.append('uploadFile', file, file.name);
      let params = new HttpParams();

      let headers = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data');
      headers.append('Accept', 'application/json');
      headers.append('enctype', 'multipart/form-data');

      const options = {
        params: params,
        headers: headers
      };

      this.loadingFirmware = true;

      this._authHttp.post(UPLOAD_ENDPOINT + `?name=${file.name}`, formData, options)
        .subscribe(
          data => {
            console.log('success')
            this.loadingFirmware = false;
            this._notifyService.showMessage({
              text: 'Uploaded',
              type: 'success'
            });

            event.target.value = '';
            event.target.files = [];
          },
          error => {
            console.log(error);
            this.loadingFirmware = false;
            this._notifyService.showMessage({
              text: 'Upload failed',
              type: 'error'
            })
          }
        )
    }
  }

  updateFirmware() {
    console.log("updateFirmware ");

    if (!this.choosenRelaySN || !this.choosenRelaySN.length || !this.choosenFirmware) {
      console.log('No firmware to update')
      this._notifyService.showMessage({
        text: 'No firmware to update',
        type: "error"
      });
      return
    }
    this.socket.emit('admin.relay-update-firmware', {
        serialNumbers: this.choosenRelaySN,
        firmware: this.choosenFirmware,
        token: getToken()
      },
      (data) => {
        const {success, message} = data;

        if (!success) {
          this._notifyService.showMessage({
            text: message || 'Сталася помилка',
            type: 'error'
          });
        } else {
          this._notifyService.showMessage({
            text: message || 'Операція прошла успішно',
            type: 'success'
          });
        }
      });
    this.resetFilters();
  }

  toggleRelay(serialNumber: string) {
    if (serialNumber === 'ALL') {
      this.choosenRelaySN = this.choosenRelaySN.length? []: this.relays.map(r => r.serialNumber)
    }else {
      this.choosenRelaySN = this.choosenRelaySN.includes(serialNumber)?
        this.choosenRelaySN.filter(sn => sn !== serialNumber):
        [...this.choosenRelaySN, serialNumber]
    }
  }

  updateProdRelay(type: string): void {
    this.loading = true;
    let endpoint = `api/admin/relay/updateProdRelays`;

    const body: {serialNumbers: string[], typeRelay?: string, isBroken?: boolean} = {
      serialNumbers: this.choosenRelaySN
    };

    if (type === 'isBroken' && this.isBroken === null) {
      this._notifyService.showMessage({
        text: 'isBroken не коректне',
        type: "error"
      });
      return;
    }

    if (type === 'typeRelay' && !this.typeRelay) {
      this._notifyService.showMessage({
        text: 'typeRelay не коректне',
        type: "error"
      });
      return;
    }

    if (type === 'isBroken' && this.isBroken !== null) {
      body.isBroken = this.isBroken
    }

    if (type === 'typeRelay' && this.typeRelay) {
      body.typeRelay = this.typeRelay;
    }

    this._authHttp.post(endpoint, body)
      .subscribe(
        (success) => {
          this.relays.forEach(r => {
            if (this.choosenRelaySN.includes(r.serialNumber)) {
              if (type === 'isBroken' && this.isBroken !== null) {
                r.isBroken = this.isBroken
              }

              if (type === 'typeRelay' && this.typeRelay) {
                r.typeRelay = this.typeRelay;
              }
            }
          })
          this.typeRelay = '';
          this.isBroken = null;

          this.loading = false;
          this._notifyService.showMessage({
            text: 'Збережено',
            type: "success"
          });
        },
        (error) => {
          console.log('this.relay error ', error);
          this._notifyService.showMessage({
            text: 'Помилка при збереженні',
            type: "error"
          });
          this.typeRelay = '';
          this.isBroken = null;
          this.loading = false;
        }
      );
  }

  openAskFirmwareModal() {
    let dialogRef = this._dialog.open(AskFirmwareModal, {
      width: '500px',
      data: {
        choosenFirmware: this.choosenFirmware,
        serialNumberFilter: this.serialNumberFilter,
        firmwareFilter: this.firmwareFilter,
        relaysSN: this.choosenRelaySN,
      }
    });


    dialogRef.afterClosed().subscribe((result) => {
      console.log('openAskFirmwareModal', result);
      if (!result || !result.success) {
        return;
      }

      this.updateFirmware();
    })
  }

  removeFirmware(firmware) {
    const isRemoving = confirm(`Точно видалити прошивку ${firmware}?`);
    const endpoint = `api/admin/firmwares/removeFirmware`;
    console.log(123, firmware, isRemoving);
    if (isRemoving) {
      this._authHttp.post(endpoint, {
        firmware
      })
        .subscribe(
          (success)=> {
            console.log('success', success);

            if (success && success['success']) {
              this._notifyService.showMessage({
                message: `Removed ${firmware}`,
                type: 'success'
              });
              this.firmwares = this.firmwares.filter(firm => firm !== firmware);
            }else {
              this._notifyService.showMessage({
                message: `Some errors`,
                type: 'error'
              })
            }

          },
          (error) => {
            console.log(error);
            this.error = error && error.message || error;
            this._notifyService.showMessage({
              message: this.error,
              type: 'error'
            })
          }
        )
    }
  }

  removeAllReadingsForRelays(): void {
    let endpoint = `api/admin/relay/removeAllReadings`;

    const confirmed = confirm("Підтверджуєте видалення даних з обраних приладів?");

    if (!confirmed) {
      return;
    }

    this.loading = true;

    const body: {serialNumbers: string[]} = {
      serialNumbers: this.choosenRelaySN
    };

    this._authHttp.post(endpoint, body)
      .subscribe(
        (success) => {
          console.log('Видалення даних ', success);
          this.loading = false;
          this._notifyService.showMessage({
            text: 'Видалення даних у процесі',
            type: "success"
          });
        },
        (error) => {
          console.log('Помилка при видалення даних ', error);
          this._notifyService.showMessage({
            text: 'Помилка при видалення даних',
            type: "error"
          });
          this.loading = false;
        }
      );
  }

}


