import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {FormControl} from "@angular/forms";
import * as moment from "moment";
import {NotifyService} from "../../lib/services/notify.service";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from "@angular/material-moment-adapter";

@Component({
  selector: 'schedule-event-dialog',
  templateUrl: '../templates/scheduleEventDialog.html',
  styleUrls: ["../styles/relay-schedule.scss"],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    {provide: MAT_DATE_LOCALE, useValue: 'uk-UA'},

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class ScheduleEventDialog {
  fromDate = new FormControl(new Date());
  toDate = new FormControl(new Date());
  fromStr: string = moment().format('DD MMM');
  toStr: string =  moment().format('DD MMM');
  fromTimeStr: string = moment().format('HH:mm');
  toTimeStr: string = moment().format('HH:mm');
  showFromInput: boolean = false;
  showFromTime: boolean = false;
  showToTime: boolean = false;
  showToInput: boolean = false;
  hours: string[] = [];
  minutes: string[] = [];
  hourFrom: string = moment().format('HH');
  hourTo: string = moment().format('HH');
  minutesFrom: string = moment().format('mm');
  minutesTo: string = moment().format('mm');
  repeatMode: string = '0';

  constructor(
    public dialogRef: MatDialogRef<ScheduleEventDialog>,
    private notifyService: NotifyService,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    for (let i = 0; i < 60; i++) {
      let str = i > 9 ? i.toString():`0${i}`

      if (i < 24) {
        this.hours.push(str)
      }

      this.minutes.push(str)
    }

    let defaultEvent = {
      time_start: +new Date(),
      time_stop: +new Date(),
      time_end: +new Date(),
      cond_relay: true,
      repeat_mode: 0
    }

    data.event.time_start = data.event.time_start? data.event.time_start * 1000: defaultEvent.time_start;
    data.event.time_stop = data.event.time_stop? data.event.time_stop * 1000: defaultEvent.time_stop;
    data.event.time_end = data.event.time_end? data.event.time_end * 1000: defaultEvent.time_end;

    data.event = {...defaultEvent, ...data.event}

    let from = moment(data.event.time_start)
    let to = moment(data.event.time_stop)

    this.fromDate = new FormControl(new Date(+from));
    this.toDate = new FormControl(new Date(+to));
    this.fromStr = from.format('DD MMM');
    this.toStr = to.format('DD MMM');
    this.fromTimeStr = from.format('HH:mm');
    this.toTimeStr = to.format('HH:mm');
    this.hourFrom = from.format('HH');
    this.hourTo = to.format('HH');
    this.minutesFrom = from.format('mm');
    this.minutesTo = to.format('mm');
    this.repeatMode = data.event.time_repeat?data.event.time_repeat.toString():'0'
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  changeDayInput(field: string, event: any){
    let date;

    if (field === 'start'){
      date = moment(event.value)

      this.fromStr = date.format('DD MMM');
    }else if (field === 'end') {
      date = moment(event.value)

      this.toStr = date.format('DD MMM');
    }
  }

  changeTimeInput(event: any){
    console.log(event);
  }

  validateSchedule(event: any) {
    let result = true;

    if (event.time_start >= event.time_stop) {
      result = false;
      this.notifyService.showMessage({
        message: 'Час старту події більше часу закінчення',
        type: 'error'
      });
    }
    if (event.time_stop > event.time_end) {
      result = false;
      this.notifyService.showMessage({
        message: 'Невалідні дані події. Зверніться до техпідтримки.',
        type: 'error'
      });
    }
    if (event.time_repeat && event.time_stop - event.time_start > event.time_repeat) {
      result = false;
      this.notifyService.showMessage({
        message: 'Час повтору події більше періоду події',
        type: 'error'
      });
    }
    if (event.cond_relay > 100 || event.cond_relay < 0) {
      result = false;
      this.notifyService.showMessage({
        message: 'Невалідні дані події. Зверніться до техпідтримки.',
        type: 'error'
      });
    }

    return result;
  }

  save() {
    let fromStr = moment(this.fromStr, 'DD MMM').format('YYYY-MM-DD')
    let toStr = moment(this.toStr, 'DD MMM').format('YYYY-MM-DD')

    const time_stop = Math.round(+moment(`${toStr} ${this.hourTo}:${this.minutesTo}`, 'YYYY-MM-DD HH:mm') / 1000);
    const plus10years =  Math.round((+new Date() + 10*365*60*60*1000) / 1000);

    let event = {
      cond_relay: +this.data.event.cond_relay,
      time_start: Math.round(+moment(`${fromStr} ${this.hourFrom}:${this.minutesFrom}`, 'YYYY-MM-DD HH:mm') / 1000),
      time_stop:  Math.round(+moment(`${toStr} ${this.hourTo}:${this.minutesTo}`, 'YYYY-MM-DD HH:mm') / 1000),
      time_end: +this.repeatMode? plus10years: time_stop,
      time_repeat: +this.repeatMode
    }

    const validate = this.validateSchedule(event);

    if (validate) {
      this.dialogRef.close({event});
    }
  }
}
