import {
  Component,
  OnInit,
  OnDestroy,
  Input
} from "@angular/core";
import {FormControl} from '@angular/forms';

import { round } from '../../utils/math';
import * as moment from "moment";
import {DecimalPipe} from '@angular/common';

import {RelayService} from "../services/relay-service";

import {NotifyService} from "../../lib/services/notify.service";


import {NavigationStart, Router, ActivatedRoute, ParamMap} from '@angular/router';

import 'rxjs/add/operator/switchMap';

import {ChartModule} from 'angular2-highcharts';
import {UserService} from "../../user/services/user-service";
import {ChoosenDateType, DateOptionsType} from "../relay.types";
import {energyDateTypes, SensorsNames, TEMPERATURE_RELAY_TYPES, TOT_106_TH6} from '../constants'
import * as Highcharts from "highcharts";

@Component({
  selector: "relay-temperature",
  templateUrl: "../templates/relay-temperature.html",
  styleUrls: ["../styles/relay.scss", "../styles/relay-stats.scss"]
})
export class RelayTemperatureComponent implements OnInit, OnDestroy {
  isPublicPage: boolean = false;
  dateType: ChoosenDateType;
  type: string;
  endpoint: string;
  readings: any;
  chart: Object;
  options: Object;
  values: any = {
    lines: []
  };
  loading: boolean = true;
  fromDate = new FormControl(new Date());
  toDate = new FormControl(new Date());
  reportMode: string = 'day';
  from: string;
  to: string;
  dateString: string;
  currentDate: Date = new Date();
  energyDateTypes: DateOptionsType[] = [...energyDateTypes];
  allowDateTypes = ['today', 'yesterday', 'custom'];
  title: string = 'Температура';
  relays: any;
  user: any;
  relay: any;

  constructor(public _relayService: RelayService,
              private _notifyService: NotifyService,
              private _route: ActivatedRoute,
              private _userService: UserService,
              private _router: Router,) {

    this.dateString = moment(this.currentDate).format("MMM, YYYY");
    this.currentDate = new Date();
    const temp = []
    this.energyDateTypes.forEach((item, i) => {
      if (this.allowDateTypes.indexOf(item.value) !== -1) {
        temp.push({...item})
      }
    });
    this.energyDateTypes = [...temp];
  }

  ngOnInit() {
    this.loading = true;
    this.isPublicPage = !!window.location.href.match(/\/public\//);
    console.log('RelayEnergyCmp Init', this._relayService);
    let previousData = this._relayService[this.type];

    this._route.paramMap
      .subscribe((success) => {
        this.loading = true;
        let dateParams = this._relayService.getDate();

        this.dateString = dateParams.dateString;
        this.dateType = dateParams;

        let sn = this._route.snapshot.paramMap.get('sn');
        if (!sn) {
          let snArr = window.location.href.match(/relay\/(\w*)/);

          sn = snArr?snArr[1]:'';
        }
        this.relay = this._relayService.getLocal(sn);
        const { sensors } = this.relay;

        if (!TEMPERATURE_RELAY_TYPES.includes(this.relay.typeRelay)) {
          this._router.navigate([`/relay/${sn}/energy`]);
        }

        this.getChartOptions(sensors, this.relay.typeRelay === TOT_106_TH6? 6: 3);

        this.runReport(this.dateType.from, this.dateType.to);
      });

    if (this.isPublicPage) {
      return;
    }

    let user = this._userService.getLocalUser()

    if (user && user['_id']){
      this.user = user;
      this.relays = user['relays'];
    }else {
      let user = JSON.parse(localStorage.getItem('currentUser'));
      if (user) {
        let userId = user._id;
        this._userService.getUser(userId)
          .subscribe((user) => {
            this.user = user;
            this.relays = user['relays'];
          }, (e) => {
            console.log(e);
          })
      }else {
        this.user = {};
      }

    }
    console.log('user', this.user);
  }

  ngOnDestroy() {
    this._relayService.saveDate(this.dateType);
  }

  getDefaultSeries(name: string, index: number) {
    const colors = ['#d2e029', '#31e02e', '#e04037', '#3761E0', '#811CD3', '#D31CCA']
    const color = colors[index] || colors[0]

    return {
      name: name || `Температура ${index + 1}`,
      data: [],
      zIndex: 1,
      color: color,
      marker: {
        enable: true,
        fillColor: color,
        lineWidth: 1,
        lineColor: color
      }
    }
  }

  getChartOptions({enable, list}: SensorsNames = {enable: false, list: []}, linesCount: number = 3){
    const series = [];

    for (let i = 0; i < linesCount; i++) {
      series.push(this.getDefaultSeries(enable && list[i].name, i))
    }
    this.options = {
      time: {
        useUTC: true
      },
      rangeSelector: {
        enabled: false
      },
      chart: {
        type: 'spline',
        zoomType: 'x'
      },
      title: {text: ''},
      xAxis: {
        type: 'datetime',
        minRange: 30000
      },
      yAxis: {
        title: {
          text: 'градусів Цельсія'
        },
        opposite:false
      },
      tooltip: {
        crosshairs: true,
        shared: true,
        valueSuffix: enable? ` , ${list[0].measure}`: ' , С',
        valueDecimals: 2,
      },
      legend: {
        enabled: true
      },
      plotOptions: {
        series: {
          fillOpacity: 0.1
        }
      },
      series: series
    };
  }

  saveInstance(chartInstance: any) {
    let container = document.getElementsByClassName('relay-chart-temperature')[0],
      width;

    width = container ? container.clientWidth : (window.innerWidth - 148);

    Highcharts.setOptions({
      global: {
        useUTC: false
      }
    });

    this.chart = chartInstance;
    this.chart['setSize'](width);

  }

  runReport(from: number, to: number): void {
    const fromConverted: number = +moment(from).startOf('day');
    const toConverted: number = +moment(to).endOf('day');

    this.loading = true;
    this._relayService.getReadingsTemperature(fromConverted, toConverted)
      .subscribe(
        (success) => {
          console.log('get readings run report ', success, !!this.chart);
          if (!this.chart) return;

          if (!success || !success.lines[0]) {
            return
          }

          this.values = success;

          success.lines.forEach((l, i) => {
            if (this.chart['series'][i]) {
              this.chart['series'][i].setData(l, false, true, false);
            }
          })

          this.chart['redraw']();
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this._notifyService.showMessage({
            message: 'Some errors, try later',
            type: 'error'
          });
        }
      );
  }

  onResize(event) {
    let container = document.getElementsByClassName('relay-chart-temperature')[0],
      width;

    if (!this.chart) return;

    console.log('resize');

    width = container?container.clientWidth:(window.innerWidth - 48);

    this.chart['setSize'](width);
    this.chart['redraw']();
  }

  onChoosenDate(typeObj: ChoosenDateType) {
    console.log('onChoosenDate', typeObj);

    this.runReport(typeObj.from, typeObj.to);

    this.dateString = typeObj.dateString;
    this.dateType = typeObj
  }

}
