import {
  Component,
  OnInit,
  OnDestroy,
  Input
} from "@angular/core";

import {
  RelayService
} from "../services/relay-service";


import {NavigationStart, Router, ActivatedRoute, ParamMap} from '@angular/router';

import 'rxjs/add/operator/switchMap';
import * as moment from "moment";
import { round } from '../../utils/math';

import {ChartModule} from 'angular2-highcharts';

import {RelayStatsCmp} from './relay-stats.component';
import {UserService} from "../../user/services/user-service";
import {FormControl} from "@angular/forms";
import {NotifyService} from "../../lib/services/notify.service";
import {ChoosenDateType, DateOptionsType} from "../relay.types";
import {energyDateTypes, PHASE_3_RELAY_TYPES, TOP_323_HT5, TOP_326_HT5, TOT_104_TH3, KMT_101_40, TOT_106_TH6} from '../constants'
import { ReadingMonitoring1Phase, ReadingMonitoring3Phase } from './relay-energy.component'
import {Socket} from "ng-socket-io";
import {convertArrayToCsv, getSerialDigitsFromSerialNumber} from "../services/helper";


@Component({
  selector: "relay-stats",
  templateUrl: "../templates/relay-stats.html",
  styleUrls: ["../styles/relay-stats.scss"]
})
export class RelayVoltageCmp extends RelayStatsCmp implements OnInit, OnDestroy {
  isPublicPage: boolean = false;
  energyDateTypes: DateOptionsType[] = [...energyDateTypes];
  allowDateTypes = ['today', 'yesterday', 'custom'];
  dateType: ChoosenDateType;
  type: string = 'voltage';
  title: string = 'Напруга';
  relays: any;
  user: any;
  loading: boolean = true;
  loadingData: boolean = true;
  relay: any;
  socketSubscription: any;

  constructor(public _relayService: RelayService,
              private _userService: UserService,
              private _route: ActivatedRoute,
              public _notifyService: NotifyService,
              private socket: Socket,
              private router: Router) {
    super(_relayService, _notifyService);
    const temp = []
    this.energyDateTypes.forEach((item, i) => {
      if (this.allowDateTypes.indexOf(item.value) !== -1) {
        temp.push({...item})
      }
    });
    this.energyDateTypes = temp;
    this.setLoading(true);

  }

  ngOnInit() {
    this.isPublicPage = !!window.location.href.match(/\/public\//);
    this.setLoading(true);
    this.setLoadingData(true);
    console.log('RelayStatsCmp Init', this._relayService[this.type]);
    this.loading = true;
    let sn = this._route.snapshot.paramMap.get('sn');
    if (!sn) {
      let snArr = window.location.href.match(/relay\/(\w*)/);

      sn = snArr?snArr[1]:'';
    }

    this._route.paramMap
      .subscribe((success) => {
        this.setLoading(true);
        this.setLoadingData(true);
        this.relay = this._relayService.getLocal(sn);
        this.generateOptions();
        let dateParams = this._relayService.getDate();
        const diff = dateParams.to - dateParams.from;
        const isPeriodBig = diff > 24 * 60 * 60 * 1000;

        if (this.allowDateTypes.indexOf(dateParams.mode) === -1 || isPeriodBig) {
          dateParams = this._relayService.saveDate(undefined);
        }

        if (this.relay.typeRelay === TOT_104_TH3 || this.relay.typeRelay === TOT_106_TH6) {
          this.router.navigate([`/relay/${sn}/temperature`]);
        }

        if (this.relay.typeRelay === KMT_101_40) {
          this.router.navigate([`/relay/${sn}/schedule`]);
        }

        this.is3Phase = PHASE_3_RELAY_TYPES.includes(this.relay.typeRelay);

        this.dateString = dateParams.dateString;
        this.dateType = dateParams;

        if (this.dateType.mode === 'today') {
          this.subscribeToMonitoringReadings();
        }
        this._relayService.getReadingsByType(this.type,this.dateType.from, this.dateType.to)
          .subscribe(
            (success) => {
              console.log('get readings onInit ', success);

              this.values['voltageKoefs'] = success['voltageKoefs'];
              this.values['currentKoefs'] = success['currentKoefs'];
              if (success['line'].length) {
                if (!this.chart) return;
                this.values['line'] = success['line'];
                this.values['range'] = success['range'];
                this.values['limits'] = success['limits'];
                this.values['rangeMinMax'] = success['rangeMinMax'];
                this.values['limitsMinMax'] = success['limitsMinMax'];

                this.chart['series'][0].setData(this.values['line'], false, true, false);
                this.chart['series'][1].setData(this.values['range'], false, true, false);
                this.chart['series'][2].setData(this.values['limits'],false, true, false);

                this.chart['redraw']();
                this.loading = false;
                this.loadingData = false;
              }
              if (success['lineA'].length) {
                if (!this.chart3) return;
                this.values['lineA'] = success['lineA'];
                this.values['rangeA'] = success['rangeA'];

                this.values['limits'] = success['limits'];

                this.values['rangeAMinMax'] = success['rangeAMinMax'];
                this.values['rangeBMinMax'] = success['rangeBMinMax'];
                this.values['rangeCMinMax'] = success['rangeCMinMax'];
                this.values['limitsMinMax'] = success['limitsMinMax'];

                this.chart3['series'][0].setData(this.values['lineA'], false, true, false);
                this.chart3['series'][1].setData(this.values['rangeA'], false, true, false);

                this.values['lineB'] = success['lineB'];
                this.values['rangeB'] = success['rangeB'];

                this.chart3['series'][2].setData(this.values['lineB'], false, true, false);
                this.chart3['series'][3].setData(this.values['rangeB'], false, true, false);

                this.values['lineC'] = success['lineC'];
                this.values['rangeC'] = success['rangeC'];

                this.chart3['series'][4].setData(this.values['lineC'], false, true, false);
                this.chart3['series'][5].setData(this.values['rangeC'], false, true, false);

                this.chart3['series'][6].setData(this.values['limits'],false, true, false);

                this.chart3['redraw']();
                this.loading = false;
                this.loadingData = false;
              }

              this.setLoading(false);
              this.setLoadingData(false);
            }
          );
      })

    let user = this._userService.getLocalUser()

    if (this.isPublicPage) {
      return;
    }

    if (user && user['_id']){
      this.user = user;
      this.relays = user['relays'];
    }else {
      let user = JSON.parse(localStorage.getItem('currentUser'));
      if (user) {
        let userId = user._id;
        this._userService.getUser(userId)
          .subscribe((user) => {
            this.user = user;
            this.relays = user['relays'];
          }, (e) => {
            console.log(e);
          })
      }else {
        this.user = {};
      }

    }

  }

  generateOptions() {
    const relayType = this.relay.typeRelay;
    let title = relayType === TOP_323_HT5 || relayType === TOP_326_HT5? 'Кіловольт': 'Вольт';
    const measure = relayType === TOP_323_HT5 || relayType === TOP_326_HT5 ? 'кВ': 'В';

    let options = {
      title: {text: ''},
      yAxis: {
        title: {
          text: title,
        },
        opposite: false
      },
      tooltip: {
        crosshairs: true,
        shared: true,
        valueSuffix: ' ' + title,
        valueDecimals: 2,
        formatter: function () {
          const pointLine = this.points && this.points[0];
          const pointRange = this.points && this.points[1];

          if (!pointLine || !pointRange) {
            return '';
          }

          const timeHeader = `<p>${moment(+pointLine.x).format('DD MMM HH:mm')}</p><br/>`;
          return `${timeHeader}<p><span style="color:${this.points[0].color}; margin-right:10px;">\u25CF</span><b>${this.points[0].y.toFixed(2)} ${measure}. </b> <span>від ${this.points[1].point.low.toFixed(2)} до ${this.points[1].point.high.toFixed(2)} ${measure}</span></p>`;
        }
      },
      legend: {
        enabled: true
      }
    };

    this.options = {...this.options, ...options};
    this.options3 = {...this.options3, ...options};

    this.options3.tooltip = {
      ...this.options3.tooltip,
      formatter: function () {
        let row1 = '';
        let row2 = '';
        let row3 = '';
        let timeHeader = '';

        let pointLine = this.points && this.points[0];
        let pointRange = this.points && this.points[1];
        if (pointLine && pointRange) {
          timeHeader = `<p>${moment(+pointLine.x).format('DD MMM HH:mm')}</p><br/>`;
          row1 = `<p><span style="color:${this.points[0].color}; margin-right:10px;">\u25CF</span><b>${this.points[0].y.toFixed(2)} ${measure}. </b> <span>від ${this.points[1].point.low.toFixed(2)} до ${this.points[1].point.high.toFixed(2)} ${measure}</span></p>`;
          row1 += '<br/>';
        }

        pointLine = this.points && this.points[2];
        pointRange = this.points && this.points[3];
        if (pointLine && pointRange) {
          row2 = `<p><span style="color:${this.points[2].color}; margin-right:10px;">\u25CF</span><b>${this.points[2].y.toFixed(2)} ${measure}. </b> <span>від ${this.points[3].point.low.toFixed(2)} до ${this.points[3].point.high.toFixed(2)} ${measure}</span></p>`;
          row2 += '<br/>';
        }

        pointLine = this.points && this.points[4];
        pointRange = this.points && this.points[5];
        if (pointLine && pointRange) {
          row3 = `<p><span style="color:${this.points[4].color}; margin-right:10px;">\u25CF</span><b>${this.points[4].y.toFixed(2)} ${measure}. </b> <span>від ${this.points[5].point.low.toFixed(2)} до ${this.points[5].point.high.toFixed(2)} ${measure}</span></p>`;
        }

        return timeHeader + row1 + row2 + row3;
      }
    }
  }

  ngOnDestroy() {
    console.log('RelayStatsCmp Destroy');
    this.unSubscribeToMonitoringReadings();

    this._relayService.saveDate(this.dateType);
  }

  convertRegularData(reading: ReadingMonitoring1Phase) {
    const divider = this._relayService.getDividerFor100VRelay(this.relay.typeRelay, this.type);
    const timestamp = reading.timestamp
    const newReading = [timestamp, reading.voltage.actual / divider];
    this.values['line'].push(newReading);
    this.values['range'].push([timestamp, reading.voltage.min / divider, reading.voltage.max / divider]);
    this.chart['series'][0].addPoint(newReading, false, true);
    this.chart['series'][1].addPoint([timestamp, reading.voltage.min / divider, reading.voltage.max / divider], true, true);

    const lastLimit = this.values['limits'][this.values['limits'].length - 1];
    if (lastLimit) {
      this.values['limits'].push([timestamp, lastLimit[1], lastLimit[2]]);
      this.chart['series'][2].addPoint([timestamp, lastLimit[1], lastLimit[2]], true, true);
    }

    this.values['rangeMinMax'].max = round(Math.max(reading.voltage.max / divider, this.values['rangeMinMax'].max), 2);
    this.values['rangeMinMax'].min = round(Math.min(reading.voltage.min / divider, this.values['rangeMinMax'].min), 2);
  }

  convertRegularData3Phase(reading: ReadingMonitoring3Phase) {
    const divider = this._relayService.getDividerFor100VRelay(this.relay.typeRelay, this.type);
    const timestamp = reading.timestamp;
    const keys = ['A', 'B', 'C'];
    let seriesNumber = 0;
    if (!this.chart3) return;

    const voltageKoef = reading.voltageKoef || 1;

    for (let i = 0; i < keys.length; i++) {
      seriesNumber = i * 2;
      const lineKey = `line${keys[i]}`;
      const rangeKey = `range${keys[i]}`
      const newReading = [timestamp, reading.voltage.actual[i] * voltageKoef / divider];
      this.values[lineKey].push(newReading);
      this.values[rangeKey].push([timestamp, reading.voltage.min[i] * voltageKoef / divider, reading.voltage.max[i] * voltageKoef / divider]);
      this.chart3['series'][seriesNumber].addPoint(newReading, true, true);
      this.chart3['series'][seriesNumber+1].addPoint([timestamp, reading.voltage.min[i] * voltageKoef / divider, reading.voltage.max[i] * voltageKoef / divider], true, true);
    }

    const lastLimit = this.values['limits'][this.values['limits'].length - 1];
    if (lastLimit) {
      this.values['limits'].push([timestamp, lastLimit[1], lastLimit[2]]);
      this.chart3['series'][6].addPoint([timestamp, lastLimit[1], lastLimit[2]], true, true);
    }

    this.values['rangeAMinMax'].max = round(Math.max(reading.voltage.max[0] * voltageKoef / divider, this.values['rangeAMinMax'].max), 2);
    this.values['rangeAMinMax'].min = round(Math.min(reading.voltage.min[0] * voltageKoef / divider, this.values['rangeAMinMax'].min), 2);

    this.values['rangeBMinMax'].max = round(Math.max(reading.voltage.max[1] * voltageKoef / divider, this.values['rangeBMinMax'].max), 2);
    this.values['rangeBMinMax'].min = round(Math.min(reading.voltage.min[1] * voltageKoef / divider, this.values['rangeBMinMax'].min), 2);

    this.values['rangeCMinMax'].max = round(Math.max(reading.voltage.max[2] * voltageKoef / divider, this.values['rangeCMinMax'].max), 2);
    this.values['rangeCMinMax'].min = round(Math.min(reading.voltage.min[2] * voltageKoef / divider, this.values['rangeCMinMax'].min), 2);
  }

  subscribeToMonitoringReadings() {
    let subscription = this.socket.fromEvent('regular data from relay')
      .subscribe(
        (data: {relayId: string, reading: ReadingMonitoring1Phase | ReadingMonitoring3Phase}) => {

          if (data.relayId !== this.relay._id) {
            console.log('Id did not match', data['relayId'], this.relay._id)
            return;
          }

          const to = moment(this.dateType.to).format('YYYY-MM-DD');
          const now = moment().format('YYYY-MM-DD');
          const readingDate = moment(data.reading.timestamp).format('YYYY-MM-DD');

          // if date not include today or reading not todays - skip adding this reading to chart
          if (to !== now || readingDate !== now) {
            return;
          }

          if (typeof data.reading.tot_negEnergyPA === "number") {
            this.convertRegularData(data.reading as ReadingMonitoring1Phase);
          }else if (data.reading.tot_negEnergyPA.length){
            this.convertRegularData3Phase(data.reading as ReadingMonitoring3Phase);
          }
        }
      );

    this.socketSubscription = subscription;
  }

  unSubscribeToMonitoringReadings() {
    if (this.socketSubscription) {
      this.socketSubscription.unsubscribe();
    }
  }

  onChoosenDate(typeObj: ChoosenDateType) {
    super.onChoosenDate(typeObj);

    if (typeObj.mode === 'today') {
      this.subscribeToMonitoringReadings();
    }else {
      this.unSubscribeToMonitoringReadings();
    }
  }

  downloadChartCsv(spreadsheetType: 'excel' | 'google') {
    this.isCsvDropdownOpen = false;
    window.removeEventListener('click', this.outCsvClick);

    if (this.is3Phase) {
      if (!this.values || !this.values["lineA"] || !this.values["lineA"].length) {
        // no data
        this._notifyService.showMessage({
          message: 'Немає даних',
          type: 'error'
        })
        return;
      }
    }else {
      if (!this.values || !this.values["line"] || !this.values["line"].length) {
        // no data
        this._notifyService.showMessage({
          message: 'Немає даних',
          type: 'error'
        })
        return;
      }
    }

    try {
      const csvData = convertArrayToCsv(
        this.is3Phase? this.getChartDataArray3Phase(): this.getChartDataArray(),
        spreadsheetType
      );
      let csvContent = `data:text/csv;charset=utf-8,${csvData}`

      const from = moment(this.dateType.from).format('DDMMYYYY');
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      const dateName = from;
      link.setAttribute("download", `${getSerialDigitsFromSerialNumber(this.relay.serialNumber)}Voltage${dateName}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }catch(e) {
      console.log(e);
      this._notifyService.showMessage({
        message: 'Вибачте, сталася помилка. Спробуйте ще раз.',
        type: 'error'
      })
    }
  }

  getChartDataArray3Phase(): string[][] {
    const result = [];

    const type = this.relay.typeRelay;
    const SN = this.relay.serialNumber;

    const headerName = [`${type}. s/n: ${SN}`];
    result.push(headerName)
    const koefHeader = this.values.voltageKoefs.length? `Voltage coefficients - ${this.values.voltageKoefs.join(', ')}`: null
    if (koefHeader) {
      result.push([koefHeader], [], []);
    } else {
      result.push([], [], []);
    }

    const from = moment(this.dateType.from).format('DD.MM.YYYY');
    const to = moment(this.dateType.to).format('DD.MM.YYYY');
    const timeHeader = `Voltage ${from} - ${to}`;

    result.push([timeHeader])
    const dateFormat = 'DD.MM.YYYY'

    const header = ['Date'];
    const measureHeader = [dateFormat]

    header.push('Time');
    measureHeader.push('hh:mm');

    header.push('Phase A', 'Phase B', 'Phase C');
    measureHeader.push('V', 'V', 'V');

    result.push(header, measureHeader)

    for (let i = 0; i < this.values.lineA.length; i++) {
      let timestamp = this.values.lineA[i][0];
      const row = []
      row.push(moment(timestamp).format(dateFormat))

      row.push(moment(timestamp).format('HH:mm'))

      const valueA = this.values.lineA[i] && typeof this.values.lineA[i][1] === "number"?
        "" + this.values.lineA[i][1] : "";

      const valueB = this.values.lineB[i] && typeof this.values.lineB[i][1] === "number"?
        "" + this.values.lineB[i][1] : "";

      const valueC = this.values.lineC[i] && typeof this.values.lineC[i][1] === "number"?
        "" + this.values.lineC[i][1] : "";
      row.push(valueA, valueB, valueC)

      result.push(row);
    }

    return result;
  }

  getChartDataArray(): string[][] {
    const result = [];

    const type = this.relay.typeRelay;
    const SN = this.relay.serialNumber;

    const headerName = [`${type}. s/n: ${SN}`];
    result.push(headerName)
    const koefHeader = this.values.voltageKoefs.length? `Voltage coefficients - ${this.values.voltageKoefs.join(', ')}`: null
    if (koefHeader) {
      result.push([koefHeader], [], []);
    } else {
      result.push([], [], []);
    }

    const from = moment(this.dateType.from).format('DD.MM.YYYY');
    const to = moment(this.dateType.to).format('DD.MM.YYYY');
    const timeHeader = `Energy ${from} - ${to}`;

    result.push([timeHeader])
    const dateFormat = 'DD.MM.YYYY'

    const header = ['Date'];
    const measureHeader = [dateFormat]

    header.push('Time');
    measureHeader.push('hh:mm');

    header.push('Voltage');
    measureHeader.push('V');

    result.push(header, measureHeader)

    for (let i = 0; i < this.values.line.length; i++) {
      let timestamp = this.values.line[i][0];
      const row = []
      row.push(moment(timestamp).format(dateFormat))

      row.push(moment(timestamp).format('HH:mm'))

      const value = this.values.line[i] && typeof this.values.line[i][1] === "number"?
        "" + this.values.line[i][1] : "";
      row.push(value)

      result.push(row);
    }

    return result;
  }
}

