import {
  Component,
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  EventEmitter,
  Output
} from "@angular/core";

import {MonitoringData} from "../relay.types";
import {copyObj} from "../../utils/common";
import { DefenceSettingsType } from '../relay.types'

const PROTECT_TYPE: string = 'protect'
const DELAY_TYPE: string = 'delay'
const ACTION_TYPE: string = 'action'

const PROTECT_TYPES: string[] = [
  PROTECT_TYPE,
  DELAY_TYPE,
  ACTION_TYPE
]

@Component({
  selector: "defence-card",
  templateUrl: "../templates/defence-card.html",
  styleUrls: ["../styles/defence-card.scss"]
})

export class DefenceCardComponent implements OnInit, OnDestroy {
  @Output() onChangeValues = new EventEmitter<{index: number, values: DefenceSettingsType[], name: string}>();
  @Output() onRemoveCard = new EventEmitter<{index: number, cardName: string}>();
  @Input() type: string;
  @Input() name: string;
  @Input() cardName: string;
  @Input() measure: string;
  @Input() settings?: DefenceSettingsType[];
  @Input() values: DefenceSettingsType[];
  @Input() id: number;
  protectTypes: string[]= PROTECT_TYPES;
  protectType: string = PROTECT_TYPE;
  delayType: string = DELAY_TYPE;
  actionType: string = ACTION_TYPE;
  loading: boolean = true;

  constructor() {

  }

  ngOnInit() {
    this.loading = false;
  }

  ngOnDestroy() {

  }

  copyObject(obj) {
    let res;

    if (Object.prototype.toString.call(obj) === "[object Array]") {
      res = []
    }else if (Object.prototype.toString.call(obj) === "[object Object]"){
      res = {}
    }else {
      return obj;
    }

    for (let key in obj) {
      if (typeof obj[key] === 'object') {
        res[key] = this.copyObject(obj[key]);
      }else {
        res[key] = obj[key];
      }
    }

    return res;
  }

  changeNumber(event: any, values: DefenceSettingsType[], index: number) {
    if (!event || typeof event.value !== 'number') {
      return
    }

    let newValue = Math.round(event.value * 100) / 100;
    const newValues: DefenceSettingsType[] = this.copyObject(values);

    if (newValue > newValues[index].max) {
      newValue = newValues[index].max
    }

    if (newValue < newValues[index].min) {
      newValue = newValues[index].min
    }

    newValues[index].value = newValue;

    if (newValues[index].step >= 10) {
      newValues[index].value = Math.round(newValue / newValues[index].step) * newValues[index].step;
    }else {
      const pow = Math.round(1 / newValues[index].step) || 1;
      newValues[index].value = Math.round(newValue * pow) / pow;
    }

    console.log('changeNumber', event.value, newValue, newValues);

    this.onChangeValues.emit({index: this.id, values: newValues, name: this.cardName})
  }

  onChangeNumberInput(event: any, index: number) {
    if (!isNaN(+this.values[index].value)) {
      let newValue = Math.round(+this.values[index].value * 100) / 100;
      const newValues: DefenceSettingsType[] = this.copyObject(this.values);

      if (newValue > newValues[index].max) {
        newValue = newValues[index].max
      }

      if (newValue < newValues[index].min) {
        newValue = newValues[index].min
      }

      newValues[index].value = newValue


      this.onChangeValues.emit({index: this.id, values: newValues, name: this.cardName})
    }else {
      this.values[index].value = 0;
    }
  }

  changeValue(newValue: string | number, values: DefenceSettingsType[], index: number) {
    if (typeof newValue !== 'string' && typeof newValue !== 'number') {
      return
    }

    const newValues: DefenceSettingsType[] = this.copyObject(values);
    newValues[index].value = newValue;

    console.log('changeValue', newValue);

    this.onChangeValues.emit({index: this.id, values: newValues, name: this.cardName})

  }

  removeCard() {
    this.onRemoveCard.emit({index: this.id, cardName: this.cardName})
  }

  roundNumber(number: number, step: number = 1): number {
    let res = number;
    if (step >= 10) {
      res = Math.round(number / step) * step;
    }else {
      const pow = Math.round(1 / step) || 1;
      res = Math.round(number * pow) / pow;
    }

    return res;
  }
}
