import {
  Component,
  OnInit,
  OnDestroy,
  Input
} from "@angular/core";

import {
  RelayService
} from "../services/relay-service";


import {Socket} from 'ng-socket-io';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import 'rxjs/add/operator/switchMap';

import {ChartModule, ChartComponent} from 'angular2-highcharts';
import {UserService} from "../../user/services/user-service";
import {CHART_TYPES, CHART_TYPES_KMA_111_40, CHART_TYPES_KCP_303_60, CHART_TYPES_TOT_104_TH3, CHART_TYPES_TOT_106_TH6, CHART_TYPES_TOP_323_HT5} from './real-time-chart.component';
import {Options} from "highcharts";
import {copyObj} from "../../utils/common";

const MULTIPLE_PHASES_COLOR = ['#f3b255', '#55f363', '#f355e5'];
const MAIN_PHASE_COLOR = '#f3b255';

import {
  KMA_111_40,
  KMA_LIKE_RELAY_TYPES,
  TEMPERATURE_RELAY_TYPES,
  PHASE_3_RELAY_TYPES,
  TOP_323_HT5,
  KCP_301_60,
  TOP_326_HT5,
  TOC_326_T5,
  KCP_303_60,
  TOT_104_TH3,
  KMT_101_40,
  SensorsNames,
  TOT_106_TH6,
  TOP_RELAYS
} from '../constants';
import * as Highcharts from "highcharts";
const DEFAULTED_CHARTS = ['voltage', 'current', 'Hz', 'power'];

@Component({
  selector: "relay-realtime",
  templateUrl: "../templates/relay-realtime-monitoring.html",
  styleUrls: ["../styles/relay-realtime.scss"]
})
export class RelayRealtimeMonitoringCmp implements OnInit, OnDestroy {
  isPublicPage: boolean = false;
  showU: boolean = true;
  loading: boolean = true;
  online: boolean = false;
  lastConnectTime: number = 0;
  energySettings: object = {
    voltageKoef: 1,
    currentKoef: 1,
    powerKoef: 1,
  };
  levelSettings: object = {
    fullS20maValue: 0,
    showLevel: false,
    zeroS20maValue: 0
  };
  settings: object = {
    voltage: {},
    current: {},
    power: {},
    powerActive: {},
    powerReactive: {},
    PFA: {},
    Hz: {},
    temperature: {}
  };
  typeRelay: string;

  serialNumber: string;
  relayId: string;
  readings: any = {
    voltage: 0,
    current: 0,
    power: 0,
    powerActive: 0,
    powerReactive: 0,
    powerAllActive: 0,
    powerAllReactive: 0,
    Hz: 0,
    PFA: 0,
    act_negEnergyPA: 0,
    act_negEnergyQA: 0,
    act_negEnergySA: 0,
    act_posEnergyPA: 0,
    act_posEnergyQA: 0,
    act_posEnergySA: 0,
    temperature: 0
  };
  readings3phase: any = {
    voltage: [0, 0, 0],
    current: [0, 0, 0],
    power: [0, 0, 0],
    powerActive: [0, 0, 0],
    powerReactive: [0, 0, 0],
    powerAllActive: 0,
    powerAllReactive: 0,
    Hz: 0,
    PFA: 0,
    act_negEnergyPA: [0, 0, 0],
    act_negEnergyQA: [0, 0, 0],
    act_negEnergySA: [0, 0, 0],
    act_posEnergyPA: [0, 0, 0],
    act_posEnergyQA: [0, 0, 0],
    act_posEnergySA: [0, 0, 0],
    temperature: 0,
    act_tempTH: [0, 0, 0]
  };
  phase3: boolean = false;
  relayState: boolean = false;
  disableAction = true;
  sendAction = false;
  intervalForData: any;
  subscriptions: any = [];
  intervals: any = [];
  user: any;
  relays: any;
  currentCharts: any = [];
  liveChart: ChartComponent | any | undefined;
  optionsLiveChart: any = {
    global: {
      useUTC: false
    },
    chart: {
      type: 'spline',
      animation: true,
      marginRight: 10
    },
    time: {
      useUTC: false
    },
    title: {
      text: ' '
    },
    xAxis: {
      type: 'datetime',
      tickPixelInterval: 1000
    },
    yAxis: {
      title: {
        text: ' '
      },
      plotLines: [{
        value: 0,
        width: 1,
        color: MAIN_PHASE_COLOR
      }]
    },
    tooltip: {
      headerFormat: '<b>{series.name}</b><br/>',
      pointFormat: '{point.x:%H:%M:%S}<br/>{point.y:.2f} {point.measure}',
      valueDecimals: 2,
    },
    legend: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    series: [{
      name: ' ',
      color: MAIN_PHASE_COLOR,
      data: []
    }]
  };
  liveChartChoosen: string = 'voltage';
  activeLiveChart: number;
  chartTypes: any = [];
  KMA_LIKE_RELAY_TYPES: string[] = KMA_LIKE_RELAY_TYPES;
  timeOffsetMiliseconds: number = 120 * 60 * 1000;
  sensors: SensorsNames | undefined = undefined;

  constructor(
    public _relayService: RelayService,
    private _route: ActivatedRoute,
    private router: Router,
    private socket: Socket,
    private _userService: UserService
  ) {
    this.socket.on('reconnect', () => {
        console.log('connect');
        socket.emit('join room', {relayId: this.relayId});
      }
    );
    this.intervalForData = {};
    this.isPublicPage = !!window.location.href.match(/\/public\//);
  }

  ngOnInit() {
    console.log("init relay relay monitoring cmp");

    let interval = setInterval(() => {
      this.online = +new Date() - this.lastConnectTime <= 30000;
      this.socket.emit('ping', {relayId: this.relayId});
    }, 30000);

    this.intervals.push(interval);

    let sn = this._route.snapshot.paramMap.get('sn');
    if (!sn) {
      let snArr = window.location.href.match(/relay\/(\w*)/);

      sn = snArr ? snArr[1] : '';
    }

    this.serialNumber = sn;

    this._route.paramMap
      .subscribe((success) => {
        const time = new Date();
        const value = time.getTimezoneOffset();
        this.timeOffsetMiliseconds = -(value * 60 * 1000);

        let sn = this._route.snapshot.paramMap.get('sn');
        if (!sn) {
          let snArr = window.location.href.match(/relay\/(\w*)/);

          sn = snArr ? snArr[1] : '';
        }

        const relay = this._relayService.getLocal(sn);
        const { sensors } = relay;
        this.sensors = sensors;

        this.serialNumber = sn;
        this.chartTypes = [];
        this.currentCharts = [];

        this.subscriptions.forEach((item) => {
          item.unsubscribe();
        })

        this.intervals.forEach((item) => {
          clearInterval(item);
        })

        this.currentCharts = this.getCurrentCharts(this.serialNumber);


        this._relayService.getSettings(this.serialNumber)
          .subscribe(
            (success) => {
              console.log('success settings', success);
              this.settings = success['settings'];
              this.energySettings = success['energySettings'];
              this.levelSettings = success['levelSettings'];
              this.sensors = success['sensors'];

              this.typeRelay = success['typeRelay'];
              this.relayId = success['_id'];

              this.chartTypes = [...CHART_TYPES];
              this.liveChartChoosen = this.getLocalLiveChart();

              if (this.typeRelay === KMT_101_40) {
                this.router.navigate([`/relay/${sn}/schedule`]);
              }

              if (!TEMPERATURE_RELAY_TYPES.includes(this.typeRelay)) {
                this.chartTypes = this.chartTypes.filter((item) => {
                  return !item.value.match('act_tempTH');
                });
              }

              if (this.typeRelay !== TOC_326_T5) {
                this.chartTypes = this.chartTypes.filter((item) => {
                  return item.value !== 'act_s20mA'
                });
              }

              if (!PHASE_3_RELAY_TYPES.includes(this.typeRelay)) {
                this.chartTypes = this.chartTypes.filter((item) => {
                  return item.value !== 'powerAllActive' && item.value !== 'powerAllReactive' && item.value !== 'powerAll'
                });
              }

              if (KMA_LIKE_RELAY_TYPES.includes(this.typeRelay)) {
                this.chartTypes = CHART_TYPES_KMA_111_40
              }

              if (this.typeRelay === KCP_303_60) {
                this.chartTypes = CHART_TYPES_KCP_303_60;
              }

              if (this.typeRelay === TOT_104_TH3) {
                this.chartTypes = CHART_TYPES_TOT_104_TH3;
              }

              if (this.typeRelay === TOT_106_TH6) {
                this.chartTypes = CHART_TYPES_TOT_106_TH6;
              }

              if (this.typeRelay === KCP_301_60) {
                this.chartTypes = this.chartTypes.filter((item) => {
                  return item.value !== 'powerAllActive' && item.value !== 'powerAllReactive' && item.value !== 'powerAll' &&
                    item.value !== 'current' && item.value !== 'power' && item.value !== 'powerReactive'
                    && item.value !== 'powerActive'
                });
              };

              // set sensor names
              if (TOP_RELAYS.includes(this.typeRelay) && this.sensors && this.sensors.enable) {
                const {list} = this.sensors;
                this.chartTypes = this.chartTypes.map(chart => {
                  const newChart = {...chart}
                  if (newChart.value.match(/act_tempTH(\d)$/)) {
                    const index = newChart.value.match(/act_tempTH(\d)$/)[1]
                    newChart.label = list[index].name;
                    newChart.measure = list[index].measure;
                    newChart.options.series[0].name = list[index].name;
                    newChart.options.title = list[index].name;
                  } else if (newChart.value.match(/act_tempTH(\d)(\d)$/)) {
                    // TODO: add name for charts t1-3 or t4-6
                  }

                  return newChart;
                })
              }

              if (this.typeRelay === TOP_323_HT5 || this.typeRelay === TOP_326_HT5) {
                this.chartTypes = this.chartTypes.map(chart => {
                  const newChart = {...chart}

                  if (newChart.value === 'voltage') {
                    newChart.measure = 'Кіловольт';
                  }

                  return newChart;
                });
              }

              this.currentCharts = this.getCurrentCharts(this.serialNumber);

            },
            (error) => {
              console.log('error', error);
            });


        this.getNowReadings();
        let self = this;
        this.intervals.push(setInterval(() => {
          self.loading = false;
        }, 10000));
      });

    if (this.isPublicPage) {
      return;
    }

    let user = this._userService.getLocalUser()

    if (user && user['_id']){
      this.user = user;
      this.relays = user['relays'];
    }else {
      let user = JSON.parse(localStorage.getItem('currentUser'));
      if (user) {
        let userId = user._id;
        this._userService.getUser(userId)
          .subscribe((user) => {
            this.user = user;
            this.relays = user['relays'];
          }, (e) => {
            console.log(e);
          })
      }else {
        this.user = {};
      }

    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item) => {
      item.unsubscribe();
    })

    this.intervals.forEach((item) => {
      clearInterval(item);
    })
  }

  getCurrentCharts(serialNumber: string): any[] {
    let charts = [];
    try {
      const chartsObject = JSON.parse(localStorage.getItem(`realtimeCharts-${serialNumber}`))
      if (chartsObject && chartsObject.charts && chartsObject.charts.length) {
        charts = chartsObject.charts
      }
    } catch (e) {
      console.log(e);
    }

    if (charts.length) {
      return charts;
    }

    if (this.chartTypes && this.chartTypes.length) {
      charts = this.chartTypes.map((chart) => {
        return chart.value;
      });

      return charts.slice(0, 4)
    }

    return DEFAULTED_CHARTS;
  }

  getLocalLiveChart(): string {
    const defaultLiveChart = this.typeRelay === TOT_104_TH3 || this.typeRelay === TOT_106_TH6? 'temperature': 'voltage';
    try {
      const serialNumber = this.serialNumber;
      const livechart = localStorage.getItem(`realtimeLiveChart-${serialNumber}`)
      return livechart || defaultLiveChart
    } catch (e) {
      console.log(e);
      return defaultLiveChart;
    }
  }

  saveLocalLiveChart(chartName: string) {
    try {
      const serialNumber = this.serialNumber;
      localStorage.setItem(`realtimeLiveChart-${serialNumber}`, chartName);
    }catch(e){
      console.log(e);
    }
  }

  saveChartLocally(charts: string[]) {
    if (this.isPublicPage) {
      return;
    }

    for (let i = 0; i < 4; i++) {
      charts[i] = charts[i] || DEFAULTED_CHARTS[i];
    }

    try {
      localStorage.setItem(`realtimeCharts-${this.serialNumber}`, JSON.stringify({charts}));
    }catch(e){
      console.log(e);
    }
  }

  reloadChart(choosenChart: {label: string, value: string}) {
    this.liveChartChoosen = choosenChart.value;
    this.saveLocalLiveChart(this.liveChartChoosen);
    let options;

    if (this.typeRelay === TOT_106_TH6 && this.liveChartChoosen === 'act_tempTH') {
      options = CHART_TYPES.find(c => c.value === 'act_tempTH');
    }else {
      options = this.chartTypes.find((item: any) => {
        return item.value === this.liveChartChoosen
      });
    }

    if (!options) {
      console.log('No options for - ', this.liveChartChoosen);
      return;
    }

    if (!this.liveChart) {
      console.log('No chart');
      return;
    }

    const currentCharts = this.getCurrentCharts(this.serialNumber);
    const index = currentCharts.findIndex((item) => item === this.liveChartChoosen);

    this.activeLiveChart = index;
    const length = this.liveChart.series.length;
    for (let i = 0; i < length; i++) {
      this.liveChart.series[0].remove();
    }

    this.liveChart.addSeries({name: options.label, color: MAIN_PHASE_COLOR, data: []}, true, true);
    this.liveChart.setTitle({text: ' '});
    this.setLiveChartPoint(this.readings);
  }

  getCurrentChart() {
    if (this.typeRelay === TOT_106_TH6 && this.liveChartChoosen === 'act_tempTH') {
      return CHART_TYPES.find(c => c.value === 'act_tempTH');
    }else {
      return this.chartTypes.find((item: any) => {
        return item.value === this.liveChartChoosen
      });
    }
  }

  changeChartLocally(chartName: string, index: number) {
    let localCharts = this.getCurrentCharts(this.serialNumber);
    localCharts[index] = chartName;
    this.saveChartLocally(localCharts);
    this.currentCharts[index] = chartName;
  }

  getNowReadings(): void {
    let subscription = this.socket.fromEvent('send monitoring data')
      .subscribe(
        (data) => {
          this.loading = false;
          this.online = true;
          this.lastConnectTime = +new Date;

          if (data['relayId'] !== this.relayId) {
            console.log('Id did not match', data['relayId'], this.relayId)
            return;
          }

          this.readings = this.convertReadings(data);

          if (this.liveChartChoosen && this.liveChart) {
            this.setLiveChartPoint(this.readings);
          }
        }
      );

    this.subscriptions.push(subscription);
  }

  convertReadings(readings: any){
    const convertedFields = ['power', 'powerActive', 'powerReactive', 'powerAllActive', 'powerAllReactive', 'act_negEnergyPA', 'act_negEnergyQA',
      'act_negEnergySA', 'act_posEnergyPA', 'act_posEnergyQA', 'act_posEnergySA'];

    if (!readings || typeof readings !== 'object') {
      return {};
    }

    let result = {...readings};

    for (let i = 0; i < convertedFields.length; i++) {
      if (typeof result[convertedFields[i]] === 'number') {
        result[convertedFields[i]] = Math.round(result[convertedFields[i]] * 100/1000)/100;
      }else if (typeof result[convertedFields[i]] === 'object') {
        for (let j = 0; j < result[convertedFields[i]].length; j++) {
          result[convertedFields[i]][j] =  Math.round(result[convertedFields[i]][j] * 100/1000)/100;
        }
      }else {
        try {
          result[convertedFields[i]] = Math.round(+result[convertedFields[i]] * 100/1000)/100;
        }catch(e) {
          console.log(e);
        }
      }
    }

    result['powerAll'] = Math.sqrt(Math.pow(result['powerAllActive'], 2) + Math.pow(result['powerAllReactive'], 2));

    if (this.typeRelay === TOT_104_TH3 && result['act_tempTH'] && result['act_tempTH'].length) {
      for (let i = 0; i < result['act_tempTH'].length; i++) {
        result[`act_tempTH${i}`] = result['act_tempTH'][i];
      }
    }

    if (this.typeRelay === TOT_106_TH6 && result['act_tempTH'] && result['act_tempTH'].length) {
      for (let i = 0; i < result['act_tempTH'].length; i++) {
        result[`act_tempTH${i}`] = result['act_tempTH'][i];
      }

      result[`act_tempTH13`] = result['act_tempTH'] && result['act_tempTH'].length? result['act_tempTH'].slice(0,3): [0,0,0];
      result[`act_tempTH46`] = result['act_tempTH'] && result['act_tempTH'].length? result['act_tempTH'].slice(3,6): [0,0,0];
    }

    if ((this.typeRelay === TOP_323_HT5 || this.typeRelay === TOP_326_HT5) && result['voltage']) {
      for (let j = 0; j < result['voltage'].length; j++) {
        result['voltage'][j] =  Math.round(result['voltage'][j] * 100/1000)/100;
      }
    }

    return result;
  }

  changeLiveChart(chartName: string, index: number){
    this.liveChartChoosen = chartName;
    let options;
    if (this.typeRelay === TOT_106_TH6 && this.liveChartChoosen === 'act_tempTH') {
      options = CHART_TYPES.find(c => c.value === 'act_tempTH');
    }else {
      options = this.chartTypes.find((item: any) => {
        return item.value === this.liveChartChoosen
      });
    }

    if (!options) {
      console.log('No options for - ', this.liveChartChoosen);
      return;
    }

    if (!this.liveChart) {
      console.log('No chart');
      return;
    }

    this.activeLiveChart = index;
    const length = this.liveChart.series.length;
    for (let i = 0; i < length; i++) {
      this.liveChart.series[0].remove();
    }

    this.liveChart.addSeries({name: options.label, color: MAIN_PHASE_COLOR, data: []}, true, true);
    this.liveChart.setTitle({text: ' '});
    this.setLiveChartPoint(this.readings);
  }

  setLiveChartPoint(reading: any) {
    let options;
    if (this.typeRelay === TOT_106_TH6 && this.liveChartChoosen === 'act_tempTH') {
      options = CHART_TYPES.find(c => c.value === 'act_tempTH');
    }else {
      options = this.chartTypes.find((item: any) => {
        return item.value === this.liveChartChoosen
      });
    }

    if (!options) {
      console.log('No options for - ', this.liveChartChoosen);
      return;
    }

    const data: number | number[] | undefined = reading[this.liveChartChoosen];
    const now: number = +new Date();

    if (typeof data === 'number') {
      if (this.liveChart.series.length > 1) {
        const length = this.liveChart.series.length;
        for (let i = 0; i < length; i++) {
          this.liveChart.series[0].remove();
        }

        this.liveChart.addSeries({name: options.label, data: [{y: data, measure: options.measure, x: now}]}, true, false);
        this.liveChart.series[0].options.color = MAIN_PHASE_COLOR;
        this.liveChart.series[0].update(this.liveChart.series[0].options);
      }else {
        this.liveChart.series[0].addPoint({y: data, measure: options.measure, x: now}, true);
      }
    }else if (typeof data === 'object') {
      const additionalNames = ['A', 'B', 'C'];
      if (this.liveChart.series.length !== data.length) {
        const length = this.liveChart.series.length;
        for (let i = 0; i < length; i++) {
          this.liveChart.series[0].remove();
        }

        for (let i = 0; i < data.length; i++) {
          const point = {
            y: data[i],
            measure: options.measure,
            x: now
          };

          let name = `${options.label} фаза${additionalNames[i]}`;
          const { enable, list} = this.sensors || {enable: false, list: []};

          if (this.liveChartChoosen === 'act_tempTH' && enable && list[i]) {
            name = list[i].name;
            point.measure = list[i].measure || point.measure;
          }else if (this.liveChartChoosen === 'act_tempTH') {
            name = `${options.label} T${i+1}`
          }

          this.liveChart.addSeries({name, data: [point]}, true, false);
          this.liveChart.series[i].options.color = MULTIPLE_PHASES_COLOR[i];
          this.liveChart.series[i].update(this.liveChart.series[i].options);
        }
      }else {
        for (let i = 0; i < this.liveChart.series.length; i++) {
          const point = {
            y: data[i],
            measure: options.measure,
            x: now
          };

          this.liveChart.series[i].addPoint(point, true);
        }

      }

    }
  }

  saveInstanceLiveChart(chartInstance: ChartComponent) {
    let options;

    if (this.typeRelay === TOT_106_TH6 && this.liveChartChoosen === 'act_tempTH') {
      options = CHART_TYPES.find(c => c.value === 'act_tempTH');
    }else {
      options = this.chartTypes.find((item: any) => {
        return item.value === this.liveChartChoosen
      });
    }

    Highcharts.setOptions({
      global: {
        useUTC: false
      }
    });

    if (!options) {
      console.log('No options for - ', this.liveChartChoosen);
      return;
    }

    let container = document.getElementsByClassName('live-chart-wrapper')[0],
      width;

    width = container ? (container.clientWidth - 10) : '100%';

    this.liveChart = chartInstance;
    this.liveChart.setTitle({text: ' '});
    this.liveChart['setSize'](width);
    this.liveChart.redraw()
  }

  onResize(event: any) {
    let width;

    let container = document.getElementsByClassName('live-chart-wrapper')[0];

    width = container ? (container.clientWidth - 10) : '100%';

    if (!this.liveChart) return;

    this.liveChart['setSize'](width);
    this.liveChart['redraw']();
  }

  getChartDropdownOptions(chartTypes: any): any {
    if (this.typeRelay === TOT_106_TH6) {
      const newChartTypes = chartTypes.filter(c => c.value !== 'act_tempTH13' && c.value !== 'act_tempTH46');
      const temperature = CHART_TYPES.find(c => c.value === 'act_tempTH');
      newChartTypes.unshift(temperature);
      return newChartTypes;
    }else {
      return chartTypes;
    }
  }

}
