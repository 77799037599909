import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'add-relay-dialog',
  templateUrl: '../templates/addRelayDialog.html',
})
export class AddRelayDialog {
  newRelay:any = {
    relayName: 'new Relay',
    serialNumber: ''
  }

  constructor(
    public dialogRef: MatDialogRef<AddRelayDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
