import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Validators, FormControl } from "@angular/forms";

import {AuthService} from "../services/auth-service";
import {NotifyService} from "../../lib/services/notify.service";
import {UserService} from "../../user/services/user-service";

@Component({
  selector: "login-cmp",
  templateUrl: "../templates/login.html",
  styleUrls: ["../styles/login.scss"]
})
export class LoginCmp implements OnInit{
  title: string = "Login";
  username: string;
  password: string;
  id?: string;
  model: any = {};
  loading = false;
  returnUrl: string;
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  passwordFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(6)
  ]);

  constructor(
    private _route: ActivatedRoute,
    private _authService: AuthService,
    private _router: Router,
    private _notifyService: NotifyService,
    private _userService: UserService
  ) {  }

  ngOnInit() {
    // reset login status
    //this._authService.logout();

    this.logout()
    // get return url from route parameters or default to '/'
    this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/';
  }

  checkIsAllowedReturnUrl(returnUrl = '', relays = []) {
    const isPublicRoute = this.returnUrl.match(/public\//);

    if (isPublicRoute) {
      return true;
    }

    const serailNumberInRouteMatch = this.returnUrl.match(/relay\/(.+)\//);
    const serailNumber = serailNumberInRouteMatch && serailNumberInRouteMatch[1];

    if (!serailNumber) {
      return true;
    }

   return !!relays.find(relay => relay.serialNumber === serailNumber);
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpireAt');
    localStorage.removeItem('currentUser');
  }

  login() {
    this._authService.login(this.model.password, this.model.email)
      .subscribe(
        (user) => {
          this.model.email = "";
          this.model.password = "";

          this._userService.saveLocalUser(user);
          const mainPageRoutes = [document.location.origin, `${document.location.origin}/`, '/']
          const isRedirectMainPage = mainPageRoutes.includes(this.returnUrl)
          const isAllowedRoute = this.checkIsAllowedReturnUrl(this.returnUrl, user && user.relays);

          if (isAllowedRoute && this.returnUrl && !isRedirectMainPage) {
            this._router.navigate([this.returnUrl]);
          }else {
            this._router.navigate(['/user']);
          }
        },
        (error) => {
          console.log('error1 - ', error);
          this.loading = false;
          let message = typeof error.error.message === 'string'?error.error.message:null;

          let otherMessages = error.error && error.error.errors;
          if (typeof otherMessages === 'object') {
            console.log('otherMessages MESSAGE', otherMessages);

            let keys = Object.keys(otherMessages);

            if (typeof otherMessages[keys[0]] === 'string') {
              message = otherMessages[keys[0]];
            }
          }

          this._notifyService.showMessage({
            text: message || 'Incorrect password or email',
            type: "error"
          });

        }
      )
  }
}
