import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {NotifyService} from "../../../lib/services/notify.service";
import {FormControl, Validators} from '@angular/forms';

@Component({
  selector: 'telegram-integration-modal',
  template: `
    <div class="modal">
      <div class="modal-header">
        <div class="modal-title">
          Телеграм інтеграція
        </div>
        <div class="modal-close">
          <button mat-icon-button mat-dialog-close="false" aria-hidden="true" autofocus="false">
            <mat-icon aria-label="false" aria-hidden="true">close</mat-icon>
          </button>
        </div>
      </div>
      <mat-dialog-actions>
          <div class="modal-bottom">
            <div class="connect-telegram" *ngIf="!hasTelegramId">
              <p>Щоб інтегрувати телеграм - залогінтеся</p>
              <div id="telegram-button">

              </div>
            </div>
          </div>
          <div *ngIf="hasTelegramId" class="modal-bottom space-top">
            <button mat-raised-button color="primary" (click)="removeTelegram()">Видалити Telegram інтеграцію</button>
          </div>
      </mat-dialog-actions>
    </div>
  `,
  styleUrls: ["../../styles/relay-schedule.scss", "../../styles/relay-settings.scss"]
})

export class TelegramIntegrationModal {
  hasTelegramId: boolean = false;
  removeTelegram: any;

  constructor(
    public dialogRef: MatDialogRef<TelegramIntegrationModal>,
    private notifyService: NotifyService,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    setTimeout(() => {
      data.loadTelegramWidget();
    }, 500)
    this.hasTelegramId = data.hasTelegramId;
    this.removeTelegram = data.removeTelegram;
  }

  save() {
    this.dialogRef.close({
      hasTelegramId: this.hasTelegramId,
    });
  }
}
