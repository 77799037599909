import {Component, Inject, OnInit, OnDestroy} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Validators, FormControl } from "@angular/forms";
import {AuthService} from "../../auth/services/auth-service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {TOP_RELAYS} from "../../relay/constants";
import {Socket} from "ng-socket-io";
import {getToken} from '../../utils/authTokens';
import {UserService} from "../../user/services/user-service";

@Component({
  selector: "front",
  templateUrl: "../templates/front.html",
  styleUrls: ["../styles/front.scss"]
})
export class FrontCmp implements OnInit, OnDestroy {
  name: string = `Вітаємо у Buckler`;
  publicRelays: any = [];
  loading = true;
  TOP_RELAYS: string[] = TOP_RELAYS;

  constructor(
    private _http: HttpClient,
    private _socket: Socket,
    private _userService: UserService
  ) {

  }

  ngOnDestroy() {
    for (let i = 0; i < this.publicRelays.length; i++) {
      this._socket.emit('leave room', {relayId: this.publicRelays[i]._id, token: getToken()});
    }
  }

  ngOnInit() {
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');

    console.log('constructor Start');
    this._http
      .get('api/relay/public')
      .subscribe(
        (response) => {
          console.log('get public response ', response);

          this.publicRelays = response['relays'];

          for (let i = 0; i < this.publicRelays.length; i++) {
            this._socket.emit('join room monitoring', {relayId: this.publicRelays[i]._id, token: getToken()});
            this.publicRelays[i].online = +Date.now() - +new Date(this.publicRelays[i].lastConnection) < 5 * 60 * 1000;
          }

          this.loading = false;
          this._socket.on('send monitoring data', (data) => {
            if (!data || !data['relayId']) {
                console.log('no relay id');
                return
              }

              const relayId = data['relayId'];
              this.publicRelays.find((relay, index) => {
                if (relay._id === relayId) {
                  if (!this.TOP_RELAYS.includes(relay.typeRelay)) {
                    relay.state = data["state"];
                  }else {
                    // this for relay TOR-326-100
                    relay["state_tor326"] = data["state_tor326"]
                  }

                  return true
                }else {
                  return false
                }

              });
            }
          );
        },
        (error) => {
          console.log('errr', error);
          this.loading = false;
        }
      );
  }
}
