import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'make-action-dialog',
  templateUrl: '../templates/removeRelayDialog.html',
})
export class RemoveRelayDialog {
  constructor(
    public dialogRef: MatDialogRef<RemoveRelayDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
