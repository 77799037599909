import { Routes, RouterModule } from '@angular/router';

import { UserCmp } from './user/components/user-cmp';
import { UserSettingsComponent} from "./user/components/user-settings.component";
import { AddRelayByQRCmp } from './user/components/addRelayByQR.component';
import { RelayCmp }   from "./relay/components/relay-cmp";
import { RelaySettingsCmp }   from "./relay/components/relay-settings.component";
import { FrontCmp } from "./front/components/front-cmp";
import { LoginCmp } from './auth/components/login-cmp';
import { SignupCmp } from './auth/components/signup-cmp';
import { ResetPasswordCmp } from './auth/components/reset-password';
import { ChangePasswordCmp } from './auth/components/change-password';
import { AuthGuardService } from './lib/services/auth-guard';
import { RelayPublicCmp }   from "./relay/components/relay-public.component";

import { TestRelayListComponent } from './admin/testRelay/components/test-relay-list.component';
import { TestRelayComponent } from './admin/testRelay/components/test-relay.component';
import {ReportUsersComponent} from "./admin/reports/components/report-users.component";

const appRoutes: Routes = [
  { path: '', component: FrontCmp },
  {
    path: "auth/signup",
    component: SignupCmp,
    pathMatch: "full"
  },
  {
    path: "auth/login",
    component: LoginCmp
  },
  {
    path: "auth/resetPassword",
    component: ResetPasswordCmp
  },
  {
    path: 'auth/changePassword/:token',
    component: ChangePasswordCmp
  },
  { path: 'user', pathMatch: "full", component: UserCmp, canActivate: [AuthGuardService] },
  { path: 'user/settings', pathMatch: "full", component: UserSettingsComponent, canActivate: [AuthGuardService] },
  //{ path: 'user/addRelayByQR', component: AddRelayByQRCmp, canActivate: [AuthGuardService] },
  { path: 'relay/:sn', component: RelayCmp, canActivate: [AuthGuardService] },
  { path: 'relay/:sn/settings', component: RelaySettingsCmp, canActivate: [AuthGuardService]},
  {
    path: "public/relay/:sn",
    component: RelayPublicCmp
  },

  { path: 'admin/relay', component: TestRelayListComponent, canActivate: [AuthGuardService]},
  { path: 'admin/relay/:serialNumber', component: TestRelayComponent, canActivate: [AuthGuardService]},

  { path: 'admin/reportUsers', component: ReportUsersComponent, canActivate: [AuthGuardService]},

  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

export const appRouting = RouterModule.forRoot(appRoutes);
