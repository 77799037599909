import {
  Component,
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  EventEmitter,
  Output
} from "@angular/core";
import {MatSelectChange} from "@angular/material/typings/select/select";
import {NotifyService} from "../../../lib/services/notify.service";

export interface ZoneTiming {
  from:  string;
  to: string;
}

export interface Zone {
  name: string;
  price: number;
  index?: number;
  times: ZoneTiming[]
}

const DEFAULT_ZONE_TIME: ZoneTiming = {
  from: '00',
  to: '24',
};

const DEFAULT_ZONE: (i: number) => Zone = (i: number) => ({
  price: 0.9,
  name: `Тариф ${i}`,
  times: [
    {...DEFAULT_ZONE_TIME}
  ]
});

interface TIME_ZONE_OPTION {
  label: string;
  value: string;
}

@Component({
  selector: "relay-settings-price",
  templateUrl: "../../templates/settings/settings-price.html",
  styleUrls: ["../../styles/relay-settings.scss"]
})

export class SettingsPriceComponent implements OnInit, OnDestroy, OnChanges {
  @Output() onChangeValues = new EventEmitter<{zones: Zone[]}>();
  @Output() onSave = new EventEmitter<{zones: Zone[]}>();
  @Output() onBack = new EventEmitter();
  @Input() zones: Zone[] = [];
  loading: boolean = true;
  timeZoneStartOptions: TIME_ZONE_OPTION[] = [];
  timeZoneEndOptions: TIME_ZONE_OPTION[] = [];
  erroredRows: boolean[] = [];

  constructor( private notifyService: NotifyService ) {
    for (let i = 0; i<24; i++) {
      const timeFrom = i < 10? `0${i}`: i.toString();
      const timeTo = i < 9? `0${i + 1}`: (i + 1).toString();

      const startOption: TIME_ZONE_OPTION = {
        label: `${timeFrom}:00`,
        value: `${timeFrom}`,
      };

      const endOption: TIME_ZONE_OPTION = {
        label: `${timeTo}:00`,
        value: `${timeTo}`,
      };

      this.timeZoneStartOptions.push(startOption);
      this.timeZoneEndOptions.push(endOption);
    }


  }

  ngOnInit() {
    this.loading = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.zones) {
      this.zones = changes.zones.currentValue;
    }
  }

  ngOnDestroy() {

  }

  changeZoneValue(zoneIndex: number, timeIndex: number, fieldName: string, event: MatSelectChange) {
    this.zones[zoneIndex].times[fieldName] = event.value;

    this.onChangeValues.emit({zones: this.zones});
  }

  changeZonePrice(zoneIndex: number, event: any) {
    const value = +event.target.value;
    // check for NaN
    if (value !== value) {
      this.notifyService.showMessage({
        text: "Вартість тарифу має бути числом",
        type: "error"
      });
      return;
    }
    this.zones[zoneIndex]['price'] = value;


    this.onChangeValues.emit({zones: this.zones});
  }

  changeZoneName(zoneIndex: number, event: any) {
    this.zones[zoneIndex]['name'] = event.target.value;

    this.onChangeValues.emit({zones: this.zones});
  }

  addZone() {
    if (this.zones.length > 23) {
      this.notifyService.showMessage({
        text: "Тарифів не може бути більше 24",
        type: "error"
      });

      return;
    }
    const defaultZone = DEFAULT_ZONE(this.zones.length + 1);
    this.zones.push(defaultZone);
    this.onChangeValues.emit({zones: this.zones});
  }

  removeZone(zoneIndex: number) {
    this.zones.splice(zoneIndex, 1);
    this.onChangeValues.emit({zones: this.zones});
  }

  addTime(zoneIndex: number, timeIndex: number) {
    if (timeIndex) {
      this.zones[zoneIndex].times.splice(timeIndex + 1, 0, {...DEFAULT_ZONE_TIME});
    }else {
      this.zones[zoneIndex].times.push({...DEFAULT_ZONE_TIME});
    }

    this.onChangeValues.emit({zones: [...this.zones]});
  }

  removeTime(zoneIndex: number, timeIndex: number) {
    this.zones[zoneIndex].times.splice(timeIndex, 1);
    this.onChangeValues.emit({zones: [...this.zones]});
  }

  validateZones(): {isValid: boolean, errors: string[], fields: boolean[]} {
    const errors: string[] = [];
    const fields = [];
    if (!this.zones.length) {
      // show message
      return {
        isValid: true,
        errors: [],
        fields
      }
    }

    let allHours = [];
    this.zones.forEach((zone: Zone, zoneIndex: number) => {
      const oneZoneHours = [];
      zone.times.forEach((time: ZoneTiming, timeIndex: number) => {
        const itemHoursFrom = +time.from;
        const itemHoursTo = +time.to;

        if (itemHoursFrom >= itemHoursTo) {
          // show message
          errors.push(`Час завершення тарифу ${zone.name} менше початку`);
          fields[zoneIndex] = true;
        }

        for (let i = itemHoursFrom; i < itemHoursTo; i++) {
          // check if this hours present in current zone
          if (oneZoneHours.includes(i)) {
            errors.push(`Години тарифу ${zone.name} пересікаються між собою`);
            fields[i] = true;
          }
          oneZoneHours.push(i);
        }


      });

      // check if current zone time exist in allHours
      oneZoneHours.forEach((hour: number) => {
        if(allHours.includes(hour)) {
          errors.push(`Години тарифу ${zone.name} пересікаються з іншим тарифом`);
          fields[zoneIndex] = true;
        }
      });

      allHours = allHours.concat(oneZoneHours);


    });

    for (let h = 0; h < 24; h++) {
      if (!allHours.includes(h)) {
        errors.push(`Година ${h > 9? h: '0' + h} не присутня у жодному тарифі`);
      }
    }

    if (errors.length) {
     return {
       isValid: false,
       errors: errors,
       fields
     }
    }else {
      return {
        isValid: true,
        errors: [],
        fields
      }
    }
  }

  savePriceZones() {
    const { isValid, errors, fields } = this.validateZones();

    if (isValid) {
      this.onSave.emit({zones: this.zones});
      this.erroredRows = [];
    }else {
      this.erroredRows = fields;

      console.log(this.erroredRows);
      let message = `${errors[0]}`;
      message = errors.length > 1? `${message}`: message;
      this.notifyService.showMessage({
        text: message,
        type: "error"
      });
    }
  }
}
