import {
  Component,
  OnInit,
  OnDestroy
} from "@angular/core";

import {
  RelayService
} from "../services/relay-service";

import {Socket} from 'ng-socket-io';

import * as moment from "moment";

import {NavigationStart, Router, ActivatedRoute, ParamMap} from '@angular/router';
import 'rxjs/add/operator/switchMap';

import {NotifyService} from "../../lib/services/notify.service";
import {FormControl} from "@angular/forms";
import {DecimalPipe} from '@angular/common';
import {getToken} from '../../utils/authTokens';
import {UserService} from "../../user/services/user-service";
import {ScheduleEventDialog} from "./schedule-modal.component";
import {MatDialog} from "@angular/material";
import { RemoveApplianceComponent } from './remove-appliance.component'
import {ChoosenDateType, DateOptionsType} from "../relay.types";

import {applianceDateTypes, KMT_101_40} from '../constants'

@Component({
  selector: "relay-appliance-cmp",
  templateUrl: "../templates/relay-appliance.html",
  styleUrls: ["../styles/relay.scss"]
})
export class RelayApplianceCmp implements OnInit, OnDestroy {
  loading: boolean = true;
  applianceDateTypes: DateOptionsType[] = applianceDateTypes;
  dateType: ChoosenDateType;
  relay: any = {
    status: false,
    energySettings: {}
  };
  user: any = {};
  relayId: string;
  relaySN: string;
  relays: any;

  subscriptions: any = [];
  timers: any = [];

  appliancesLength: number;
  appliances: any = [];
  otherAppliance: any = {
    P: 0,
    Q: 0
  };
  notifications: any = [];
  turnSubscription: any;
  step: any;
  changedApplianced: number;
  searching: boolean = false;
  searchingPhase: number;

  chartReady: boolean = false;
  loaded: boolean = false;
  dateString: string;
  currentDate: Date = new Date();
  chartCategories: any = [];

  type: string = 'energy';
  title: string = 'Energy';
  reportMode = 'day';
  options: any;
  applianceEvents: any = [];
  chart: any;
  appliancePerMonth: any = {};
  isPublicPage: boolean = false;
  energySettings: any = {};

  constructor(
    private _relayService: RelayService,
    private route: ActivatedRoute,
    private router: Router,
    private socket: Socket,
    private notifyService: NotifyService,
    private _userService: UserService,
    private _dialog: MatDialog
  ) {

    this.relaySN = this.route.snapshot.paramMap.get('sn');

    if (!this.relaySN) {
      let snArr = window.location.href.match(/relay\/(\w*)/);

      this.relaySN = snArr ? snArr[1] : '';
    }

    this.dateString = moment(this.currentDate).format("MMM, YYYY");
    this.currentDate = new Date();
    this.isPublicPage = !!window.location.href.match(/\/public\//);

    if (this.isPublicPage) {
      this.energySettings = {};
    }

    this.socket.on('reconnect', () => {
        console.log('reconnect');
        socket.emit('join room', {relayId: this.relayId, token: getToken()});
      }
    );

    this.socket.on('server:appliance:all appliances', data => {
      console.log('all appliances', data);
      let {appliances} = data;

      if (appliances) {
        this.appliances = appliances;
      }
    });
  }

  ngOnInit() {
    this.listenDisconnectRelay();

    this.getChartOptions();
    this.chartReady = true;

    this.loading = true;

    let subscription = this.route.paramMap
      .subscribe((success) => {
        let dateParams = this._relayService.getDate();

        this.dateString = dateParams.dateString;
        this.dateType = dateParams;

        let sn = this.route.snapshot.paramMap.get('sn');
        if (!sn) {
          let snArr = window.location.href.match(/relay\/(\w*)/);

          sn = snArr?snArr[1]:'';
        }
        this.relay = this._relayService.getLocal(sn);
        this.energySettings = this.isPublicPage? {}: this.relay.energySettings;
        this.relayId = this.relay._id;
        this.relaySN = this.relay.serialNumber;
        this.appliancesLength = this.relay.appliances ? this.relay.appliances.length : 0;
        this.appliances = this.relay.appliances || [];

        // do not show Appliance to relay KMA, KCP, TOP, TOT, TOC
        if((this.relay.typeRelay || '').match(/^KIA|KMA|KCP|TOP|TOT_104_TH3|TOC|KMT_101_40|TOC_326_T5|TOT_106_TH6|TEP_321_T01/i)) {
          this.router.navigate([`/relay/${this.relaySN}/energy`]);
          return;
        }
        // end

        this.runReport(this.dateType.from, this.dateType.to);

        let user = this._userService.getLocalUser()
        console.log('user is', user);
        if (user && user['_id']){
          this.user = user;
          this.relays = user['relays'];
        }else {
          let userId = JSON.parse(localStorage.getItem('currentUser'))._id;
          this._userService.getUser(userId)
            .subscribe((user) => {
              this.user = user;
              this.relays = user['relays'];
            }, (e) => {
              console.log(e);
            })
        }



        this.loading = false;

        this.socket.emit('user:appliance:get all_appliances',
          {relayId: this.relayId, token: getToken()},
          (data) => {
            if (!data['success']) {
              this.notifyService.showMessage({
                text: "Сталася помилка",
                type: "failed"
              });
            }
          });
      });

    this.subscriptions.push(subscription);

    this.socket.on('server:appliance:turn', (data) => {
        console.log('server:appliance:turn', data);
        if (data.appliances && (data.event === 1 || data.event === 2)) {
          this.colorChangedAppliance(data.appliances);
          this.appliances = data.appliances;
        }
      }
    );

    this.socket.on('server:appliance:stoped search new', (data) => {
        console.log('server:appliance:stoped search new', data);
        this.searching = false;
        this.searchingPhase = 0;
        this.notifyService.showMessage({
          text: 'Пошук приладу припинено',
          type: 'error'
        })
      }
    );

    this.socket.on('server:appliance:deleted', (data) => {
      console.log('server:appliance:deleted', data);

      if (!data.error) {
        this.notifyService.showMessage({
          text: 'Реле видалено',
          type: 'success'
        });
        this.appliances = data.appliances;
      } else {
        this.notifyService.showMessage({
          text: data.error,
          type: 'error'
        });
      }
    });
  }

  ngOnDestroy() {
    console.log("DESTROY");

    this.subscriptions.forEach((item) => {
      item.unsubscribe();
    });

    this.timers.forEach((item) => {
      clearTimeout(item);
    });

    this._relayService.saveDate(this.dateType);
  }

  listenDisconnectRelay(): void {
    let subscription = this.socket.fromEvent('relay disconnected')
      .subscribe(
        (data) => {
          console.log('listenDisconnectRelay relay CMP', data);
        }
      );

    this.subscriptions.push(subscription);
  }

  colorChangedAppliance(appliances: any): void {
    try {
      appliances.find((a, i) => {
        if (a.condition_on !== this.appliances[i].condition_on) {
          this.changedApplianced = i;
          return true
        }

        return false;
      });
    } catch (e) {
      console.log(e);
    }
  }

  startNewSearch(): void {
    console.log('startNewSearch');
    this.searching = true;
    this.searchingPhase = 0.5;
    this.socket.emit('user:appliance:start search new',
      {relayId: this.relayId, token: getToken()},
      (data) => {
        const {success, message} = data;

        if (!success) {
          this.notifyService.showMessage({
            text: message,
            type: 'error'
          });
          this.showInstruction('');
          this.searching = false;
          this.searchingPhase = 0;
          console.log('startNewSearch err', message);
        } else {
          this.searching = true;
          this.searchingPhase = 1;
          this.subscribeToSearchEvents();
          //this.showInstruction('prestart');
        }
      })
  }

  showNotification(message) {
    this.notifications.push(message);
    let self = this;
    const timer = setTimeout(() => {
      self.notifications.shift();
    }, 5000);
    self.timers.push(timer);
  }

  subscribeToSearchEvents() {
    console.log('subscribeToSearchEvents');
    let subscription = this.socket.fromEvent('server:appliance:started search new')
      .subscribe(data => {
        if (data['number_appliance'] === -1 || data['error']) {
          this.showInstruction('error');
          this.notifyService.showMessage({
            text: data['error'],
            type: 'error'
          });
          subscription.unsubscribe();
          this.searching = false;
          this.searchingPhase = 0;
          return;
        }

        subscription.unsubscribe();

        this.searchingPhase = 2;

        this.turnSubscription = this.socket.fromEvent('server:appliance:search_turn')
          .subscribe(response => {
            let data = response['data'];
            if (data.event === 10) {
              //this.showInstruction('find');
              this.searchingPhase = 3;
            } else if (data.event === 20) {
              //this.showInstruction('end');
              this.turnSubscription.unsubscribe();
              this.searchingPhase = 4;

              let self = this;

              setTimeout(() => {
                self.searching = false;
                this.searchingPhase = 0;
              }, 5000)

              if (data.list && data.list.length) {
                this.appliances.push(data.list[data.list.length - 1]);
              }

              setTimeout(() => {
                self.step = null;
              }, 10000);
            }
          });
      })
  };

  showInstruction(step) {
    console.log('showInstruction', step);
    const steps = {
      prestart: 'Вимкніть прилад який хочете додати',
      start: 'Пошук розпочато. Увімкніть прилад',
      find: 'Прилад знайдено. Вимкніть прилад',
      end: 'Пошук закінчено. Прилад додано',
      error: 'Сталася помилка',
      noRelay: 'Реле не відповідає'
    };

    this.step = steps[step] || null;
  };

  removeAppliance(key_appliance: number): void {
    console.log('removeAppliance');
    this.socket.emit('user:appliance:delete',
      {
        key_appliance: key_appliance,
        relayId: this.relayId,
        token: getToken()
      },
      (data) => {
        let type = data.error ? 'error' : 'success';
        this.notifyService.showMessage({
          text: data.message,
          type: type
        });
      });
  }

  addApplianceName(name, key_appliance) {
    let appliances = this.appliances.map((item) => {
      if (item.key_appliance === key_appliance) {
        item.name = name;
        item.showAddName = false;
      }

      return item;
    });

    this._relayService.setApplianceName(this.relaySN, name, key_appliance)
      .subscribe(
        (success) => {
          console.log('setApplianceName', success);
          this.appliances = appliances || [];
        })
  }

  stopSearching() {
    this.searching = false;
    this.searchingPhase = 0;

    this.socket.emit('user:appliance:stop search new',
      {
        relayId: this.relayId, token: getToken()
      },
      (data) => {
        if (!data['success']) {
          this.notifyService.showMessage({
            text: "Сталася помилка",
            type: "failed"
          });
        }
      });
  }

  getChartOptions() {

    let series = [];

    this.options = {
      chart: {
        type: 'column'
      },
      title: {
        text: 'Спожита електроенергія, кВТ*г'
      },
      xAxis: {
        categories: [],
        crosshair: true
      },
      yAxis: {
        title: {
          text: 'Спожита електроенергія'
        }
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 2,
          stacking: 'normal'
        }
      },
      series: series
    };
  }

  saveInstance(chartInstance: any) {
    let container = document.getElementsByClassName('relay-chart-energy')[0],
      width;

    width = container ? (container.clientWidth - 48) : (window.innerWidth - 48);

    this.chart = chartInstance;
    this.chart['setSize'](width);

  }

  runReport(from: number, to: number): void {
    const fromTimestamp: number = +moment(from).startOf('day');
    const toTimestamp: number = +moment(to).endOf('day');

    this.loaded = false;
    this.getChartOptions();
    this.chartReady = true;

    function divider(item) {
      return Math.round(item)/1000
    }

    this._relayService.getApplianceEvents(this.relaySN, fromTimestamp, toTimestamp)
      .subscribe(
        (success) => {
          if (!this.chart) return;
          this.chartCategories = [];
          this.applianceEvents = success['events'] || [];

          let P = {
            other: {
              name: 'Інше, активна, кВт*г',
              data: [],
              stack: 'active',
              sum: 0
            }
          };
          let Q = {
            other: {
              name: 'Інше, реактивна, кВар*г',
              data: [],
              stack: 'reactive',
              sum: 0
            }
          };

          let appliances = this.applianceEvents.appliances || {};

          this.appliances.forEach((appliance) => {
            appliance.P = 0;
            appliance.Q = 0;
          });
          this.otherAppliance.P = 0;
          this.otherAppliance.Q = 0;

          if (Object.keys(appliances).length) {
            for (let id in appliances) {
              if (appliances[id].P && appliances[id].P.length) {
                P[id] = {
                  name: (appliances[id].name || id) + ', активна, кВт*г',
                  data: appliances[id].P.map(divider),
                  stack: 'active',
                  sum: Math.round(appliances[id].P.reduce((sum, item) => {
                    return sum + Math.round(item || 0)/1000 || 0
                  }, 0)*1000)/1000
                }
              }

              if (appliances[id].Q && appliances[id].Q.length) {
                Q[id] = {
                  name: (appliances[id].name || id) + ', реактивна, кВар*г',
                  data: appliances[id].Q.map(divider),
                  stack: 'reactive',
                  sum: Math.round(appliances[id].Q.reduce((sum, item) => {
                    return sum + Math.round(item || 0) / 1000 || 0
                  }, 0)*1000)/1000
                }
              }

              for (let i = 0; i < this.appliances.length; i++) {
                if (+this.appliances[i].key_appliance === +id) {
                  this.appliances[i].P = P[id]? P[id].sum: 0;
                  this.appliances[i].Q = Q[id]? Q[id].sum: 0;
                }
              }
            }

            let dates = this.applianceEvents.dates.map((date) => {
              const year = date.year || new Date().getFullYear();
              const month = date.month > 9? date.month: '0' + date.month;
              if (date.hour !== undefined) {
                const day = date.day > 9? date.day: '0' + date.day;
                const hour = date.hour > 9? date.hour: '0' + date.hour;
                return moment(year + '-' + month + '-' + day + ' ' + hour, 'YYYY-MM-DD HH').format('HH');
              } else if (date.day !== undefined) {
                const day = date.day > 9? date.day: '0' + date.day;
                return moment(year + '-' + month + '-' + day, 'YYYY-MM-DD').format('DD MMM');
              } else if (date.month !== undefined) {
                return moment(year + '-' + month + '-1', 'YYYY-MM-DD').format('MMM, YYYY');
              }

              return `${date.day?date.day + '/':'month - '}${date.month}`
            });

            P['other'].data = this.applianceEvents.relay.P.map((item, i) => {
              let sum = 0;
              item = item || 0;

              for (let key in appliances) {
                sum += appliances[key].P[i]?appliances[key].P[i]:0;
              }
              sum = sum || 0
              return Math.round(item - sum)/1000 || 0;
            });

            P['other'].sum = P['other'].data.reduce((sum, item) => {
              return Math.round((sum + item || 0)*1000)/1000;
            }, 0)

            Q['other'].data = this.applianceEvents.relay.Q.map((item, i) => {
              let sum = 0;
              item = item || 0;

              for (let key in appliances) {
                sum += appliances[key].Q[i]?appliances[key].Q[i]:0;
              }
              sum = sum || 0
              return Math.round(item - sum)/1000 || 0;
            });

            Q['other'].sum = Q['other'].data.reduce((sum, item) => {
              return Math.round((sum + item || 0)*1000)/1000;
            }, 0)

            this.otherAppliance.P = P['other'].sum;
            this.otherAppliance.Q = Q['other'].sum;

            let options = this.options;

            options.series = [...Object.values(P)];
            if (this.energySettings.reactive) {
              options.series = [...options.series, ...Object.values(Q)];
            }

            options.xAxis.categories = dates;
            this.chart.update(options, true, true, true);

            this.appliancePerMonth = {
              P: P,
              Q: this.energySettings.reactive? Q: {},
              appliances: Object.values(this.applianceEvents.appliances)
            }

            console.log('this.appliancePerMonth ', this.appliancePerMonth);
            this.loaded = true;
          }else {
            this.appliancePerMonth = {};
            this.applianceEvents = [];
            this.loaded = true;
          }

        },
        (error) => {
          this.loaded = true;
          this.notifyService.showMessage({
            message: 'Some errors, try later',
            type: 'error'
          });
        }
      );
  }

  changeDatePeriod(mode: String, way: number) {

    let currentDate = this.currentDate;

    if (mode === 'month') {
      this.currentDate = new Date(currentDate.setFullYear(currentDate.getFullYear() + way));
      this.dateString = moment(this.currentDate).format("YYYY");
    } else if (mode === 'hour') {
      this.currentDate = new Date(new Date(currentDate.setMonth(currentDate.getMonth())).setDate((currentDate.getDate() + way)));
      this.dateString = moment(this.currentDate).format("DD MMM, YYYY");
    } else if (mode === 'day') {
      this.currentDate = new Date(currentDate.setMonth(currentDate.getMonth() + way));
      this.dateString = moment(this.currentDate).format("MMM, YYYY");
    }
  }

  openRemoveDialog(key_appliance) {
    let dialogRef = this._dialog.open(RemoveApplianceComponent, {
      width: '300px',
      data: {
        key_appliance: key_appliance
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result || !result.remove) return;

      this.removeAppliance(key_appliance)

    })
  }

  onChoosenDate(typeObj: ChoosenDateType) {
    console.log('onChoosenDate', typeObj);

    this.runReport(typeObj.from, typeObj.to);

    this.dateString = typeObj.dateString;
    this.dateType = typeObj
  }
}
