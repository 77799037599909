import {
  Injectable
} from "@angular/core";

import {
  Observable
} from "rxjs/Observable";

import "rxjs/add/operator/map";
import {MatSnackBar} from "@angular/material";

@Injectable()
export class NotifyService {
  messages:any = [];

  constructor(public snackBar: MatSnackBar) {

  }

  showMessage(message: any = {}) {
    let classes = message.type || '',
      duration = typeof message.duration === 'number'?message.duration:5000;

    const text = message.text || message.message
    if (!text) return;

    this.snackBar.open(text, 'Закрити', {
      duration: duration,
      extraClasses: [classes]
    });
  }


}
