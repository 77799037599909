import {Component, OnInit, OnDestroy } from "@angular/core";
import {AuthService} from "../../auth/services/auth-service";
import {Router} from '@angular/router';

import { HttpClient } from '@angular/common/http';
import { DecimalPipe } from '@angular/common';

import { MatDialog } from '@angular/material';

import { round } from '../../utils/math';
import {UserService} from '../services/user-service'
import { Socket } from 'ng-socket-io';
import {getToken} from '../../utils/authTokens';

import { DialogChangeInfo } from '../../lib/components/changeInfo/dialogChangeInfo.component'
import { AddRelayDialog } from './addReayDialog.component';
import { ChangeRelayNameDialog } from './changeRelayNameDialog.component';
import { RemoveRelayDialog } from './removeRelayDialog.component';

import { NotifyService } from '../../lib/services/notify.service';
import {makeActionDialog} from "../../relay/components/makeActionDialog.component";
import {copyObj, getTorStateByType } from "../../utils/common";
import {KCP_323_60, TOP_323_HT5, TOP_323_T01, TOP_323_T5, TOP_RELAYS, KCP_303_60, TOT_104_TH3, TOT_106_TH6, SWITCHER_3_TYPES, TOP_324_T5, TOP_324_T01} from "../../relay/constants";

interface ShowReadingOption {
  name: string;
  measure: string;
  type: string;
  subtype?: string;
  show?: boolean;
}

@Component({
  selector: "user",
  templateUrl: "../templates/user.html",
  styleUrls: ["../styles/user.scss"]
})
export class UserCmp implements OnInit, OnDestroy {
  user: any = {};
  relays: any;
  relaysInterval: any;
  ENDPOINT: string = 'api/users/';
  loading: boolean = true;
  actions: any = {};
  showReadingOptions: ShowReadingOption[] = [
    {
      name: 'Активна потужність',
      measure: 'kW',
      type: 'powerActive',
      show: false
    },
    {
      name: 'Реактивна потужність',
      measure: 'kVar',
      type: 'powerReactive',
      show: false
    },
    {
      name: 'Напруга максимальна',
      measure: 'V',
      type: 'voltage',
      subtype: 'max',
      show: false
    },
    {
      name: 'Напруга мінімальна',
      measure: 'V',
      type: 'voltage',
      subtype: 'min',
      show: false
    }
  ];
  readingChoosenOption: {[key: string]: ShowReadingOption} = {};
  defaultReadingChoosenOption: ShowReadingOption = this.showReadingOptions[0];
  readingChoosen: {[key: string]: number} = {};
  showDD: boolean[] = [];
  allowDragRelayList = true;
  TOT_104_TH3: string = TOT_104_TH3;
  SWITCHER_3_TYPES: string[] = SWITCHER_3_TYPES;
  TOT_106_TH6: string = TOT_106_TH6;
  TOP_324_T5: string = TOP_324_T5;
  TOP_324_T01:string = TOP_324_T01;
  TOP_RELAYS: string[] = TOP_RELAYS;
  subscriptions: any[] = [];

  constructor(private _authService: AuthService,
              private _authHttp: HttpClient,
              private _router: Router,
              private _dialog: MatDialog,
              private socket: Socket,
              private _notifyService: NotifyService,
              private _userService: UserService
              ) {
    this.user = {};
    this.relays = [];
  }

  ngOnInit() {
    const jsonOptions: string | null = localStorage.getItem('showReadingOptions');
    let readingOptions: {[key: string]: ShowReadingOption} = {};

    if (jsonOptions) {
      try {
        readingOptions = JSON.parse(jsonOptions);
      }catch(e) {
        console.log(e);
      }
    }

    this.readingChoosenOption = readingOptions;

      let userId = JSON.parse(localStorage.getItem('currentUser'))._id;

    this._userService.getMe()
        .subscribe(
          (user) => {
            this.user = user;
            this.relays = this.user.relays.sort((first, second) => {
              return first.index - second.index;
            });

            for (let i = 0; i < this.relays.length; i++) {
              this.socket.emit('join room', {relayId: this.relays[i]._id, token: getToken()});
              this.socket.emit('join room monitoring', {relayId: this.relays[i]._id, token: getToken()});
            }

            this.loading = false;

            for (let relay of this.relays) {
              if (!this.readingChoosenOption[relay._id]) {
                this.readingChoosenOption[relay._id] = {...this.defaultReadingChoosenOption};
              }
            }
            this.getRelaysStats();
            this.getLastReadings();

            console.log('this.user onInit ', this.user);
          },
          (error) => {
            console.log('onInit error ', error);
          }
        );

    this.socket.on('need action response', (data) => {
        console.log('need action response', data);

        if (!data || !data.relayId || !this.actions[data.relayId]) {
          return
        }

        if (data.result && this.actions[data.relayId] && data.state) {
          this._notifyService.showMessage({
            text: 'Relay turn on after delay to turn on',
            type: "success"
          });
        }else {
          this._notifyService.showMessage({
            text: 'Relay turned off',
            type: "success"
          });
          this.actions[data.relayId].disableAction = false;
          this.actions[data.relayId].sendAction = false;
        }
      }
    );

    this.socket.on('regular data from relay', (data) => {
      this.relays.find((relay, index) => {
        if (relay._id === data['relayId']) {
          relay.reading = data['reading'];

          this.relays[index] = relay;
          this.relays[index].online = true;
          this.relays[index].lastConnection = new Date();
          this.setReadingToShow(data['relayId'], data['reading']);
          return true
        }else {
          return false
        }

      })

    });

    this.socket.on('send monitoring data', (data) => {
          if (!data || !data['relayId']) {
            console.log('no relay id');
            return
          }

          const relayId = data['relayId'];
          this.relays.find((relay, index) => {
            if (relay._id === relayId) {
              this.actions[relayId] = this.actions[relayId]? this.actions[relayId]: {};
              const isSendAction = this.actions[relay._id].sendAction;
              const isDisableAction = this.actions[relay._id].disableAction;
              this.relays[index].online = true;
              this.relays[index].lastConnection = new Date();

              if (!this.TOP_RELAYS.includes(relay.typeRelay)) {
                if (data["state"] !== undefined && !isDisableAction) {
                  relay.state = data["state"];
                } else if (data["state"] === relay['state'] && isSendAction) {
                  relay.state = data["state"];
                  this.actions[relayId].disableAction = false;
                  this.actions[relayId].sendAction = false;
                }

              }else {
                // this for relay TOR-326-100
                if (data["state_tor326"] !== undefined) {
                  const currentTorState = this.relays[index]["state_tor326"] || getTorStateByType(relay.typeRelay);

                  if ((currentTorState.join() === data["state_tor326"].join()) && isSendAction) {
                    this.relays[index]["state_tor326"] = data["state_tor326"];
                    this.actions[relayId].disableAction = false;
                    this.actions[relayId].sendAction = false;
                  }else if (!this.actions[relayId].sendAction && !isDisableAction) {
                    this.relays[index]["state_tor326"] = data["state_tor326"];
                  }
                }

                // Проверяем, если не послан запрос на откл-вкл, то разблокируем переключатель откл-вкл
                if (!isDisableAction) {
                  this.actions[relayId].disableAction = false;
                  this.actions[relayId].sendAction = false;
                }
              }

              return true
            }else {
              return false
            }

          });
        }
      );

    this.relaysInterval = setInterval(() => {
      this.updateOnlineStatus();
    }, 60000);
  }

  ngOnDestroy() {
    if (this.relaysInterval) {
      clearInterval(this.relaysInterval);
    }
    for (let i = 0; i < this.relays.length; i++) {
      this.socket.emit('leave room', {relayId: this.relays[i]._id, token: getToken()});
    }
  }

  getTorStateByType(relayType: string) {
    if (this.SWITCHER_3_TYPES.includes(relayType)) {
      return [0, 0, 0];
    }

    if (relayType === TOT_104_TH3) {
      return [0, 0, 0, 0];
    }

    if (relayType === TOP_324_T5 || relayType === TOP_324_T01) {
      return [0, 0, 0, 0];
    }

    return [0, 0, 0, 0, 0, 0];
  }

  getLastReadings() {
    this._userService.getlastRelayReadings()
      .subscribe(
        (success) => {
          const readings = success['readings'] || {};
          console.log('getlastRelayReadings', success);

          for (let i = 0; i < this.relays.length; i++) {
            const relay = this.relays[i];
            if (readings[relay._id]) {
              this.relays[i].reading = readings[relay._id]['data'];
              this.setReadingToShow(relay._id, readings[relay._id]['data']);
            }
          }
        });

  }

  chooseReadingType(relayId: string, readingOption: ShowReadingOption) {
    const jsonOptions: string | null = localStorage.getItem('showReadingOptions');
    let readingOptions = {};

    if (jsonOptions) {
      try {
        readingOptions = JSON.parse(jsonOptions);
      }catch(e) {
        console.log(e);
      }
    }

    readingOptions[relayId] = readingOption;

    const show = this.readingChoosenOption[relayId].show
    this.readingChoosenOption[relayId] = readingOption;

    this.readingChoosenOption[relayId].show = show;
    localStorage.setItem('showReadingOptions', JSON.stringify(this.readingChoosenOption));

    const relay = this.relays.find((relay) => relay._id === relayId);

    if (relay) {
      this.setReadingToShow(relayId, relay.reading);
    }
  }

  toggleIndicators(relayId: string, show: boolean) {
    this.readingChoosenOption[relayId].show = show;
    this.readingChoosenOption[relayId].type = this.readingChoosenOption[relayId].type || 'voltage';

    localStorage.setItem('showReadingOptions', JSON.stringify(this.readingChoosenOption));
  }

  setReadingToShow(relayId: string, reading: any) {
    const readingChoosenOption: ShowReadingOption = this.readingChoosenOption[relayId] || this.defaultReadingChoosenOption;
    if (!reading) {
      this.readingChoosen[relayId] = 0;
      return;
    }

    const relay = this.relays.find((relay) => relay._id === relayId);
    const voltageKoef = relay && relay.energySettings && relay.energySettings.voltageKoef || 1;
    const currentKoef = relay && relay.energySettings && relay.energySettings.currentKoef || 1;
    const powerKoef = voltageKoef * currentKoef;
    let value = 0;

    try {
      switch(readingChoosenOption.type) {
        case 'powerActive':
          value = typeof reading.powerActive.max === 'number'? reading.powerActive.max:
            reading.powerActive.max.reduce((sum, item) => sum += item, 0);

          value = value * powerKoef / 1000;
          this.readingChoosen[relayId] = Math.floor(value * 1000) / 1000;
          break;
        case 'powerReactive':
          value = typeof reading.powerReactive.max === 'number'? reading.powerReactive.max:
            reading.powerReactive.max.reduce((sum, item) => sum += item, 0);
          value = value * powerKoef / 1000;
          this.readingChoosen[relayId] = Math.floor(value * 1000) / 1000;
          break;
        case 'tot_posEnergyPA':
          value = typeof reading.tot_posEnergyPA === 'number'? reading.tot_posEnergyPA:
            reading.tot_posEnergyPA.reduce((sum, item) => sum += item, 0);
          value = value / 1000;
          this.readingChoosen[relayId] = Math.floor(value * 1000) / 1000;
          break;
        case 'tot_posEnergyQA':
          value = typeof reading.tot_posEnergyQA === 'number'? reading.tot_posEnergyQA:
            reading.tot_posEnergyQA.reduce((sum, item) => sum += item, 0);
          value = value / 1000;
          this.readingChoosen[relayId] = Math.floor(value * 1000) / 1000;
          break;
        case 'voltage':
          if (readingChoosenOption.subtype === 'max') {
            this.readingChoosen[relayId] = typeof reading.voltage.max === 'number'? reading.voltage.max:
              Math.max(...reading.voltage.max);
          }else {
            this.readingChoosen[relayId] = typeof reading.voltage.min === 'number'? reading.voltage.min:
              Math.min(...reading.voltage.min);
          }
          break;
      }
    }catch(e) {
      this.readingChoosen[relayId] = 0;
    }

  }

  getRelaysStats() {
    this._userService.getRelaysStats(this.user._id)
      .subscribe(
        (success) => {

          let relays = success["relays"];

          if (this.relays) {
            for(let i = 0; i < this.relays.length; i++) {
              let relay = this.relays[i];
              for (let j = 0; j < relays['length']; j ++) {
                if (relay._id == relays[j]['_id']) {

                  for (let prop in relays[j]) {
                    this.relays[i][prop] = relays[j][prop];
                  }

                  this.relays[i]['typeRelay'] = relays[j]['typeRelay'];
                  if (this.TOP_RELAYS.includes(relays[j].typeRelay)) {
                    this.relays[i].state_tor326 = getTorStateByType(relays[j]['typeRelay']);
                  }
                }
              }
            }
          }
        },
        (error) => {
          console.log('onInit error ', error);
        }
      );
  }

  updateOnlineStatus() {
    this.relays.forEach((relay, i) => {
      this.relays[i].online = +new Date(this.relays[i]['lastConnection']) + 65 * 1000 > +new Date();
    })
  }

  deleteRelay(id: string): void {
    this._authHttp.delete('api/users/' + this.user._id + "/relay/" + id)
      .subscribe(
        (relay) => {
          this._notifyService.showMessage(
            {
              text: 'Relay have been removed',
              type: 'success'
            }
          );
          this.relays = this.relays.filter((item) => {
              return item._id !== id;
            });
          this.user.relays = this.relays;
          this._userService.saveLocalUser(this.user);

            console.log('relays', this.relays);
          },
        (error) => {
          this._notifyService.showMessage({
            type: 'error',
            text: 'Relay not deleted'
          });
            console.log('error - ', error);
        }
      )
  }

  addRelay(relayName:string, serialNumber:string): void {

    this._authHttp.post(
      this.ENDPOINT + this.user["_id"] + "/relay",
      {
        relay: {
          serialNumber: serialNumber,
          name: relayName,
          index: 9999
        }
      }
    )
      .subscribe(
        (success) => {
          let relay = success;
          this._notifyService.showMessage(
            {
              text: 'Relay have been added',
              type: 'success'
            }
          );

          this.relays.push(relay);
          this.user.relays = this.relays;
          this._userService.saveLocalUser(this.user);

        },
        (error) => {
          console.log('error - ', error);

          let message = error.error && error.error.message? error.error.message : error.statusText;
          this._notifyService.showMessage(
            {
              text: message,
              type: 'error'
            }
          );
        }
      )
  }

  changeUserInfo(name: string, value: string): void {
    let dialogRef = this._dialog.open(DialogChangeInfo, {
      width: '250px',
      data: { name: name, value: value }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;

      let data = {};

      console.log('res', result);

      name = result["name"];
      value = result["value"];

      data[result["name"]] = result["value"];
      this._authHttp.put(
        this.ENDPOINT + this.user["_id"],
        data
      )
        .subscribe(
          (success) => {
            this.user[name] = value;
            this._userService.saveLocalUser(this.user);
          }
        );
    })
  }

  addRelayDialog():void {
    let dialogRef = this._dialog.open(AddRelayDialog, {
      width: '250px'
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('result', result);
      if (!result) return;

      if (!result.relayName || !result.serialNumber) return;

      this.addRelay(result.relayName, result.serialNumber);
    })
  }

  changeRelayNameDialog(id: string, name: string){
    let dialogRef = this._dialog.open(ChangeRelayNameDialog, {
      width: '250px',
      data: {name: name, id: id}
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('result', result);
      if (!result) return;

      if (!result.relayName || !result.id) return;

      this.changeName(result.id, result.relayName);
    })
  }

  askDeleteRelay(id: string){
    let dialogRef = this._dialog.open(RemoveRelayDialog, {
      width: '250px',
      data: { id: id },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('result', result);
      if (!result) return;

      if (result.remove === true && result.id !== undefined) {
        this.deleteRelay(result.id);
      }
    })
  }

  changeName(id: string, name: string) {
    this._authHttp.put(
      this.ENDPOINT + this.user["_id"] + "/relay/name",
      {
        relay: {
          _id: id,
          name: name

        }
      }
    )
      .subscribe(
        (success) => {
          let relay = success;
          this._notifyService.showMessage(
            {
              text: 'Name was changed',
              type: 'success'
            }
          );

          for (let i = 0; i < this.relays.length; i++) {
            if (this.relays[i]._id === id) {
              this.relays[i].name = relay['name'];
              break;
            }
          }

          this._userService.saveLocalUser(this.user);
          console.log(1, success);

        },
        (error) => {
          console.log('error - ', error);
          this._notifyService.showMessage({
            type: 'error',
            text: 'Relay not changed'
          })
        }
      )
  }

  changeRelayTor(relayId, relayIndex) {
    this.relays.find((relay, index) => {
      if (relay._id === relayId) {
        if (!this.actions[relay._id] || this.actions[relay._id].sendAction || !relay.online) {
          return
        }
        this.actions[relay._id] = {
          disableAction: true
        };
        const newState = +(!this.relays[index]['state_tor326'][relayIndex]);
        this.relays[index]['state_tor326'][relayIndex] = newState;
        let dialogRef = this._dialog.open(makeActionDialog, {
          width: '250px',
          data: {relayState: newState}
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (!result || result.makeAction !== true) {
            this.relays[index]['state_tor326'][relayIndex] = +(!newState);
            this.actions[relay._id] = {};
            return;
          }

          if (result.makeAction === true) {
            this.makeActionTor(newState, relayIndex, relayId, index);
          }
        });

        return true
      };

      return false;
    });


  }

  makeActionTor(state, index, relayId, indexInRelays){
    this.actions[relayId].sendAction = true;
    this.actions[relayId].disableAction = true;

    try {
      this.socket.emit('user:need:action',
        {
          relayId: relayId,
          token: getToken(),
          message: {
            cond_relay: state,
            relay: index
          }
        },
        (response) => {
          if(!response.success) {
            this.actions[relayId].sendAction = false;
            this.actions[relayId].disableAction = false;
            this._notifyService.showMessage({
              type: 'error',
              text: `Помилка ${state?'увімкнення':'вимкнення'} реле`
            })
            this.relays[indexInRelays]['state_tor326'][index] = +(!this.relays[indexInRelays]['state_tor326'][index]);
            return
          }

          this._notifyService.showMessage({
            type: 'success',
            text: 'Чекаємо підтвердження від реле'
          })
        })
    }catch(e){
      console.error(e);
      this.actions[relayId] = {};
      this._notifyService.showMessage({
        type: 'error',
        text: `Помилка ${this.relays[indexInRelays]['state_tor326'][index]?'увімкнення':'вимкнення'} реле`
      })
      this.relays[indexInRelays]['state_tor326'][index] = +(!this.relays[indexInRelays]['state_tor326'][index]);
    }
  }

  makeAction(id: string, on: boolean): void {
    let relay = this.relays.find((item) => {
      return item._id === id
    })
    relay.state = on;
    if (!this.actions[id]) {
      this.actions[id] = {}
    }

    this.actions[id].lastAction = relay.state;
    console.log('make action ', relay.state, this.user._id, this.user.username);

    this.actions[id].disableAction = true;
    this.actions[id].sendAction = true;

    this.socket.emit('need action',
      {
        on: on,
        relayId: relay._id,
        userId: this.user._id,
        username: this.user.username,
        token: getToken()
      },
      (response) => {
        if (!response.success) {
          this.actions[id] = {}
        }

        this._notifyService.showMessage({
          text: response.message,
          type: response.success?"success":"error"
        });
      });
  }

  makeActionDialog(id, relayState){
    let relay = this.relays.find((item) => {
      return item._id === id
    });

    this.actions[id] = {
      disableAction: true
    }

    let dialogRef = this._dialog.open(makeActionDialog, {
      width: '250px',
      data: {relayState: relayState}
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result || result.makeAction !== true) {
        relay.state = !relayState;
        this.actions[id] = {};
        return;
      }

      if (result.makeAction === true) {
        this.makeAction(id, relayState);
      }
    })
  }

  outMenuClick(){
    if(this.showDD.includes(true)) {
      this.showDD = [];
    }

    document.removeEventListener('click', this.outMenuClick)
  }

  onClickOnRelayIndicator(e, showDD: boolean | undefined, index: number) {
    this.showDD = [];
    this.showDD[index] = !showDD;

    e && e.stopPropagation();
    if (!showDD) {
      document.addEventListener('click', this.outMenuClick.bind(this))
    }else {
      document.removeEventListener('click', this.outMenuClick.bind(this))
    }
  }

  drop(event: any, index: number) {
    const elementIndex = +event.dataTransfer.getData("text");
    event.dataTransfer.clearData();

    if (elementIndex === index) {
      return;
    }

    const oldRelays = copyObj(this.relays);
    const elements = this.relays.splice(elementIndex, 1);

    this.relays.splice(index, 0, elements[0]);

    this.relays = this.relays.map((item, index) => {
      item.index = index;
      return item;
    });
    this.allowDragRelayList = false;

    this.changeRelaysSort()
      .subscribe(
        (success) => {
          this.allowDragRelayList = true;
          this._notifyService.showMessage({
            text: 'Порядок приладів успішно змінено',
            type: "success"
          });
        },
        (error) => {
          this.allowDragRelayList = true;
          this.relays = oldRelays;
          this._notifyService.showMessage({
            text: 'Не вдалося змінити порядок приладів',
            type: "error"
          });
        }
      );

  }

  drag(ev, index) {
    ev.dataTransfer.setData("text/plain", index);
  }

  allowDrop(ev) {
    ev.preventDefault();
  }

  changeRelaysSort() {
    const relays = this.relays.map(item => ({
      name: item.name,
      serialNumber: item.serialNumber,
      _id: item._id,
      index: item.index,
    }));

    return this._authHttp.post(
      this.ENDPOINT + this.user["_id"] + '/relay/sort',
      {relays: relays}
    )
  }
}


