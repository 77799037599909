import {Component, Input, EventEmitter, Output, OnChanges, SimpleChanges} from '@angular/core';

@Component({
  selector: 'number-input-spinner',
  templateUrl: "number-input.html",
  styleUrls: ["number-input.scss"]
})
export class NumberInputCmp implements OnChanges {
  @Input()  value: number;
  @Input()  min: number;
  @Input()  max: number;
  @Input()  step: number;
  @Input()  decimal: number;
  @Input()  settingsVar: string;
  @Output() change = new EventEmitter<number>();
  localValue: number = 0;

  constructor() {

  }

  ngOnChanges(changes: SimpleChanges){
    if (typeof changes.value === 'object') {
      let v = changes.value['currentValue'];
      v = this.checkMin(v);
      v = this.checkMax(v);
      v = this.round(v);

      this.localValue = v;
    }
  }

  checkMin(value: any){
    if(value < this.min) {
      return this.min;
    }

    return value;
  }


  checkMax(value: any) {
    if(value > this.max) {
      return this.max;
    }

    return value;
  }

  round(value: any){
    return Math.round(value*Math.pow(10, this.decimal || 0))/Math.pow(10, this.decimal || 0);
  }

  changed(newValue: string){
    let res = Number(newValue) || 0;

    res = this.checkMin(res);
    res = this.checkMax(res);
    res = this.round(res);

    this.localValue = res;
    this.change.emit(res);
  }

  arrowChange(way: number){
    let res = this.localValue;

    res = res + this.step * way;
    res = this.checkMin(res);
    res = this.checkMax(res);
    res = this.round(res);

    this.localValue = res;
    this.change.emit(res);
  }

}
