import { NgModule }             from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RelayVoltageCmp } from './components/relay-voltage.component';
import { RelayPowerCmp } from './components/relay-power.component';
import { RelayTemperatureComponent } from './components/relay-temperature.component';
import { RelayEnergyCmp } from './components/relay-energy.component';
import { RelayLogsCmp } from './components/relay-logs.component';
import { RelayCurrentCmp } from './components/relay-current.component';
import { RelayRealtimeMonitoringCmp } from './components/relay-realtime-monitoring.component';
import { RelayApplianceCmp } from './components/relay-appliance.component';
import { RelayScheduleCmp } from './components/relay-schedule.component';
import { RelayPriceCmp } from './components/relay-price.component';
import { RelayCmp } from './components/relay-cmp';
import { RelayPublicCmp } from './components/relay-public.component';
import { RelaySettingsCmp } from './components/relay-settings.component';
import { AuthToRelayGuardService, AuthToPublicRelayGuardService } from '../lib/services/auth-guard';
import { RelayDefencesComponent } from './components/relay-defences.component'
import { RelayProtectComponent } from './components/relay-protect.component'
import { PendingChangesGuard } from '../lib/services/save-changes.guard'
import { RelayLevelComponent } from "./components/relay-level.component";

const relayRoutes: Routes = [
  {
    path: 'relay/:sn',
    component: RelayCmp,
    children: [
      {
        path: 'energy',
        component: RelayEnergyCmp,
        canActivate: [AuthToRelayGuardService]
      },
      {
        path: 'costs',
        component: RelayPriceCmp,
        canActivate: [AuthToRelayGuardService]
      },
      {
        path: 'logs',
        component: RelayLogsCmp,
        canActivate: [AuthToRelayGuardService]
      },
      {
        path: 'voltage',
        component: RelayVoltageCmp,
        canActivate: [AuthToRelayGuardService]
      },
      {
        path: 'power',
        component: RelayPowerCmp,
        canActivate: [AuthToRelayGuardService]
      },
      {
        path: 'current',
        component: RelayCurrentCmp,
        canActivate: [AuthToRelayGuardService]
      },
      {
        path: 'temperature',
        component: RelayTemperatureComponent,
        canActivate: [AuthToRelayGuardService]
      },
      {
        path: 'levelType',
        component: RelayLevelComponent,
        canActivate: [AuthToRelayGuardService]
      },
      {
        path: 'monitoring',
        component: RelayRealtimeMonitoringCmp,
        canActivate: [AuthToRelayGuardService]
      },
      {
        path: 'appliance',
        component: RelayApplianceCmp,
        canActivate: [AuthToRelayGuardService]
      },
      {
        path: 'schedule',
        component: RelayScheduleCmp,
        canActivate: [AuthToRelayGuardService]
      },
      {
        path: 'settings',
        component: RelaySettingsCmp,
        canActivate: [AuthToRelayGuardService]
      },
      {
        path: 'defence',
        component: RelayDefencesComponent,
        canActivate: [AuthToRelayGuardService],
        canDeactivate: [PendingChangesGuard]
      },
      {
        path: 'protect',
        component: RelayProtectComponent,
        canActivate: [AuthToRelayGuardService],
        canDeactivate: [PendingChangesGuard]
      },
      {
        path: '',
        component: RelayEnergyCmp,
        canActivate: [AuthToRelayGuardService]
      }
    ]
  },
  {
    path: 'public/relay/:sn',
    component: RelayPublicCmp,
    children: [
      {
        path: 'energy',
        component: RelayEnergyCmp,
        canActivate: [AuthToPublicRelayGuardService]
      },
      {
        path: 'voltage',
        component: RelayVoltageCmp,
        canActivate: [AuthToPublicRelayGuardService]
      },
      {
        path: 'current',
        component: RelayCurrentCmp,
        canActivate: [AuthToPublicRelayGuardService]
      },
      {
        path: 'temperature',
        component: RelayTemperatureComponent,
        canActivate: [AuthToPublicRelayGuardService]
      },
      {
        path: 'monitoring',
        component: RelayRealtimeMonitoringCmp,
        canActivate: [AuthToPublicRelayGuardService]
      },
      {
        path: '',
        component: RelayEnergyCmp,
        canActivate: [AuthToPublicRelayGuardService]
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(relayRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class RelayRoutingModule { }
