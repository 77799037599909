import { environment } from '../environments/environment';

import {NgModule, ErrorHandler, Injectable} from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule  } from '@angular/platform-browser';
import { App }   from './app';
import { FrontCmp }   from './front/components/front-cmp';
import { RelayRoutingModule } from './relay/relay-routing.module';
import { appRouting } from './app-routing';

import { AuthService }   from './auth/services/auth-service';
import { LoginCmp }   from './auth/components/login-cmp';
import { SignupCmp }   from './auth/components/signup-cmp';
import { ResetPasswordCmp } from './auth/components/reset-password';
import { ChangePasswordCmp } from './auth/components/change-password';

import { UserCmp }   from './user/components/user-cmp';
import { UserSettingsComponent } from './user/components/user-settings.component';
import { UserService }   from './user/services/user-service';
import { AddRelayByQRCmp } from './user/components/addRelayByQR.component';
import { RemoveRelayDialog } from './user/components/removeRelayDialog.component';
import { RelayCmp }   from './relay/components/relay-cmp';
import { RelayPublicCmp }   from './relay/components/relay-public.component';
import { RelayStatsCmp }   from './relay/components/relay-stats.component';
import { RelayTemperatureComponent } from './relay/components/relay-temperature.component';
import { RelayEnergyCmp } from './relay/components/relay-energy.component';
import { RelayLogsCmp } from './relay/components/relay-logs.component';
import { RelayVoltageCmp } from './relay/components/relay-voltage.component';
import { RelayPowerCmp } from './relay/components/relay-power.component';
import { RelayPriceCmp } from './relay/components/relay-price.component';
import { RelayCurrentCmp } from './relay/components/relay-current.component';
import { RelayRealtimeMonitoringCmp } from './relay/components/relay-realtime-monitoring.component';
import { RelayApplianceCmp } from './relay/components/relay-appliance.component';
import { RelayScheduleCmp } from './relay/components/relay-schedule.component';
import { RelaySettingsCmp }   from './relay/components/relay-settings.component';
import { RelayDateChooserComponent }   from './relay/components/relay-date-chooser.component';
import { RealTimeDropdownComponent } from './relay/components/real-time-dropdown.component';
import { RealTimeChartComponent }   from './relay/components/real-time-chart.component';
import { RelayDefencesComponent }   from './relay/components/relay-defences.component';
import { RelayProtectComponent }   from './relay/components/relay-protect.component';
import {RelayLevelComponent} from './relay/components/relay-level.component';
import { DefenceCardComponent }   from './relay/components/defence-card.component';
import { SettingsPriceComponent }   from './relay/components/settings/settings.price.component';
import { RelayService }   from './relay/services/relay-service';
import { AmplifierIndicatorCmp }   from './relay/components/amplifier-indicator.component';
import { makeActionDialog } from './relay/components/makeActionDialog.component';
import { RemoveApplianceComponent } from './relay/components/remove-appliance.component';
import { saveChangesModal} from './lib/components/saveChangesModal/save-changes.modal';
import {ScheduleEventDialog} from './relay/components/schedule-modal.component';
import {EnableScheduleDialog} from './relay/components/enable-schedule.component';
import {CustomPeriodModal} from './relay/components/custom-period.component';
import {InfoComponent} from './common/info.component';
import { ReportUsersComponent } from './admin/reports/components/report-users.component';
import { MqttIntegrationModal } from './relay/components/modals/mqtt-integration.modal';
import { TelegramIntegrationModal } from './relay/components/modals/telegram-integration.modal';
import { WebhookIntegrationModal } from './relay/components/modals/webhook-integration.modal';

import { TestRelayListComponent } from './admin/testRelay/components/test-relay-list.component';
import { TestRelayComponent } from './admin/testRelay/components/test-relay.component';

import { AuthGuardService, AuthToRelayGuardService, AuthToPublicRelayGuardService } from './lib/services/auth-guard';

import { LoadingSpinnerCmp } from './lib/components/loadingSpinner/loading-spinner.component';
import { NumberInputCmp } from './lib/components/numberInput/number-input.component';
import { PendingChangesGuard } from './lib/services/save-changes.guard';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AngularDateTimePickerModule } from 'angular2-datetimepicker';

import { ChartModule } from 'angular2-highcharts';
import { HighchartsStatic } from 'angular2-highcharts/dist/HighchartsService';

// interceptor
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './lib/interceptors/auth.interceptor';

import { SocketIoModule, SocketIoConfig } from 'ng-socket-io';

const config: SocketIoConfig = { url: '/',
  options: {
    pingTimeout: 60000,
    pingInterval: 25000,
    transports: ['polling', 'websocket'],
    forceNew: true
  }
};

import { DialogChangeInfo } from './lib/components/changeInfo/dialogChangeInfo.component';
import { AddRelayDialog } from './user/components/addReayDialog.component';
import { ChangeRelayNameDialog } from './user/components/changeRelayNameDialog.component';

import { NotifyService } from './lib/services/notify.service';
import * as moment from 'moment';

moment.locale('uk');

import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule
} from '@angular/material';
import {AskFirmwareModal} from './admin/testRelay/components/ask-firmware.modal';

/**
 * NgModule that includes all Material modules that are required
 */
@NgModule({
  exports: [    // Material
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule
  ]
})
export class MaterialModule {}

declare var require: any;
export function highchartsFactory() {
  let hc = require('highcharts/highstock');
  let hcm = require('highcharts/highcharts-more');
  let hcsg = require('highcharts/modules/solid-gauge');
  hcm(hc);
  hcsg(hc);
  return hc;
}

@NgModule({
    imports: [
      BrowserModule,
      BrowserAnimationsModule,
      FormsModule,
      ReactiveFormsModule,
      HttpClientModule,
      RelayRoutingModule,
      appRouting,
      MaterialModule,
      SocketIoModule.forRoot(config),
      ChartModule,
      AngularDateTimePickerModule
    ],
    declarations: [
      App,
      FrontCmp,
      LoginCmp,
      SignupCmp,
      ResetPasswordCmp,
      ChangePasswordCmp,
      UserCmp,
      UserSettingsComponent,
      AddRelayByQRCmp,
      RelayCmp,
      RelayPublicCmp,
      RelayStatsCmp,
      RelayEnergyCmp,
      RelayTemperatureComponent,
      RelayLevelComponent,
      RelayPriceCmp,
      RelayLogsCmp,
      RelayVoltageCmp,
      RelayCurrentCmp,
      RelayPowerCmp,
      RelayRealtimeMonitoringCmp,
      RelayApplianceCmp,
      RelayScheduleCmp,
      RelaySettingsCmp,
      RelayDateChooserComponent,
      RealTimeDropdownComponent,
      RealTimeChartComponent,
      RelayDefencesComponent,
      RelayProtectComponent,
      DefenceCardComponent,
      SettingsPriceComponent,
      DialogChangeInfo,
      AddRelayDialog,
      RemoveRelayDialog,
      makeActionDialog,
      RemoveApplianceComponent,
      saveChangesModal,
      ScheduleEventDialog,
      EnableScheduleDialog,
      CustomPeriodModal,
      ChangeRelayNameDialog,
      LoadingSpinnerCmp,
      NumberInputCmp,
      AmplifierIndicatorCmp,
      TestRelayListComponent,
      TestRelayComponent,
      InfoComponent,
      ReportUsersComponent,
      MqttIntegrationModal,
      TelegramIntegrationModal,
      WebhookIntegrationModal,
      AskFirmwareModal,
    ],
    providers: [
      AuthService,
      AuthGuardService,
      AuthToRelayGuardService,
      AuthToPublicRelayGuardService,
      RelayService,
      UserService,
      PendingChangesGuard,
      NotifyService,
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true,
      },
      {
        provide: HighchartsStatic,
        useFactory: highchartsFactory
      }
    ],
    entryComponents: [
      DialogChangeInfo,
      AddRelayDialog,
      ChangeRelayNameDialog,
      RemoveRelayDialog,
      makeActionDialog,
      RemoveApplianceComponent,
      saveChangesModal,
      ScheduleEventDialog,
      EnableScheduleDialog,
      CustomPeriodModal,
      MqttIntegrationModal,
      TelegramIntegrationModal,
      WebhookIntegrationModal,
      AskFirmwareModal,
    ],

  bootstrap: [
      App
    ],
})

export class AppModule {}
