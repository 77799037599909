export function round(number, levels) {
  if (typeof +number !== 'number') {
    return 0
  }
  
  return Math.round(number * Math.pow(10, levels))/Math.pow(10, levels);
}

export default {
  round: round
}
