import {
  Component,
  OnInit,
  OnDestroy,
  HostListener
} from "@angular/core";

import {Location} from "@angular/common";

import {
  Validators,
  FormGroup,
  FormControl
} from "@angular/forms";


import {
  RelayService
} from "../services/relay-service";

import {
  KMA_111_40,
  KIA_111_16,
  KMA_LIKE_RELAY_TYPES,
  KMP_121_60,
  KCP_321_40,
  KCP_321_60,
  TOP_RELAYS,
  KMI_121_60,
  KCP_301_60, KMT_101_40, KMI_121_60_V2, TEP_321_T01
} from '../constants'

import {Socket} from 'ng-socket-io';

import {NotifyService} from '../../lib/services/notify.service';

import {NavigationStart, Router, ActivatedRoute, ParamMap} from '@angular/router';

import 'rxjs/add/operator/switchMap';

import {ChartModule} from 'angular2-highcharts';
import {getToken} from '../../utils/authTokens';
import {Observable} from "rxjs/Observable";
import {convertRelayDataToCards, getKoefs, DefaultSettings, SettingsType} from '../services/protect-converter'
import { ComponentCanDeactivate } from '../../lib/services/save-changes.guard';
import {hasOwnProperty} from "tslint/lib/utils";
import {DefenceSettingsType} from "../relay.types";

const MinVoltage = 80;
const MaxVoltage = 280;

@Component({
  selector: "relay-protect-cmp",
  templateUrl: "../templates/relay-protect.html",
  styleUrls: ["../styles/relay-protect.scss"]
})
export class RelayProtectComponent implements OnInit, OnDestroy, ComponentCanDeactivate {
  loading: boolean = true;
  relayId: string;
  relaySN: string;
  isOwner: boolean = false;
  isActualSettings: boolean = false;
  isPublicRelay: boolean;
  isOnFrontPage: boolean;
  publicRelayName: string;
  subscriptions: any = [];
  users: any = [];
  settings: SettingsType = DefaultSettings;
  usersRestriction: any = {};
  energySettings: any = {};
  typeRelay: string;
  lastSettings: any = {};
  cards: any = {
    common: false,
    voltage: false,
    current: false,
    power: false,
    frequency: false,
    temperature: false,
    difCurrent: false
  };
  intervals: any = [];

  voltageLimits: any = {
    max: {
      max: 280,
      min: 220,
    },
    min: {
      max: 210,
      min: 80,
    }
  };

  currentLimits: any = {
    max: {
      max: 60,
      min: 0.1,
    }
  };

  powerLimits: any = {
    max: {
      max: 13.8,
      min: 0.02,
    }
  };

  cardLimits: any = {
    voltage: {
      max: 280,
      min: 220,
    },
    current: {
      max: 280,
      min: 220,
    }
  };
  relaysWithoutDifCard: string[] = [KIA_111_16, KMA_111_40, KMP_121_60, KCP_321_40, KCP_321_60, KCP_301_60];
  KMA_LIKE_RELAY_TYPES: string[] = KMA_LIKE_RELAY_TYPES;
  KMI_121_60: string = KMI_121_60;
  KMI_121_60_V2: string = KMI_121_60_V2;
  KCP_301_60: string = KCP_301_60;

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return this.settings.confirm;
  }

  constructor(private _relayService: RelayService,
              private route: ActivatedRoute,
              private router: Router,
              private socket: Socket,
              private location: Location,
              private notifyService: NotifyService) {
    let sn = this.route.snapshot.paramMap.get('sn');

    if (!sn) {
      let snArr = window.location['href'].match(/relay\/(\w*)/);

      sn = snArr?snArr[1]:'';
    }

    this.relaySN = sn

  }

  ngOnInit() {
    let sn = this.route.snapshot.paramMap.get('sn');

    if (!sn) {
      let snArr = window.location['href'].match(/relay\/(\w*)/);

      sn = snArr?snArr[1]:'';
    }

    this.relaySN = sn

    this.route.paramMap
      .subscribe((params) => {
        this.relaySN = params.get('sn')
        if (!this.relaySN) {
          let snArr = window.location.href.match(/relay\/(\w*)/);

          this.relaySN = snArr ? snArr[1] : '';
        }

        this._relayService.getSettings(this.relaySN)
          .subscribe(
            (success) => {
              console.log('success', success);
              this.energySettings = success['energySettings'] ? success['energySettings'] : {};

              const koefs = getKoefs(success['energySettings']);
              koefs.voltageKoef = koefs.voltageKoef || 1;
              koefs.currentKoef = koefs.currentKoef || 1;

              this.voltageLimits = {
                max: {
                  max: Math.round(MaxVoltage * koefs.voltageKoef),
                  min: Math.round(0.7 * koefs.voltageKoefU1),
                  step: Math.round(koefs.voltageKoefU1 / 230)
                },
                min: {
                  max: Math.round(0.9 * koefs.voltageKoefU1),
                  min: Math.round(MinVoltage * koefs.voltageKoef),
                  step: Math.round(koefs.voltageKoefU1 / 230)
                }
              };

              this.currentLimits = {
                max: {
                  max: Math.round(koefs.currentKoefI1),
                  min: Math.round(0.01 * koefs.currentKoefI1 ) / 10,
                  step: Math.round(0.1 * koefs.currentKoefI1 ) / 10
                }
              };

              this.relayId = success['_id'];
              this.isOwner = success['isOwner'];
              this.usersRestriction = success['usersRestriction'] ? success['usersRestriction'] : {};
              this.isActualSettings = success['isActualSettings'];
              this.isPublicRelay = success['isPublic'];
              this.isOnFrontPage = success['isOnFrontPage'];
              this.publicRelayName = success['publicName'];
              this.publicRelayName = success['publicName'];
              this.typeRelay = success['typeRelay'] || '';

              this.settings = convertRelayDataToCards(koefs, success['settings']);

              const powerK = koefs.currentKoefI1 * koefs.voltageKoefU1 / 1000;

              const calculatedStep = this.settings.power.max > 100? Math.ceil(this.settings.power.max / 100):
                this.settings.power.max > 1? 0.1: 0.01;

              this.powerLimits = {
                max: {
                  max: Math.round(powerK),
                  min: 0,
                  step: calculatedStep
                }
              };

              if (this.typeRelay === KMA_111_40) {
                this.currentLimits.max.max = 40;
              }else if (this.typeRelay === KIA_111_16) {
                this.currentLimits.max.max = 16;
                this.powerLimits.max.max = 3.68;
              }

              console.log('this.settings', this.settings);

              if (TOP_RELAYS.includes(this.typeRelay) || this.typeRelay === TEP_321_T01) {
                this.router.navigate([`/relay/${this.relaySN}/energy`]);
              }

              if (this.typeRelay === KMT_101_40) {
                this.router.navigate([`/relay/${sn}/schedule`]);
              }

              this.users = success['users'];
              this.loading = false;

              this.lastSettings = this.copySettings();
              const self = this;
              this.socket.emit('join room', {relayId: this.relayId, token: getToken()});
              if (success['users'] && success['users'].length) {
                let users = success['users'].map((item) => {
                  if (item.username) {
                    item.username = item.username;
                  }

                  if (item.email) {
                    item.email = item.email;
                  }
                })
              }

              const checkModelInterval = setInterval(this.checkModel.bind(this), 500)
              this.intervals.push(checkModelInterval);
            },
            (error) => {
              console.log('error', error);
            });
      });



    let changeSettingsEvent = this.socket.fromEvent('server:settings:changed')
      .subscribe(
        (data) => {
          console.log('server:settings:changed', data);
          if (data['ok']) {


            const koefs = getKoefs(this.energySettings);

            this.settings = convertRelayDataToCards(koefs, data['settings']);

            if (this.typeRelay === KMA_111_40) {
              this.currentLimits.max.max = 40;
            }else if (this.typeRelay === KIA_111_16) {
              this.currentLimits.max.max = 16;
              this.powerLimits.max.max = 3.68;
            }else {
              this.currentLimits.max.max = 63;
              this.powerLimits.max.max = 13.8;
            }

            if (TOP_RELAYS.includes(this.typeRelay) || this.typeRelay === TEP_321_T01) {
              this.router.navigate([`/relay/${this.relaySN}/energy`]);
            }

            this.settings.confirm = true;

            for (let key in this.cards) {
              if (this.cards.hasOwnProperty(key)) {
                this.cards[key] = false;
              }
            }

            this.notifyService.showMessage({
              text: "Налаштування змінені у приладі",
              type: "success"
            });
          }
        }
      );

    this.subscriptions.push(changeSettingsEvent);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item) => {
      item.unsubscribe();
    });

    this.intervals.forEach((item) => {
      clearInterval(item);
    });
  }

  checkModel(){
    for ( const prop in this.settings) {
      if (typeof this.settings[prop] === 'object'){
        this.lastSettings[prop] = this.lastSettings[prop] || {};
        for(const key in this.settings[prop]) {
          if (this.settings[prop][key] !== this.lastSettings[prop][key]){
            this.cards[prop] = true
          }
        }
      }
    }

    this.lastSettings = this.copySettings();
  }

  copySettings(){
    let newSettings = {}

    for ( const prop in this.settings) {
      if (typeof this.settings[prop] === 'object') {
        newSettings[prop] = {}
        for (const key in this.settings[prop]) {
          newSettings[prop][key] = this.settings[prop][key]
        }
      }
    }

    return newSettings;
  }

  saveSettings(type: string) {
    this.settings.confirm = false;
    let settings = this.copySettings();

    let setToSend = {
      relayId: this.relayId,
      settings: settings,
      token: getToken()
    };

    const koefs = {
      currentKoef: this.energySettings && this.energySettings.currentKoef || 1,
      voltageKoef: this.energySettings && this.energySettings.voltageKoef || 1,
    };

   if (setToSend.settings['current']) {
      setToSend.settings['current'].category = +setToSend.settings['current'].category
     // переводим ток из ампер в миллиамперы
      setToSend.settings['current'].max = Math.round(setToSend.settings['current'].max*1000 / koefs.currentKoef)
    }

    if (setToSend.settings['difCurrent']) {
      setToSend.settings['difCurrent'].max = +setToSend.settings['difCurrent'].max / koefs.currentKoef
    }

    if (setToSend.settings['power']) {
      // переводим мощность из киловатт в ватты
      setToSend.settings['power'].max = Math.round(setToSend.settings['power'].max*1000 / (koefs.currentKoef * koefs.voltageKoef))
    }

    if (setToSend.settings['voltage']) {
      setToSend.settings['voltage'].max = setToSend.settings['voltage'].max / koefs.voltageKoef;
      setToSend.settings['voltage'].min = setToSend.settings['voltage'].min / koefs.voltageKoef;
    }

    if (this.relaysWithoutDifCard.includes(this.typeRelay)) {
      delete setToSend.settings['difCurrent'];
    }

    if (this.typeRelay === KCP_301_60) {
      delete setToSend.settings['current'];
      delete setToSend.settings['power'];
    }

    let successSocket = this.socket.emit('user:settings:changed', setToSend, (data)=> {
      if (data['success']) {
        this.notifyService.showMessage({
          text: "Чекаємо підтвердження від приладу",
          type: "success"
        });
        for (let key in this.cards) {
          if (this.cards.hasOwnProperty(key)) {
            this.cards[key] = false;
          }
        }

      }else {
        this.notifyService.showMessage({
          text: "Сталася помилка збереження налаштувань",
          type: "failed"
        });
      }
    });
  }

  validateNumber(event: any, min: number, max: number): any {
    const value = +event.target.value;
    if (!event || typeof value !== 'number' || isNaN(value)) {
      event.value = min;
    } else if (value < min) {
      event.value = min;
    }else if (value > max) {
     event.value = max;
    } else {
      event.value = value;
    }

    return event;
  }

  roundNumber(number: number, decimal: number = 0): number {
    const decMulti = Math.pow(10, decimal || 0);

    const newValue = decimal === 0? Math.ceil(number): Math.round(number * decMulti) / decMulti;

    return newValue;
  }

  changeNumber(event: any, type: string, prop: string, decimal: number) {
    if (!event || typeof event.value !== 'number') {
      return
    }

    const decMulti = Math.pow(10, decimal || 0);

    const newValue = decimal === 0? Math.ceil(event.value): Math.round(event.value * decMulti) / decMulti;

    event.target.value = newValue;
    this.settingsChanged(newValue, type, prop);
  }

  settingsChanged(event: number, type: string, prop: string){

    console.log('settingsChanged', event, type, prop);
    if (typeof event === 'number') {
      this.settings[type][prop] = event;
      this.cards[type] = true;
    }
  }

  changeProtectEnable(protectType: string) {
    this.settings[protectType].enable = !this.settings[protectType].enable
  }

  dynamicCurrentStep(event: any) {
    const value = event && event.value;
    const source = event && event.source;
    if (value <= 1) {
      this.currentLimits.max.step = 0.1;
      source.step = 0.1;
      this.settings.current.max = value;
      source.value = value;
    }else {
      this.currentLimits.max.step = 1;
      this.settings.current.max = Math.ceil(value);
      source.value = Math.ceil(value);
      source.step = 1;
    }
  }

  dynamicPowerStep(event: any) {
    const value = event && event.value;
    const source = event && event.source;
    if (value <= 1) {
      this.powerLimits.max.step = 0.01;
      source.step = 0.01;
      this.settings.power.max = value;
      source.value = value;
    }else if (value < 100){
      this.powerLimits.max.step = 0.1;
      source.step = 0.1;
      this.settings.power.max = Math.round(value * 10) / 10;
      source.value = Math.round(value * 10) / 10;
    }else {
      this.powerLimits.max.step = Math.ceil(value / 100);
      this.settings.power.max = value;
      source.value = value;
      source.step = Math.ceil(value / 100);
    }
  }
}
