import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'change-relay-name-dialog',
  templateUrl: '../templates/changeRelayNameDialog.html',
})
export class ChangeRelayNameDialog {
  name:string = '';

  constructor(
    public dialogRef: MatDialogRef<ChangeRelayNameDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
