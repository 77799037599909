import {
  Component,
  OnInit,
  OnDestroy,
  Input
} from "@angular/core";

import {FormControl} from '@angular/forms';

import {DecimalPipe} from '@angular/common';

import {round} from '../../utils/math';
import * as moment from "moment";

import {RelayService} from "../services/relay-service";

import {NotifyService} from "../../lib/services/notify.service";

import {NavigationStart, Router, ActivatedRoute, ParamMap} from '@angular/router';

import 'rxjs/add/operator/switchMap';

import {ChartModule} from 'angular2-highcharts';
import {UserService} from "../../user/services/user-service";
import {ChoosenDateType, DateOptionsType} from "../relay.types";
import {energyDateTypes, KCP_301_60, KCP_303_60, KMT_101_40, TOT_104_TH3, TOT_106_TH6} from '../constants'
import {Zone} from "./settings/settings.price.component";

@Component({
  selector: "relay-price",
  templateUrl: "../templates/relay-price.html",
  styleUrls: ["../styles/relay.scss", "../styles/relay-energy.scss"]
})
export class RelayPriceCmp implements OnInit, OnDestroy {
  energyDateTypes: DateOptionsType[] = [...energyDateTypes];
  dateType: ChoosenDateType;
  relay: any = {};
  sn: string;

  loading: boolean = true;
  reportMode: string = 'day';
  from: string;
  to: string;
  dateString: string;
  currentDate: Date = new Date();
  chartCategories: any = [];

  type: string = 'energy';
  title: string = 'Energy';
  options: any;
  values: any = [];
  total: any = {};
  costs: any = {};
  chart: any;
  showSeries: object = {
    'common': true,
    '2Zone': false,
    '3Zone': false
  };
  user: any;
  relays: any;
  fromDate = new FormControl(new Date());
  toDate = new FormControl(new Date());
  priceZones: (Zone & {total: number})[] = [];
  allowDateTypes = ['today', 'yesterday', 'custom', 'last month', 'this month'];
  totalPrice: number = 0;
  totalP: number = 0;


  constructor(public _relayService: RelayService,
              private _notifyService: NotifyService,
              private _route: ActivatedRoute,
              private _userService: UserService,
              private router: Router) {

    //this.dateString = moment(this.currentDate).format("MMM, YYYY");
    this.chartCategories = [];
    //this.currentDate = new Date();

    const temp = [];
    this.energyDateTypes.forEach((item, i) => {
      if (this.allowDateTypes.indexOf(item.value) === -1) {
        temp.push({...item, disabled: true})
      }else {
        temp.push({...item})
      }
    });
    this.energyDateTypes = temp;


    let sn = this._route.snapshot.paramMap.get('sn');

    if (!sn) {
      let snArr = window.location.href.match(/relay\/(\w*)/);

      sn = snArr ? snArr[1] : '';
    }

    this.sn = sn

    this.options = {
      chart: {
        type: 'column'
      },
      title: {
        text: ''
      },
      legend: {
        enabled: false
      },
      xAxis: {
        categories: ['Тарифи'],
        crosshair: true,
        type: 'category'
      },
      yAxis: {
        title: {
          text: 'Вартість, грн'
        }
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 2
        },
        dataLabels: {
          enabled: true,
          color: '#FFFFFF'
        }
      },
      series: [],
      tooltip: {
        headerFormat: '<span style="color:{series.color}">\u25CF</span> {series.name}<br/>',
        pointFormat: 'Спожито, kWh: <b>{point.energy:.3f}</b><br/>Вартість, грн: <b>{point.y:.2f}</b>'
      }
    };
  }

  ngOnInit() {
    this.loading = true;
    let dateParams = this._relayService.getDate();

    this.dateString = dateParams.dateString;
    this.dateType = dateParams;

    this._route.paramMap
      .subscribe((success) => {
        let sn = this._route.snapshot.paramMap.get('sn');
        if (!sn) {
          let snArr = window.location.href.match(/relay\/(\w*)/);

          sn = snArr ? snArr[1] : '';
        }
        this.sn = sn;

        this.getRelay();

        if (this.relay.typeRelay === KCP_301_60 || this.relay.typeRelay === KCP_303_60) {
          this.router.navigate([`/relay/${sn}/voltage`]);
        }else if (this.relay.typeRelay === TOT_104_TH3 || this.relay.typeRelay === TOT_106_TH6) {
          this.router.navigate([`/relay/${sn}/temperature`]);
        }else if (this.relay.typeRelay === KMT_101_40) {
          this.router.navigate([`/relay/${sn}/schedule`]);
        }
      })

    let user = this._userService.getLocalUser()

    if (user && user['_id']) {
      this.user = user;
      this.relays = user['relays'];
    } else {
      let userId = JSON.parse(localStorage.getItem('currentUser'))._id;
      this._userService.getUser(userId)
        .subscribe((user) => {
          this.user = user;
          this.relays = user['relays'];
        }, (e) => {
          console.log(e);
        })
    }
  }

  ngOnDestroy() {
    this._relayService.saveDate(this.dateType);

    console.log('RelayCostsCmp Destroy', this.reportMode, this.fromDate.value, this.toDate.value, this.currentDate.toISOString());
  }

  getRelay(): void {
    this.relay = this._relayService.getLocal(this.sn);
    this.sn = this.relay.serialNumber;

    this.runReport(this.dateType.from, this.dateType.to);
  }

  setCurrentZone() {
    let zone = this.relay.zoneType,
      i = zone === '2Zone' ? 1 : zone === '3Zone' ? 2 : 0;

    if (!this.chart.series[i]) {
      return;
    }

    let name = this.chart.series[i].name;

    this.showSeries[zone] = true;

    name += ' *Ваш тариф';

    this.chart.series[i].update({
      name: name,
      color: 'rgb(252, 9, 41)',
      borderWidth: 2,
      visible: true,
      showInLegend: false
    });
  }

  triggerAnotherZone(zone: string) {
    let index = zone === '2Zone' ? 1 : zone === '3Zone' ? 2 : 0,
      show = !this.showSeries[zone];

    this.showSeries[zone] = show;

    this.chart.series[index].update({
      visible: show
    });

  }

  saveInstance(chartInstance: any) {
    let container = document.getElementsByClassName('relay-chart-energy')[0],
      width;

    width = container ? (container.clientWidth - 48) : (window.innerWidth - 48);

    this.chart = chartInstance;
    this.chart['setSize'](width);
    this.setCurrentZone();
  }

  runReport(from: number, to: number): void {
    const fromConverted: number = +moment(from).startOf('day');
    const toConverted: number = +moment(to).endOf('day');

    this.loading = true;

    this.totalPrice = 0;
    this.totalP = 0;
    if (this.chart && this.chart.series) {
      const seriesLength = this.chart.series.length;
      for (let i = 0; i < seriesLength; i++) {
        this.chart.series[0].remove();
      }
      this.chart['redraw']();
    }

    this._relayService.getCosts(this.sn, fromConverted, toConverted)
      .subscribe(
        (success) => {
          console.log('getCosts', success);
          if (!this.chart) return;

          if (!success['priceZones']) {
            this.loading = false;
            this._notifyService.showMessage({
              message: 'Some errors, try later',
              type: 'error'
            });
            return;
          }

          const priceZones: (Zone & {total: number; energy: number})[] = success['priceZones'] || [];
          const readings: any[] = success['readings'] || [];

          this.totalPrice =0;
          this.totalP =0;

          for (let priceZone of priceZones) {
            priceZone.total = 0;
            priceZone.energy = 0;
            for (let time of priceZone.times) {
              const from = +time.from;
              const to = +time.to;

              let totalPrice = 0;
              let totalEnergy = 0;

              readings.forEach((reading) => {
                const hour = new Date(reading.ts).getHours();
                if (hour >= from && hour < to) {
                  // divide by 1000 because readings are in Wt
                  totalPrice += (reading['posP'] || 0) * priceZone.price / 1000;
                  totalEnergy += (reading['posP'] || 0) / 1000;
                }
              });

              priceZone.total += totalPrice;
              priceZone.energy += totalEnergy;
            }

            priceZone.total = Math.round(priceZone.total * 100) / 100;
            priceZone.energy = Math.round(priceZone.energy * 1000) / 1000;
            this.totalPrice += priceZone.total;
            this.totalP += priceZone.energy;
          }

          this.priceZones = priceZones;

          this.totalPrice = Math.round(this.totalPrice * 100) / 100;
          this.totalP = Math.round(this.totalP * 1000) / 1000;

          console.log('this.priceZones', this.priceZones);
          const seriesLength = this.chart.series.length;
          for (let i = 0; i < seriesLength; i++) {
            this.chart.series[0].remove();
          }

          const colors = [
            '#f3b255',
            '#55f363',
            '#f355e5',
            '#F32525',
            '#7C2FF3',
            '#271BF3',
            '#8ED9F3',
            '#E3F321',
            '#BFF35E',
            '#F38200',
            '#00F314',
            '#9FF3B5',
            '#f3b255',
            '#55f363',
            '#f355e5',
            '#F32525',
            '#7C2FF3',
            '#271BF3',
            '#8ED9F3',
            '#E3F321',
            '#BFF35E',
            '#F38200',
            '#00F314',
            '#9FF3B5'
          ];

          const categories = priceZones.map((zone: Zone) => zone.name);
          this.chart.xAxis[0].setCategories(categories);
          for (let i = 0; i < priceZones.length; i++) {
            this.chart.addSeries({
              name: priceZones[i].name,
              color: colors[i],
              data: [{x: i, y: priceZones[i].total, energy: priceZones[i].energy}]
            }, true, true);
          }

          this.chart['redraw']();
          this.loading = false;
        },
        (error) => {
          console.log('error', error);
          this.loading = false;

          if (error && error.error && error.error.reason === "TIME_TOO_LONG"){
            this._notifyService.showMessage({
              message: 'Обраний період не може бути більше місяця',
              type: 'error'
            });
            return;
          }
          this._notifyService.showMessage({
            message: 'Сталася помилка. Спробуйте ще раз',
            type: 'error'
          });
        });
  }

  changeDatePeriod(mode: String, way: number) {
    console.log('changeDatePeriod ', mode, this.reportMode, way);
    let currentDate = this.currentDate;

    if (mode === 'month') {
      this.currentDate = new Date(currentDate.setFullYear(currentDate.getFullYear() + way));
      this.dateString = moment(this.currentDate).format("YYYY");
    } else if(mode === 'hour') {
      this.currentDate = new Date(new Date(currentDate.setMonth(currentDate.getMonth())).setDate((currentDate.getDate() + way)));
      this.dateString = moment(this.currentDate).format("DD MMM, YYYY");
    }else if(mode === 'day') {
      this.currentDate = new Date(currentDate.setMonth(currentDate.getMonth() + way));
      this.dateString = moment(this.currentDate).format("MMM, YYYY");
    }
  }

  changeMode(mode: string) {
    if (mode === 'day') {
      this.currentDate = new Date();
      this.dateString = moment(this.currentDate).format('MMM, YYYY');
    } else if(mode === 'hour') {
      this.currentDate = new Date();
      this.dateString = moment(this.currentDate).format('DD MMM, YYYY');
    }else if (mode === 'month') {
      this.currentDate = new Date();
      this.dateString = moment(this.currentDate).format('YYYY');
    }
  }

  onChoosenDate(typeObj: ChoosenDateType) {
    console.log('onChoosenDate');
    let from = moment(typeObj.from).startOf('day');
    let to = moment(typeObj.to).endOf('day');
    this.runReport(+from, +to);

    this.dateString = typeObj.dateString;
    this.dateType = typeObj
  }
}
