import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    const canChangeRoute = component.canDeactivate()
    if (canChangeRoute) {
      return true
    }else {
      return confirm('Ви маєте не збережені зміни. Натисніть "Скасувати" щоб повернутись і зберегти зміни або "OK" щоб залишити сторінку.');
    }
  }
}
