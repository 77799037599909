import {
  Component,
  OnInit,
  OnDestroy, Input
} from "@angular/core";

import {
  RelayService,
} from "../services/relay-service";

import {
  TOP_323_T01,
  TOP_323_T5,
  TOP_323_HT5,
  TOP_326_T01,
  TOP_RELAYS,
  KCP_323_60,
  KCP_303_60,
  TOC_326_T5,
  TOT_104_TH3, RelayNames, TOT_106_TH6, TOP_324_T5, TOP_324_T01, TEP_321_T01
} from '../constants'

import {Socket} from 'ng-socket-io';

import * as moment from "moment";

import {NavigationStart, Router, ActivatedRoute, ParamMap} from '@angular/router';

import 'rxjs/add/operator/switchMap';

import {NotifyService} from "../../lib/services/notify.service";
import {FormControl} from "@angular/forms";
import {getToken} from '../../utils/authTokens';
import {UserService} from "../../user/services/user-service";
import {MatDialog} from "@angular/material";
import {ScheduleEventDialog} from './schedule-modal.component'
import {EnableScheduleDialog} from './enable-schedule.component'

const TOP_RELAY_TYPES = TOP_RELAYS;

@Component({
  selector: "relay-schedule-cmp",
  templateUrl: "../templates/relay-schedule.html",
  styleUrls: ["../styles/relay-schedule.scss"]
})
export class RelayScheduleCmp implements OnInit, OnDestroy {
  TOP_323_T01: string = TOP_323_T01;
  TOP_323_T5: string = TOP_323_T5;
  TOP_323_HT5: string = TOP_323_HT5;
  TOP_326_T01: string = TOP_326_T01;
  TOC_326_T5: string = TOC_326_T5;
  TOP_RELAY_TYPES: string[] = TOP_RELAY_TYPES;
  SWITCHER_3_TYPES: string[] = [TOP_323_T5, TOP_323_T01, KCP_323_60, TOP_323_HT5, KCP_303_60];
  numberOfRelays: number[] = [1];
  typeRelay: string = '';
  loading: boolean = true;
  scheduleModeReceived: boolean = false;
  relay: any = {
    status: false,
    energySettings: {}
  };
  relayId: string;
  relaySN: string;

  subscriptions: any = [];
  schedules: any = [];

  scheduleStart: number = Math.round(+new Date() / 1000);
  scheduleStop: number =  Math.round(+new Date() / 1000);
  scheduleEnd: number =  Math.round(+new Date() / 1000);
  scheduleCondition: string = "0";
  scheduleTimeRepeat: number = 0;
  scheduleTimeRepeatMode: string = "2";
  settings: any = {
    bigBanner: true,
    timePicker: true,
    format: 'dd-MMM-yyyy hh:mm',
    defaultOpen: false
  }

  mode_auto: number[] = [0];
  interval: any;
  weekday: number;
  hourStart: number = new Date().getHours();
  hourEnd: number = new Date().getHours();
  minuteStart: number = new Date().getMinutes();
  minuteEnd: number = new Date().getMinutes();
  user: any;
  relays: any;
  nextYear: number = +new Date() + 365*24*60*60*1000;
  relayNames: RelayNames | undefined = undefined;

  constructor(
    private _relayService: RelayService,
    private route: ActivatedRoute,
    private router: Router,
    private socket: Socket,
    private notifyService: NotifyService,
    private _userService: UserService,
    private _dialog: MatDialog
  ) {

    this.relaySN = this.route.snapshot.paramMap.get('sn');

    if (!this.relaySN) {
      let snArr = window.location.href.match(/relay\/(\w*)/);

      this.relaySN = snArr ? snArr[1] : '';
    }

    let subscription = this.route.paramMap
      .switchMap((params: ParamMap) => this._relayService.getRelaySchedules(this.relaySN))
      .subscribe(
        (success) => {
          console.log('relay', success);
          const { relayNames } = this._relayService.getLocal(this.relaySN);

          this.relayNames = relayNames.enable? relayNames: undefined;

          this.typeRelay = success['typeRelay'];
          let schedules = success['schedules'] || [];
          let schedulesArray = [[]];
          if (this.SWITCHER_3_TYPES.includes(this.typeRelay) ) {
            schedulesArray = [ [], [], [] ]
          }else if (this.typeRelay === this.TOP_326_T01 || this.typeRelay === this.TOC_326_T5 || this.typeRelay === TOT_106_TH6) {
            schedulesArray = [ [], [], [], [], [], [] ]
          } else if (this.typeRelay === TOT_104_TH3) {
            schedulesArray = [ [], [], [], [] ]
          }else if (this.typeRelay === TOP_324_T5 || this.typeRelay === TOP_324_T01) {
            schedulesArray = [ [], [], [], [] ]
          } else if (this.typeRelay === TEP_321_T01) {
            schedulesArray = [ [] ]
          }

          schedules.forEach((s) => {
            s.relay = s.relay || 0;
            schedulesArray[s.relay].push(s);
          });

          this.schedules = schedulesArray;
          this.schedules.forEach((s, i) => {
            this.calculateSchedules(i);
          });

          this.relayId = success['relayId'] || '';


          this.checkScheduleMode();
          this.loading = false;

          this.socket.emit('user:schedule:get_mode',
            {relayId: this.relayId, token: getToken()},
            (data) => {
              if (!data['success']) {
                this.notifyService.showMessage({
                  text: "Сталася помилка",
                  type: "failed"
                });
              }
            });
          this.socket.emit('user:schedule:get',
            {relayId: this.relayId, token: getToken()},
            (data) => {
              if (!data['success']) {
                this.notifyService.showMessage({
                  text: "Сталася помилка",
                  type: "failed"
                });
              }
            });
        }
      );

    this.subscriptions.push(subscription);

  }

  ngOnInit() {
    this.listenDisconnectRelay();

    this.subscriptions.push(this.socket.fromEvent('server:schedule:deleted')
      .subscribe(
        (data) => {
          console.log('server:schedule:deleted', data);

          if (data['error']) {
            this.notifyService.showMessage({
              message: 'Помилка видаленя розкладу з реле',
              type: 'error'
            });
            return;
          }

          this.schedules = this.schedules.map((schedule) => {
            return schedule.filter((item) => {
              if (item.key_sched === data['key_sched']) {
                this.notifyService.showMessage({
                  message: 'Розклад видалено з реле',
                  type: 'success'
                });
              }

              return item.key_sched !== data['key_sched']
            })
          });
        }
      ));

    this.subscriptions.push(this.socket.fromEvent('server:schedule:added')
      .subscribe(
        (data) => {
          console.log('server:schedule:added', data);

          if (data['error']) {
            this.notifyService.showMessage({
              message: 'Помилка додавання розкладу до реле',
              type: 'error'
            });
            return;
          }

          const relay: number = data["relay"] || 0;
          this.schedules[relay] = this.schedules[relay]? this.schedules[relay]: [];
          this.schedules[relay].push({...data});

          this.notifyService.showMessage({
            message: 'Розклад додано до реле',
            type: 'success'
          });
        }
      ));

    this.subscriptions.push(this.socket.fromEvent('server:schedule:set_mode_auto')
      .subscribe(
        (data) => {
          console.log('server:schedule:set_mode_auto', data);

          if (data['error']) {
            this.notifyService.showMessage({
              message: 'Помилка увімкнення розкладу',
              type: 'error'
            });
            return;
          }
          this.mode_auto = Number(+data['mode_auto']).toString(2).split('').reverse().map(item => +item);
          this.notifyService.showMessage({
            message: 'Автоматичний режим змінено',
            type: 'success'
          });
        }
      ));

    this.subscriptions.push(this.socket.fromEvent('server:schedule:get_mode')
      .subscribe(
        (data) => {
          this.scheduleModeReceived = true;
          this.mode_auto = Number(+data['mode_auto']).toString(2).split('').reverse().map(item => +item);
        }
      ));

    this.subscriptions.push(this.socket.fromEvent('server:schedule:check_schedule')
      .subscribe(
        (data) => {
          console.log('server:schedule:check_schedule', data);
          let schedules = data['schedules'] || [];
          let schedulesArray = [[]];
          if (this.SWITCHER_3_TYPES.includes(this.typeRelay) ) {
            schedulesArray = [ [], [], [] ]
          }else if (this.typeRelay === this.TOP_326_T01 || this.typeRelay === this.TOC_326_T5 || this.typeRelay === TOT_106_TH6) {
            schedulesArray = [ [], [], [], [], [], [] ]
          } else if (this.typeRelay === TOT_104_TH3) {
            schedulesArray = [ [], [], [], [] ]
          }else if (this.typeRelay === TOP_324_T5 || this.typeRelay === TOP_324_T01) {
            schedulesArray = [ [], [], [], [] ]
          } else if (this.typeRelay === TEP_321_T01) {
            schedulesArray = [ [] ]
          }

          schedules.forEach((s) => {
            schedulesArray[s.relay || 0].push(s);
          });

          this.schedules = schedulesArray;
        }
      ));
    let self = this;
    this.interval = setInterval(() => {
      self.schedules.forEach((s, i) => {
        self.calculateSchedules(i);
      })

    }, 15 * 1000)

    let user = this._userService.getLocalUser()

    if (user && user['_id']){
      this.user = user;
      this.relays = user['relays'];
    }else {
      let userId = JSON.parse(localStorage.getItem('currentUser'))._id;
      this._userService.getUser(userId)
        .subscribe((user) => {
          this.user = user;
          this.relays = user['relays'];
        }, (e) => {
          console.log(e);
        })
    }
  }

  ngOnDestroy() {
    console.log("DESTROY");

    this.subscriptions.forEach((item) => {
      item.unsubscribe();
    });

    clearInterval(this.interval);

  }

  listenDisconnectRelay(): void {
    let subscription = this.socket.fromEvent('relay disconnected')
      .subscribe(
        (data) => {
          console.log('listenDisconnectRelay relay CMP', data);
        }
      );

    this.subscriptions.push(subscription);
  }

  checkScheduleMode(){
    this.socket.emit('user:schedule:get_mode',
      {relayId: this.relayId, token: getToken()},
      (data) => {
        if (!data['success']) {
          this.notifyService.showMessage({
            text: "Сталася помилка",
            type: "failed"
          });
        }
      });
  }

  calculateSchedules(relay: number){
    this.schedules[relay] = this.schedules[relay].filter((item) => {
      return item.time_end >= Math.round(+new Date() / 1000);
    })

    this.schedules[relay].forEach((item) => {
      let date = Math.round(+new Date() / 1000);
      let period = +item['time_repeat'];

      if (item['time_start'] < date && period > 0) {
        let addTime = Math.ceil((date - item['time_start'])/period) * period;
        item['time_start'] = item['time_start'] + addTime;
        item['time_stop'] = item['time_stop'] + addTime;
      }
    })
  }

  addSchedule(event: any, relay: number) {
    console.log(event);

    this.socket.emit('user:schedule:add',
      {
        relayId: this.relayId,
        time_start: event.time_start,
        time_stop: event.time_stop,
        time_end: event.time_end,
        cond_relay: +event.cond_relay,
        time_repeat: event.time_repeat,
        token: getToken(),
        relay: relay
      },
      (data) => {
        if (data.success) {
          this.notifyService.showMessage({
            message: 'Чекаємо збереження у контролері',
            type: 'success'
          });

          this.scheduleStart = Math.round( +new Date() / 1000);
          this.scheduleStop = Math.round(+new Date() / 1000);
          this.scheduleEnd = Math.round(+new Date() / 1000);

          this.hourStart = new Date().getHours();
          this.hourEnd = new Date().getHours();
          this.minuteStart = new Date().getMinutes();
          this.minuteEnd = new Date().getMinutes();
          this.scheduleCondition = '0';
          this.scheduleTimeRepeat = 0;
        } else {
          this.notifyService.showMessage({
            message: 'Помилка збереження',
            type: 'error'
          });
        }
      })
  }

  removeSchedule(schedule: any) {

    if (!schedule || !this.schedules.length) {
      this.notifyService.showMessage({
        message: 'Невірно обрані дати розкладу',
        type: 'error'
      });
      return;
    }

    this.socket.emit('user:schedule:delete',
      {
        relayId: this.relayId,
        time_start: schedule.time_start,
        time_stop: schedule.time_stop,
        time_end: schedule.time_end,
        cond_relay: schedule.cond_relay,
        time_repeat: +schedule.time_repeat,
        key_sched: schedule.key_sched,
        token: getToken(),
        relay: schedule.relay
      },
      (data) => {
        if (data.success) {
          this.notifyService.showMessage({
            message: 'Чекаємо видалення у контролері',
            type: 'success'
          });
        } else {
          this.notifyService.showMessage({
            message: 'Помилка видалення',
            type: 'error'
          });
        }
      })
  }

  sendEditScheduleMessage(event: any) {
    console.log('sendEditScheduleMessage', event);
    if (!event) {
      this.notifyService.showMessage({
        message: 'Невірно обрані дати розкладу',
        type: 'error'
      });
      return;
    }

    this.socket.emit('user:schedule:edit',
      {
        relayId: this.relayId,
        time_start: event.time_start,
        time_stop: event.time_stop,
        time_end: event.time_end,
        cond_relay: event.cond_relay,
        time_repeat: +event.time_repeat,
        key_sched: event.key_sched,
        token: getToken(),
        relay: event.relay
      },
      (data) => {
        if (data.success) {
          this.notifyService.showMessage({
            message: 'Чекаємо відповіді від контролера',
            type: 'success'
          });
        } else {
          this.notifyService.showMessage({
            message: 'Помилка редагування',
            type: 'error'
          });
        }
      })
  }

  sendAutoScheduleEvent(mode_auto: number, relay: number) {
    this.socket.emit('user:schedule:set_mode_auto',
      {
        relayId: this.relayId,
        mode_auto: mode_auto,
        token: getToken(),
        relay: relay
      },
      (data) => {
        if (data.success) {
          this.notifyService.showMessage({
            message: 'Чекаємо підтвердження у контролері',
            type: 'success'
          });
        } else {
          this.notifyService.showMessage({
            message: 'Помилка',
            type: 'error'
          });
        }
      })
  }

  setAutoSchedule(relay: number) {
    let mode_auto = this.mode_auto[relay]? 0: 1;
    if (!this.mode_auto[relay]) {
      let dialogRef = this._dialog.open(EnableScheduleDialog, {
        width: '350px',
        data: {
          schedulesExist: !!this.schedules.length && !!this.schedules[relay].length
        }
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (!result) return;

        if (!result.submit) return;

        this.sendAutoScheduleEvent(mode_auto, relay);
      })
    } else {
      this.sendAutoScheduleEvent(mode_auto, relay);
    }
  }

  convertScheduleRepeat(type: number){
    let types = {
      0: "Ніколи",
      60: "Щохвилини",
      3600: "Щогодини",
      86400: "Щодня",
      604800: "Щотижня"
    }

    let result = types[type.toString()];

    return result || '';
  }

  convertScheduleDays(days: string) {
    return days;
  }

  editSchedule(schedule: any, index: number){
    let dialogRef = this._dialog.open(ScheduleEventDialog, {
      width: '500px',
      data: {
        isCreate: false,
        title: 'Створити нову подію',
        event: {
          ...schedule
        }
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('schedule edit', result);
      if (!result) return;

      if (!result.event) return;

      result.event.key_sched = schedule.key_sched;
      result.event.relay = schedule.relay;
      this.sendEditScheduleMessage(result.event)

    })
  }

  createScheduleEvent(relay: number){
    let dialogRef = this._dialog.open(ScheduleEventDialog, {
      width: '500px',
      data: {
        isCreate: true,
        title: 'Створити нову подію',
        event: {
          cond_relay: true
        }
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('schedule create, edit', result);
      if (!result) return;

      if (!result.event) return;

      this.addSchedule(result.event, relay);

    })
  }
}
