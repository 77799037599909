import {
  Component,
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  EventEmitter,
  Output
} from "@angular/core";

import {ChartComponent} from 'angular2-highcharts';
import {MonitoringData} from "../relay.types";
import { copyObj } from "../../utils/common";
import {
  TOP_RELAYS,
  KMA_LIKE_RELAY_TYPES,
  TOP_323_HT5,
  TOP_326_HT5,
  KCP_323_60,
  PHASE_3_RELAY_TYPES
} from '../constants';

const DEFAULT_CHART: string = 'voltage';
const DEFAULT_OPTIONS = {
  chart: {
    type: 'solidgauge',
    height: '110%'
  },
  title: {
    text: '',
    style: {
      fontSize: '16px',
      display: 'none'
    }
  },
  tooltip: {
    enabled: false
  },
  pane: {
    startAngle: -90,
    endAngle: 270,
    background: [{
      outerRadius: '112%',
      innerRadius: '88%',
      backgroundColor: 'rgba(220, 220, 220, 0.13)',
      borderWidth: 0
    }, { // background
      outerRadius: '88%',
      innerRadius: '0%',
      backgroundColor: '#fff',
      borderWidth: 0
    }]
  },
  yAxis: {
    min: 0,
    max: 440,
    lineWidth: 0,
    tickPositions: []
  },
  plotOptions: {
    solidgauge: {
      dataLabels: {
        enabled: true,
        inside: true
      },
      linecap: 'round',
      stickyTracking: false,
      rounded: true
    }
  },

  series: [{
    name: '',
    data: [{
      color: '#33b679',
      radius: '112%',
      innerRadius: '88%',
      y: 0
    }]
  }]
}

const VoltageOptions = {
  ...DEFAULT_OPTIONS,
  ...{
    title: {
      text: ' ',
      style: {
        fontSize: '24px'
      }
    },
    yAxis: {
      min: 0,
      max: 440,
      lineWidth: 0,
      tickPositions: []
    },
    tooltip: {
      enabled: false
    },
    series: [{
      name: 'Фаза А',
      dataLabels: {
        enabled: true,
        inside: true
      },
      data: [{
        color: '#33b679',
        radius: '112%',
        innerRadius: '88%',
        y: 0,
        dataLabels: {
          enabled: true,
          inside: true,
          align: 'center',
          borderWidth: 0,
          y: -2,
          style: {
            fontWeight: 'bold',
            fontSize: '36px',
            color: '#f3b255',
            fontFamily: "Ubuntu"
          },
          verticalAlign: 'middle',
        }
      }]
    }]
  }
}

const CurrentOptions = {
  ...DEFAULT_OPTIONS,
  ...{
    title: {
      text: ' ',
      style: {
        fontSize: '24px'
      }
    },
    tooltip: {
      enabled: false
    },
    yAxis: {
      min: 0,
      max: 120,
      lineWidth: 0,
      tickPositions: []
    },
    series: [{
      name: 'Фаза А',
      dataLabels: {
        enabled: true,
        inside: true
      },
      data: [{
        color: '#33b679',
        radius: '112%',
        innerRadius: '88%',
        y: 0,
        dataLabels: {
          enabled: true,
          inside: true,
          align: 'center',
          borderWidth: 0,
          y: -2,
          style: {
            fontWeight: 'bold',
            fontSize: '36px',
            color: '#f3b255',
            fontFamily: "Ubuntu"
          },
          verticalAlign: 'middle',
        }
      }]
    }]
  }
}

const PowerOptions = {
  ...DEFAULT_OPTIONS,
  ...{
    title: {
      text: ' ',
      style: {
        fontSize: '24px'
      }
    },
    tooltip: {
      enabled: false
    },
    yAxis: {
      min: 0,
      max: 24.2,
      lineWidth: 0,
      tickPositions: []
    },
    series: [{
      name: 'Фаза А',
      dataLabels: {
        enabled: true,
        inside: true
      },
      overshoot: 335,
      data: [{
        color: '#33b679',
        radius: '112%',
        innerRadius: '88%',
        y: 0,
        dataLabels: {
          enabled: true,
          inside: true,
          align: 'center',
          borderWidth: 0,
          y: -2,
          style: {
            fontWeight: 'bold',
            fontSize: '36px',
            color: '#f3b255',
            fontFamily: "Ubuntu"
          },
          verticalAlign: 'middle',
        }
      }]
    }]
  }
}

const HzOptions = {
  ...DEFAULT_OPTIONS,
  ...{
    title: {
      text: ' ',
      style: {
        fontSize: '24px'
      }
    },
    tooltip: {
      enabled: false
    },
    yAxis: {
      min: 0,
      max: 100,
      lineWidth: 0,
      tickPositions: []
    },
    series: [{
      name: 'Фаза А',
      dataLabels: {
        enabled: true,
        inside: true
      },
      data: [{
        color: '#33b679',
        radius: '112%',
        innerRadius: '88%',
        y: 0,
        dataLabels: {
          enabled: true,
          inside: true,
          align: 'center',
          borderWidth: 0,
          y: -2,
          style: {
            fontWeight: 'bold',
            fontSize: '36px',
            color: '#f3b255',
            fontFamily: "Ubuntu"
          },
          verticalAlign: 'middle',
        }
      }]
    }]
  }
}

const PFAOptions = {
  ...DEFAULT_OPTIONS,
  ...{
    title: {
      text: ' ',
      style: {
        fontSize: '24px'
      }
    },
    tooltip: {
      enabled: false
    },
    yAxis: {
      min: 0,
      max: 2,
      lineWidth: 0,
      tickPositions: []
    },
    series: [{
      name: 'series-1',
      dataLabels: {
        enabled: true,
        inside: true
      },
      overshoot: 15,
      data: [{
        color: '#33b679',
        radius: '112%',
        innerRadius: '88%',
        y: 0,
        dataLabels: {
          enabled: true,
          inside: true,
          align: 'center',
          borderWidth: 0,
          y: -2,
          style: {
            fontWeight: 'bold',
            fontSize: '36px',
            color: '#f3b255',
            fontFamily: "Ubuntu"
          },
          verticalAlign: 'middle',
        }
      }]
    }]
  }
}

const TemperatureOptions = {
  ...DEFAULT_OPTIONS,
  ...{
    title: {
      text: ' ',
      style: {
        fontSize: '24px'
      }
    },
    tooltip: {
      enabled: false
    },
    yAxis: {
      min: -100,
      max: 150,
      lineWidth: 0,
      tickPositions: []
    },
    series: [{
      name: 'series-1',
      dataLabels: {
        enabled: true,
        inside: true
      },
      data: [{
        color: '#33b679',
        radius: '112%',
        innerRadius: '88%',
        y: 0,
        dataLabels: {
          enabled: true,
          inside: true,
          align: 'center',
          borderWidth: 0,
          y: -2,
          style: {
            fontWeight: 'bold',
            fontSize: '36px',
            color: '#f3b255',
            fontFamily: "Ubuntu"
          },
          verticalAlign: 'middle',
        }
      }]
    }]
  }
}

const LevelOptions = {
  ...DEFAULT_OPTIONS,
  ...{
    title: {
      text: ' ',
      style: {
        fontSize: '24px'
      }
    },
    tooltip: {
      enabled: false
    },
    yAxis: {
      min: 0,
      max: 100,
      lineWidth: 0,
      tickPositions: []
    },
    series: [{
      name: 'series-1',
      dataLabels: {
        enabled: true,
        inside: true
      },
      data: [{
        color: '#33b679',
        radius: '112%',
        innerRadius: '88%',
        y: 0,
        dataLabels: {
          enabled: true,
          inside: true,
          align: 'center',
          borderWidth: 0,
          y: -2,
          style: {
            fontWeight: 'bold',
            fontSize: '36px',
            color: '#f3b255',
            fontFamily: "Ubuntu"
          },
          verticalAlign: 'middle',
        }
      }]
    }]
  }
}

interface ChartTypes {
  name: string
  label: string
  value: string
  options: any
  measure: string
  min?: number
  max?: number
}

export const CHART_TYPES: ChartTypes[] = [
  {
    name: 'Напруга',
    label: 'Напруга',
    value: 'voltage',
    options: VoltageOptions,
    measure: 'Вольт',
    min: 0,
    max: 440
  },
  {
    name: 'Струм',
    label: 'Струм',
    value: 'current',
    options: CurrentOptions,
    measure: 'Ампер',
    min: 0,
    max: 120
  },
  {
    name: 'Повна Потужність',
    label: 'Повна Потужність',
    value: 'power',
    options: PowerOptions,
    measure: 'кВА',
    min: 0,
    max: 24.2
  },
  {
    name: 'Активна Потужність',
    label: 'Активна Потужність',
    value: 'powerActive',
    options: PowerOptions,
    measure: 'кВт',
    min: 0,
    max: 24.2
  },
  {
    name: 'Реактивна Потужність',
    label: 'Реактивна Потужність',
    value: 'powerReactive',
    options: PowerOptions,
    measure: 'кВАр',
    min: 0,
    max: 24.2
  },
  {
    name: 'Всього Активна Потужність',
    label: 'Всього Активна Потужність',
    value: 'powerAllActive',
    options: PowerOptions,
    measure: 'кВт',
    min: 0,
    max: 72.6
  },
  {
    name: 'Всього Реактивна Потужність',
    label: 'Всього Реактивна Потужність',
    value: 'powerAllReactive',
    options: PowerOptions,
    measure: 'кВАр',
    min: 0,
    max: 72.6
  },
  {
    name: 'Всього Повна Потужність',
    label: 'Всього Повна Потужність',
    value: 'powerAll',
    options: PowerOptions,
    measure: 'кВА',
    min: 0,
    max: 72.6
  },
  {
    name: 'Частота',
    label: 'Частота',
    value: 'Hz',
    options: HzOptions,
    measure: 'Гц',
    min: 0,
    max: 100
  },
  {
    name: 'Коефіціент потужності',
    label: 'Коефіціент потужності',
    value: 'PFA',
    options: PFAOptions,
    measure: ' ',
    min: 0,
    max: 2
  },
  {
    name: 'Температура корпуса',
    label: 'Температура корпуса',
    value: 'temperature',
    options: TemperatureOptions,
    measure: 'Градусів',
    min: -50,
    max: 100
  },
  {
    name: 'Температура',
    label: 'Температура',
    value: 'act_tempTH',
    options: TemperatureOptions,
    measure: 'Градусів',
    min: -50,
    max: 100
  },
  {
    name: 'Рівень',
    label: 'Рівень',
    value: 'act_s20mA',
    options: LevelOptions,
    measure: 'м',
    min: 0,
    max: 100
  }
];

export const CHART_TYPES_TOP_323_HT5: ChartTypes[] = [
  {
    name: 'Напруга',
    label: 'Напруга',
    value: 'voltage',
    options: VoltageOptions,
    measure: 'Кіловольт',
    min: 0,
    max: 200
  },
  {
    name: 'Струм',
    label: 'Струм',
    value: 'current',
    options: CurrentOptions,
    measure: 'Ампер',
    min: 0,
    max: 120
  },
  {
    name: 'Повна Потужність',
    label: 'Повна Потужність',
    value: 'power',
    options: PowerOptions,
    measure: 'кВА',
    min: 0,
    max: 24.2
  },
  {
    name: 'Активна Потужність',
    label: 'Активна Потужність',
    value: 'powerActive',
    options: PowerOptions,
    measure: 'кВт',
    min: 0,
    max: 24.2
  },
  {
    name: 'Реактивна Потужність',
    label: 'Реактивна Потужність',
    value: 'powerReactive',
    options: PowerOptions,
    measure: 'кВАр',
    min: 0,
    max: 24.2
  },
  {
    name: 'Всього Активна Потужність',
    label: 'Всього Активна Потужність',
    value: 'powerAllActive',
    options: PowerOptions,
    measure: 'кВт',
    min: 0,
    max: 72.6
  },
  {
    name: 'Всього Реактивна Потужність',
    label: 'Всього Реактивна Потужність',
    value: 'powerAllReactive',
    options: PowerOptions,
    measure: 'кВАр',
    min: 0,
    max: 72.6
  },
  {
    name: 'Частота',
    label: 'Частота',
    value: 'Hz',
    options: HzOptions,
    measure: 'Гц',
    min: 0,
    max: 100
  },
  {
    name: 'Коефіціент потужності',
    label: 'Коефіціент потужності',
    value: 'PFA',
    options: PFAOptions,
    measure: ' ',
    min: 0,
    max: 2
  },
  {
    name: 'Температура корпуса',
    label: 'Температура корпуса',
    value: 'temperature',
    options: TemperatureOptions,
    measure: 'Градусів',
    min: -50,
    max: 100
  },
  {
    name: 'Температура',
    label: 'Температура',
    value: 'act_tempTH',
    options: TemperatureOptions,
    measure: 'Градусів',
    min: -50,
    max: 100
  }
];

export const CHART_TYPES_KMA_111_40: ChartTypes[] = [
  {
    name: 'Напруга',
    label: 'Напруга',
    value: 'voltage',
    options: VoltageOptions,
    measure: 'Вольт',
    min: 0,
    max: 440
  },
  {
    name: 'Струм',
    label: 'Струм',
    value: 'current',
    options: CurrentOptions,
    measure: 'Ампер',
    min: 0,
    max: 120
  },
  {
    name: 'Активна Потужність',
    label: 'Активна Потужність',
    value: 'powerActive',
    options: PowerOptions,
    measure: 'кВт',
    min: 0,
    max: 24.2
  },
  {
    name: 'Температура корпуса',
    label: 'Температура корпуса',
    value: 'temperature',
    options: TemperatureOptions,
    measure: 'Градусів',
    min: -50,
    max: 100
  }
];

export const CHART_TYPES_KCP_303_60: ChartTypes[] = [
  {
    name: 'Напруга',
    label: 'Напруга',
    value: 'voltage',
    options: VoltageOptions,
    measure: 'Вольт',
    min: 0,
    max: 440
  },
  {
    name: 'Частота',
    label: 'Частота',
    value: 'Hz',
    options: HzOptions,
    measure: 'Гц',
    min: 0,
    max: 100
  },
  {
    name: 'Температура корпуса',
    label: 'Температура корпуса',
    value: 'temperature',
    options: TemperatureOptions,
    measure: 'Градусів',
    min: -50,
    max: 100
  }
];

export const CHART_TYPES_TOT_104_TH3: ChartTypes[] = [
  {
    name: 'Температура корпуса',
    label: 'Температура корпуса',
    value: 'temperature',
    options: TemperatureOptions,
    measure: 'Градусів',
    min: -50,
    max: 120
  },
  {
    name: 'Температура',
    label: 'Температура',
    value: 'act_tempTH',
    options: TemperatureOptions,
    measure: 'Градусів',
    min: -50,
    max: 120
  },
  {
    name: 'Температура T1',
    label: 'Температура T1',
    value: 'act_tempTH0',
    options: TemperatureOptions,
    measure: 'Градусів',
    min: -50,
    max: 100
  },
  {
    name: 'Температура T2',
    label: 'Температура T2',
    value: 'act_tempTH1',
    options: TemperatureOptions,
    measure: 'Градусів',
    min: -50,
    max: 100
  },
  {
    name: 'Температура T3',
    label: 'Температура T3',
    value: 'act_tempTH2',
    options: TemperatureOptions,
    measure: 'Градусів',
    min: -50,
    max: 100
  }
];

export const CHART_TYPES_TOT_106_TH6: ChartTypes[] = [
  {
    name: 'Температура корпуса',
    label: 'Температура корпуса',
    value: 'temperature',
    options: TemperatureOptions,
    measure: 'Градусів',
    min: -50,
    max: 100
  },
  {
    name: 'Температура 1-3',
    label: 'Температура 1-3',
    value: 'act_tempTH13',
    options: TemperatureOptions,
    measure: 'Градусів',
    min: -50,
    max: 100
  },
  {
    name: 'Температура 4-6',
    label: 'Температура 4-6',
    value: 'act_tempTH46',
    options: TemperatureOptions,
    measure: 'Градусів',
    min: -50,
    max: 100
  },
  {
    name: 'Температура T1',
    label: 'Температура T1',
    value: 'act_tempTH0',
    options: TemperatureOptions,
    measure: 'Градусів',
    min: -50,
    max: 100
  },
  {
    name: 'Температура T2',
    label: 'Температура T2',
    value: 'act_tempTH1',
    options: TemperatureOptions,
    measure: 'Градусів',
    min: -50,
    max: 100
  },
  {
    name: 'Температура T3',
    label: 'Температура T3',
    value: 'act_tempTH2',
    options: TemperatureOptions,
    measure: 'Градусів',
    min: -50,
    max: 100
  },
  {
    name: 'Температура T4',
    label: 'Температура T4',
    value: 'act_tempTH3',
    options: TemperatureOptions,
    measure: 'Градусів',
    min: -50,
    max: 100
  },
  {
    name: 'Температура T5',
    label: 'Температура T5',
    value: 'act_tempTH4',
    options: TemperatureOptions,
    measure: 'Градусів',
    min: -50,
    max: 100
  },
  {
    name: 'Температура T6',
    label: 'Температура T6',
    value: 'act_tempTH5',
    options: TemperatureOptions,
    measure: 'Градусів',
    min: -50,
    max: 100
  }
];

const DATA_LABEL_OBJ = {
  enabled: true,
  inside: true,
  align: 'center',
  borderWidth: 0,
  y: -2,
  style: {
    fontWeight: 'bold',
    fontSize: '36px',
    height: '36px',
    color: '#f3b255',
    fontFamily: "Ubuntu"
  },
  verticalAlign: 'middle',
};

const DATA_LABEL_OBJ_1 = {
  enabled: true,
  inside: true,
  align: 'center',
  borderWidth: 0,
  y: -37,
  style: {
    fontWeight: 'bold',
    fontSize: '18px',
    height: '18px',
    color: '#f3b255',
    textOutline: 'none',
    fontFamily: "Ubuntu"
  }
};

const DATA_LABEL_OBJ_2 = {
  enabled: true,
  inside: true,
  align: 'center',
  borderWidth: 0,
  y: -12,
  style: {
    fontWeight: 'bold',
    fontSize: '18px',
    height: '18px',
    textOutline: 'none',
    color: '#55f363',
    fontFamily: "Ubuntu"
  }
};

const DATA_LABEL_OBJ_3 = {
  enabled: true,
  inside: true,
  align: 'center',
  borderWidth: 0,
  y: 13,
  style: {
    fontWeight: 'bold',
    fontSize: '18px',
    height: '18px',
    textOutline: 'none',
    color: '#f355e5',
    fontFamily: "Ubuntu"
  }
};

const DATA_LABEL_OBJ_4 = {
  enabled: true,
  inside: true,
  align: 'center',
  borderWidth: 0,
  y: 13,
  style: {
    fontWeight: 'bold',
    fontSize: '18px',
    height: '18px',
    textOutline: 'none',
    color: '#5864f3',
    fontFamily: "Ubuntu"
  }
};

const DATA_LABEL_OBJ_5 = {
  enabled: true,
  inside: true,
  align: 'center',
  borderWidth: 0,
  y: 13,
  style: {
    fontWeight: 'bold',
    fontSize: '18px',
    height: '18px',
    textOutline: 'none',
    color: '#0ab7f3',
    fontFamily: "Ubuntu"
  }
};

const DATA_LABEL_OBJ_6 = {
  enabled: true,
  inside: true,
  align: 'center',
  borderWidth: 0,
  y: 13,
  style: {
    fontWeight: 'bold',
    fontSize: '18px',
    height: '18px',
    textOutline: 'none',
    color: '#16f3d8',
    fontFamily: "Ubuntu"
  }
};

const DATA_LABEL_OBJ_3PHASE: any[] = [DATA_LABEL_OBJ_1, DATA_LABEL_OBJ_2, DATA_LABEL_OBJ_3, DATA_LABEL_OBJ_4, DATA_LABEL_OBJ_5, DATA_LABEL_OBJ_6];

@Component({
  selector: "real-time-chart",
  templateUrl: "../templates/real-time-chart.html",
  styleUrls: ["../styles/real-time-chart.scss"]
})

export class RealTimeChartComponent implements OnInit, OnDestroy, OnChanges {
  @Output() onChangeChart = new EventEmitter<string>();
  @Output() onChangeLiveChart = new EventEmitter<string>();
  @Input() monitoringData: MonitoringData;
  @Input() settings: any;
  @Input() energySettings: any;
  @Input() levelSettings: any;
  @Input() defaultChart: string | undefined;
  @Input() serialNumber: string;
  @Input() isLiveChartActive: boolean;
  @Input() typeRelay: string;
  @Input() chartTypes: any = CHART_TYPES;

  currentSerialNumber: string;
  currentValue: number;
  measure: string;
  choosenChart: string = DEFAULT_CHART;

  chartGauge : ChartComponent | any | undefined;
  optionsGauge: any = copyObj({...VoltageOptions});

  subscriptions: any = [];
  intervals: any = [];

  constructor() {

  }

  ngOnInit() {
   this.choosenChart = this.defaultChart? this.defaultChart: DEFAULT_CHART;

    let options: ChartTypes | undefined = this.chartTypes.find((item: any) => {
      return item.value === this.choosenChart
    });

    if (options) {
      options = copyObj(options);
      options = this.convertOptions(options);
      this.optionsGauge = options.options;
      this.measure = options.measure;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.monitoringData) {
      const dataGauge = this.getData(changes.monitoringData.currentValue, changes.defaultChart && changes.defaultChart.currentValue || this.choosenChart);

      if (!this.chartGauge) {
        return;
      }

      if (this.chartGauge.series.length < dataGauge.length) {
        const options = this.optionsGauge;
        options.series[0].data = [dataGauge[0]];
        options.series[1] = {...options.series[0], name: 'series-2', data: [dataGauge[1]]};
        options.series[2] = {...options.series[0], name: 'series-3', data: [dataGauge[2]]};
        this.optionsGauge = options;
        this.chartGauge.addSeries(options.series[1], true, false);
        this.chartGauge.addSeries(options.series[2], true, false);

      }else if (this.chartGauge.series.length > dataGauge.length) {
        const options = this.optionsGauge;
        options.series = [options.series[0]]
        options.series[0].data = [dataGauge[0]];
        this.optionsGauge = options;

        this.chartGauge.series[5] && this.chartGauge.series[2].remove();
        this.chartGauge.series[4] && this.chartGauge.series[2].remove();
        this.chartGauge.series[3] && this.chartGauge.series[2].remove();
        this.chartGauge.series[2] && this.chartGauge.series[2].remove();
        this.chartGauge.series[1] && this.chartGauge.series[1].remove();
        this.chartGauge.series[0] && this.chartGauge.series[0].remove();
        this.chartGauge.addSeries(options.series[0], true, false);
      }

      for (let i = 0; i < dataGauge.length; i++) {
        if (this.chartGauge.series[i]) {
          this.chartGauge.series[i].data[0].update(dataGauge[i]);
        }
      }

    }

    if (changes.energySettings) {
      let options: ChartTypes | undefined = this.chartTypes.find((item: any) => {
        return item.value === this.choosenChart;
      });

      if (options) {
        options = copyObj(options);
        options = this.convertOptions(options);
        this.optionsGauge = options.options;
        this.measure = options.measure;
      }
    }
  }

  ngOnDestroy() {

  }

  getDividerByChartName(chartName: string): number {
    if (chartName === "act_s20mA") {
      return 1000;
    }

    return 100;
  }

  getData(monitoringData: MonitoringData, chartName: string) {
    const results = []

    let value: number | number[] | undefined = monitoringData[this.choosenChart];

    if (value === undefined) {
      return results
    }

    const divider = this.getDividerByChartName(chartName);

    if (typeof value === 'number') {
      value = Math.round(value * divider)/divider;
      this.currentValue = value;
      const dataGauge = {
        color: '#33b679',
        radius: '112%',
        innerRadius: '88%',
        y: value,
        dataLabels: DATA_LABEL_OBJ
      }

      results.push(dataGauge)
    }else if (typeof value === 'object' && value.length) {
      const graphWidth = 24;
      const oneWidth = graphWidth/value.length;
      let currentWidth = 112;

      for (let i = 0; i < value.length; i ++) {
        const dataGauge = {
          color: DATA_LABEL_OBJ_3PHASE[i].style.color,
          radius: `${currentWidth}%`,
          innerRadius: `${currentWidth - oneWidth + 1}%`,
          y: Math.round(value[i] * divider)/divider,
          dataLabels: DATA_LABEL_OBJ_3PHASE[i]
        }

        currentWidth = currentWidth - oneWidth;
        results.push(dataGauge);
      }

    }
    return results;
  }

  saveInstanceGauge(chartInstance: ChartComponent) {
    const options = this.chartTypes.find((item: any) => {
      return item.value === this.choosenChart
    });

    if (!options) {
      console.log('No options for - ', this.choosenChart);
      return;
    }

    let container = document.getElementsByClassName('real-time-chart-container')[0],
      width;

    width = container ? (container.clientWidth - 10) : '100%';

    this.chartGauge = chartInstance;
    this.chartGauge['setSize'](width);
    this.chartGauge.redraw()
  }

  saveChoosenChartLocally(choosenChart: string) {
    this.onChangeChart.emit(choosenChart);
  }

  reloadChart(choosenChart: {label: string, value: string}) {
    this.choosenChart = choosenChart.value;
    this.saveChoosenChartLocally(this.choosenChart);

    let options = this.chartTypes.find((item: any) => {
      return item.value === this.choosenChart
    });

    if (!this.chartGauge || !options) {
      return;
    }

    if (options) {
      options = copyObj(options);
      options = this.convertOptions(options);
      this.optionsGauge = options.options;
      this.measure = options.measure;
    }
  }

  convertOptions(options) {
    if (options.value === 'voltage') {
      let voltageMax = this.energySettings && this.energySettings.voltageKoefU1 || 230;

      if (this.typeRelay === TOP_323_HT5 || this.typeRelay === TOP_326_HT5) {
        voltageMax = this.energySettings && this.energySettings.voltageKoefU1? this.energySettings.voltageKoefU1 / 1000: 230;
      }
      options.options.yAxis.max = 2 * voltageMax;
    }else if (options.value === 'current') {
      const currentMax = this.energySettings && this.energySettings.currentKoefI1 || 60;

      options.options.yAxis.max = 2 * currentMax
    }else if (options.value === 'powerActive' || options.value === 'power' || options.value === 'powerReactive') {
      const voltageMax = this.energySettings && this.energySettings.voltageKoefU1 || 230;
      const currentMax = this.energySettings && this.energySettings.currentKoefI1 || 60;
      const powerMax = voltageMax * currentMax / 1000;

      options.options.yAxis.max = 2 * powerMax;
    }else if (options.value === 'powerAllActive' || options.value === 'powerAllReactive') {
      const voltageMax = this.energySettings && this.energySettings.voltageKoefU1 || 230;
      const currentMax = this.energySettings && this.energySettings.currentKoefI1 || 60;
      const powerMax = voltageMax * currentMax / 1000;

      options.options.yAxis.max = 6 * powerMax;
    }else if (options.value === 'act_s20mA') {
      const levelMax = this.levelSettings && this.levelSettings.fullS20maValue || 100;

      options.options.yAxis.max = 1.2 * levelMax;
    }

    return options;

  }

  getCurrentChart() {
    return this.chartTypes.find((item: any) => {
      return item.value === this.choosenChart
    });
  }

  changeLiveChart() {
    this.onChangeLiveChart.emit(this.choosenChart)
  }

  onResize(event: any) {
    let width;

    let container = document.getElementsByClassName('real-time-chart-container')[0];

    width = container ? (container.clientWidth - 10) : '100%';

    if (!this.chartGauge) return;

    this.chartGauge['setSize'](width);
    this.chartGauge['redraw']();
  }
}
