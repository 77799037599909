import {
  Component,
  OnInit,
  OnDestroy,
  Input
} from "@angular/core";

import {
  RelayService
} from "../services/relay-service";


import {NavigationStart, Router, ActivatedRoute, ParamMap} from '@angular/router';

import 'rxjs/add/operator/switchMap';
import * as moment from "moment";

import {ChartModule} from 'angular2-highcharts';

import {RelayStatsCmp} from './relay-stats.component';
import {UserService} from "../../user/services/user-service";
import {NotifyService} from "../../lib/services/notify.service";
import {FormControl} from "@angular/forms";
import {ChoosenDateType, DateOptionsType} from "../relay.types";
import {
  energyDateTypes,
  KCP_301_60,
  KCP_303_60,
  KMT_101_40,
  PHASE_3_RELAY_TYPES,
  TOT_104_TH3,
  TOT_106_TH6
} from '../constants'
import {convertArrayToCsv, getSerialDigitsFromSerialNumber} from "../services/helper";


@Component({
  selector: "relay-stats",
  templateUrl: "../templates/relay-stats.html",
  styleUrls: ["../styles/relay-stats.scss"]
})
export class RelayPowerCmp extends RelayStatsCmp implements OnInit, OnDestroy {
  isPublicPage: boolean = false;
  energyDateTypes: DateOptionsType[] = [...energyDateTypes];
  allowDateTypes = ['today', 'yesterday', 'custom'];
  dateType: ChoosenDateType;
  type: string = 'power';
  title: string = 'Потужність';
  user: any;
  relays: any;
  loading: boolean = true;
  loadingData: boolean = true;
  relay: any = {};
  is3Phase: boolean = false;
  isCsvDropdownOpen: boolean = false;

  constructor(public _relayService: RelayService,
              private _userService: UserService,
              private _route: ActivatedRoute,
              public _notifyService: NotifyService,
              private router: Router) {
    super(_relayService, _notifyService);
    this.setLoading(true);
    const temp = []
    this.energyDateTypes.forEach((item, i) => {
      if (this.allowDateTypes.indexOf(item.value) !== -1) {
        temp.push({...item})
      }
    });
    this.energyDateTypes = temp;
    console.log('RelayStatsCmp constructor', super.getLoading());

    let options = {
      global: {
        useUTC: false
      },
      title: {text: ''},
      yAxis: {
        title: {
          text: 'Вт'
        },
        opposite:false
      },
      tooltip: {
        crosshairs: true,
        shared: true,
        valueSuffix: ' Вт',
        valueDecimals: 3
      },
      legend: {
        enabled: true
      }
    };

    this.options = {...this.options, ...options};
    this.options3 = {...this.options3, ...options};
  }

  ngOnInit() {
    this.isPublicPage = !!window.location.href.match(/\/public\//);
    this.setLoading(true);

    let sn = this._route.snapshot.paramMap.get('sn');
    if (!sn) {
      let snArr = window.location.href.match(/relay\/(\w*)/);

      sn = snArr?snArr[1]:'';
    }

    this._route.paramMap
      .subscribe((success) => {
        this.setLoading(true);
        this.setLoadingData(true);
        this.generateOptions();
        let dateParams = this._relayService.getDate();
        const diff = dateParams.to - dateParams.from;
        const isPeriodBig = diff > 24 * 60 * 60 * 1000;

        if (this.allowDateTypes.indexOf(dateParams.mode) === -1 || isPeriodBig) {
          dateParams = this._relayService.saveDate(undefined);
        }

        this.dateString = dateParams.dateString;
        this.dateType = dateParams;

        this.relay = this._relayService.getLocal(sn);

        if (this.relay.typeRelay === KCP_301_60 || this.relay.typeRelay === KCP_303_60) {
          this.router.navigate([`/relay/${sn}/voltage`]);
        }else if (this.relay.typeRelay === TOT_104_TH3 || this.relay.typeRelay === TOT_106_TH6) {
          this.router.navigate([`/relay/${sn}/temperature`]);
        }else if (this.relay.typeRelay === KMT_101_40) {
          this.router.navigate([`/relay/${sn}/schedule`]);
        }

        this.is3Phase = PHASE_3_RELAY_TYPES.includes(this.relay.typeRelay);

        this._relayService.getReadingsByType(this.type,this.dateType.from, this.dateType.to)
          .subscribe(
            (success) => {
              console.log('get readings onInit ', success);

              this.values['voltageKoefs'] = success['voltageKoefs'];
              this.values['currentKoefs'] = success['currentKoefs'];
              if (success['range'].length) {
                if (!this.chart) return;
                this.values['line'] = success['line'];
                this.values['range'] = success['range'];
                this.values['limits'] = success['limits'];
                this.values['rangeMinMax'] = success['rangeMinMax'];
                this.values['limitsMinMax'] = success['limitsMinMax'];

                this.chart['series'][0].setData(this.values['line'], false, true, false);
                this.chart['series'][1].setData(this.values['range'], false, true, false);
                this.chart['series'][2].setData(this.values['limits'],false, true, false);
                this.chart['redraw']();
              }
              if (success['rangeA'].length) {
                if (!this.chart3) return;
                this.values['lineA'] = success['lineA'];
                this.values['rangeA'] = success['rangeA'];

                this.values['limits'] = success['limits'];

                this.values['rangeAMinMax'] = success['rangeAMinMax'];
                this.values['rangeBMinMax'] = success['rangeBMinMax'];
                this.values['rangeCMinMax'] = success['rangeCMinMax'];
                this.values['limitsMinMax'] = success['limitsMinMax'];

                this.chart3['series'][0].setData(this.values['lineA'], false, true, false);
                this.chart3['series'][1].setData(this.values['rangeA'], false, true, false);

                this.values['lineB'] = success['lineB'];
                this.values['rangeB'] = success['rangeB'];

                this.chart3['series'][2].setData(this.values['lineB'], false, true, false);
                this.chart3['series'][3].setData(this.values['rangeB'], false, true, false);

                this.values['lineC'] = success['lineC'];
                this.values['rangeC'] = success['rangeC'];

                this.chart3['series'][4].setData(this.values['lineC'], false, true, false);
                this.chart3['series'][5].setData(this.values['rangeC'], false, true, false);

                this.chart3['series'][6].setData(this.values['limits'],false, true, false);

                this.chart3['redraw']();
              }
              this.setLoading(false);
              this.setLoadingData(false);
            }
          );
      })

    let user = this._userService.getLocalUser()

    if (this.isPublicPage) {
      return;
    }

    if (user && user['_id']){
      this.user = user;
      this.relays = user['relays'];
    }else {
      let user = JSON.parse(localStorage.getItem('currentUser'));
      if (user) {
        let userId = user._id;
        this._userService.getUser(userId)
          .subscribe((user) => {
            this.user = user;
            this.relays = user['relays'];
          }, (e) => {
            console.log(e);
          })
      }else {
        this.user = {};
      }

    }
  }

  ngOnDestroy() {
    console.log('RelayStatsCmp Destroy');
    this._relayService.saveDate(this.dateType);
  }

  generateOptions() {
    let options = {
      title: {text: ''},
      yAxis: {
        title: {
          text: 'Вт'
        },
        opposite: false
      },
      tooltip: {
        crosshairs: true,
        shared: true,
        valueSuffix: ' Вт',
        valueDecimals: 2,
        formatter: function () {
          const pointLine = this.points && this.points[0];
          const pointRange = this.points && this.points[1];

          if (!pointLine || !pointRange) {
            return '';
          }

          const timeHeader = `<p>${moment(+pointLine.x).format('DD MMM HH:mm')}</p><br/>`;
          return `${timeHeader}<p><span style="color:${this.points[0].color}; margin-right:10px;">\u25CF</span><b>${this.points[0].y.toFixed(2)} Вт. </b> <span>від ${this.points[1].point.low.toFixed(2)} до ${this.points[1].point.high.toFixed(2)} Вт</span></p>`;
        }
      },
      legend: {
        enabled: true
      }
    };

    this.options = {...this.options, ...options};
    this.options3 = {...this.options3, ...options};

    this.options3.tooltip = {
      ...this.options3.tooltip,
      formatter: function () {
        let row1 = '';
        let row2 = '';
        let row3 = '';
        let timeHeader = '';

        let pointLine = this.points && this.points[0];
        let pointRange = this.points && this.points[1];
        if (pointLine && pointRange) {
          timeHeader = `<p>${moment(+pointLine.x).format('DD MMM HH:mm')}</p><br/>`;
          row1 = `<p><span style="color:${this.points[0].color}; margin-right:10px;">\u25CF</span><b>${this.points[0].y.toFixed(2)} Вт. </b> <span>від ${this.points[1].point.low.toFixed(2)} до ${this.points[1].point.high.toFixed(2)} Вт</span></p>`;
          row1 += '<br/>';
        }

        pointLine = this.points && this.points[2];
        pointRange = this.points && this.points[3];
        if (pointLine && pointRange) {
          row2 = `<p><span style="color:${this.points[2].color}; margin-right:10px;">\u25CF</span><b>${this.points[2].y.toFixed(2)} Вт. </b> <span>від ${this.points[3].point.low.toFixed(2)} до ${this.points[3].point.high.toFixed(2)} Вт</span></p>`;
          row2 += '<br/>';
        }

        pointLine = this.points && this.points[4];
        pointRange = this.points && this.points[5];
        if (pointLine && pointRange) {
          row3 = `<p><span style="color:${this.points[4].color}; margin-right:10px;">\u25CF</span><b>${this.points[4].y.toFixed(2)} Вт. </b> <span>від ${this.points[5].point.low.toFixed(2)} до ${this.points[5].point.high.toFixed(2)} Вт</span></p>`;
        }

        return timeHeader + row1 + row2 + row3;
      }
    }
  }

  downloadChartCsv(spreadsheetType: 'excel' | 'google') {
    this.isCsvDropdownOpen = false;
    window.removeEventListener('click', this.outCsvClick);

    if (this.is3Phase) {
      if (!this.values || !this.values["lineA"] || !this.values["lineA"].length) {
        // no data
        this._notifyService.showMessage({
          message: 'Немає даних',
          type: 'error'
        })
        return;
      }
    }else {
      if (!this.values || !this.values["line"] || !this.values["line"].length) {
        // no data
        this._notifyService.showMessage({
          message: 'Немає даних',
          type: 'error'
        })
        return;
      }
    }

    try {
      const csvData = convertArrayToCsv(
        this.is3Phase? this.getChartDataArray3Phase(): this.getChartDataArray(),
        spreadsheetType
      );
      let csvContent = `data:text/csv;charset=utf-8,${csvData}`

      const from = moment(this.dateType.from).format('DDMMYYYY');
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      const dateName = from;
      link.setAttribute("download", `${getSerialDigitsFromSerialNumber(this.relay.serialNumber)}Power${dateName}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }catch(e) {
      console.log(e);
      this._notifyService.showMessage({
        message: 'Вибачте, сталася помилка. Спробуйте ще раз.',
        type: 'error'
      })
    }
  }

  getChartDataArray3Phase(): string[][] {
    const result = [];

    const type = this.relay.typeRelay;
    const SN = this.relay.serialNumber;

    const headerName = [`${type}. s/n: ${SN}`];
    result.push(headerName)
    let koefHeader = this.values.voltageKoefs.length? `Voltage coefficients - ${this.values.voltageKoefs.join(', ')}`: null
    if (koefHeader) {
      result.push([koefHeader]);
    }
    koefHeader = this.values.currentKoefs.length? `Current coefficients - ${this.values.currentKoefs.join(', ')}`: null
    if (koefHeader) {
      result.push([koefHeader], []);
    } else {
      result.push([], []);
    }

    const from = moment(this.dateType.from).format('DD.MM.YYYY');
    const to = moment(this.dateType.to).format('DD.MM.YYYY');
    const timeHeader = `Power ${from} - ${to}`;

    result.push([timeHeader])
    const dateFormat = 'DD.MM.YYYY'

    const header = ['Date'];
    const measureHeader = [dateFormat]

    header.push('Time');
    measureHeader.push('hh:mm');

    header.push('Phase A', 'Phase B', 'Phase C');
    measureHeader.push('kW', 'kW', 'kW');

    result.push(header, measureHeader)

    for (let i = 0; i < this.values.lineA.length; i++) {
      let timestamp = this.values.lineA[i][0];
      const row = []
      row.push(moment(timestamp).format(dateFormat))

      row.push(moment(timestamp).format('HH:mm'))

      const valueA = this.values.lineA[i] && typeof this.values.lineA[i][1] === "number"?
        "" + this.values.lineA[i][1] : "";

      const valueB = this.values.lineB[i] && typeof this.values.lineB[i][1] === "number"?
        "" + this.values.lineB[i][1] : "";

      const valueC = this.values.lineC[i] && typeof this.values.lineC[i][1] === "number"?
        "" + this.values.lineC[i][1] : "";
      row.push(valueA, valueB, valueC)

      result.push(row);
    }

    return result;
  }

  getChartDataArray(): string[][] {
    const result = [];

    const type = this.relay.typeRelay;
    const SN = this.relay.serialNumber;

    const headerName = [`${type}. s/n: ${SN}`];
    result.push(headerName)
    let koefHeader = this.values.voltageKoefs.length? `Voltage coefficients - ${this.values.voltageKoefs.join(', ')}`: null
    if (koefHeader) {
      result.push([koefHeader]);
    }
    koefHeader = this.values.currentKoefs.length? `Current coefficients - ${this.values.currentKoefs.join(', ')}`: null
    if (koefHeader) {
      result.push([koefHeader], []);
    } else {
      result.push([], []);
    }

    const from = moment(this.dateType.from).format('DD.MM.YYYY');
    const to = moment(this.dateType.to).format('DD.MM.YYYY');
    const timeHeader = `Power ${from} - ${to}`;

    result.push([timeHeader])
    const dateFormat = 'DD.MM.YYYY'

    const header = ['Date'];
    const measureHeader = [dateFormat]

    header.push('Time');
    measureHeader.push('hh:mm');

    header.push('Power');
    measureHeader.push('kW');

    result.push(header, measureHeader)

    for (let i = 0; i < this.values.line.length; i++) {
      let timestamp = this.values.line[i][0];
      const row = []
      row.push(moment(timestamp).format(dateFormat))

      row.push(moment(timestamp).format('HH:mm'))

      const value = this.values.line[i] && typeof this.values.line[i][1] === "number"?
        "" + this.values.line[i][1] : "";
      row.push(value)

      result.push(row);
    }

    return result;
  }
}
