import {
  Component, OnInit
} from "@angular/core";

import { Title }     from '@angular/platform-browser';

import { environment } from '../environments/environment';

import {AuthService} from "./auth/services/auth-service";

@Component({
	selector: "app",
  templateUrl: "app.html",
  styleUrls: ["app.scss"]
})
export class App {
  environment: any = environment;
  year: number = new Date().getFullYear()

  public constructor(public _authService: AuthService, private titleService: Title) {
    this.titleService.setTitle( environment.title );
  }
}
