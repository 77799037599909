import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'save-changes-modal',
  templateUrl: './save-changes-modal.html',
})
export class saveChangesModal {
  constructor(
    public dialogRef: MatDialogRef<saveChangesModal>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }
}
