import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {NotifyService} from "../../../lib/services/notify.service";
import {FormControl, Validators} from '@angular/forms';

@Component({
  selector: 'ask-firmware-modal',
  template: `
    <div class="modal">
      <div class="modal-header">
        <div class="modal-title">
          Ви впевнені?
        </div>
        <div class="modal-close">
          <button mat-icon-button mat-dialog-close="false" aria-hidden="true" autofocus="false">
            <mat-icon aria-label="false" aria-hidden="true">close</mat-icon>
          </button>
        </div>
      </div>
      <mat-dialog-content>
        <div class="modal-body">
          <div>
            обрані прилади: {{relaysSN}}.
          </div>
          <div>
           Фільтр: серійний номер - {{serialNumberFilter}}, прошивка - {{firmwareFilter}}
          </div>
          <div>
            прошивка: {{choosenFirmware}}
          </div>
        </div>
      </mat-dialog-content>

      <mat-dialog-actions>
        <div class="modal-bottom">
          <button mat-raised-button color="primary" (click)="save()" cdkFocusInitial="true">Оновити</button>
        </div>
      </mat-dialog-actions>
    </div>
  `,
  styleUrls: ["../../../relay/styles/relay-settings.scss", "../../../relay/styles/relay-schedule.scss"]
})
export class AskFirmwareModal {
  choosenFirmware: string = '';
  serialNumberFilter: string = '';
  firmwareFilter: string = '';
  relaysSN: string;

  constructor(
    public dialogRef: MatDialogRef<AskFirmwareModal>,
    private notifyService: NotifyService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.choosenFirmware = data.choosenFirmware;
      this.serialNumberFilter = data.serialNumberFilter;
      this.firmwareFilter = data.firmwareFilter;
      this.relaysSN = (data.relaysSN || []).join(', ');
  }

  save() {
    this.dialogRef.close({
      success: true,
    });
  }
}
