import {
  Component,
  OnInit,
  OnDestroy
} from "@angular/core";
import {MatDialog} from "@angular/material";

import {Location} from "@angular/common";

import {
  Validators,
  FormGroup,
  FormControl
} from "@angular/forms";


import {
  RelayService
} from "../services/relay-service";

import {Socket} from 'ng-socket-io';

import {NotifyService} from '../../lib/services/notify.service';

import {NavigationStart, Router, ActivatedRoute, ParamMap} from '@angular/router';

import 'rxjs/add/operator/switchMap';

import {ChartModule} from 'angular2-highcharts';
import {getToken} from '../../utils/authTokens';
import {UserService} from "../../user/services/user-service";
import { getSyncCounterData, calculateSyncCounterOffset, SyncCounterType} from '../services/helper'
import { Zone } from './settings/settings.price.component';
import {
  TEP_321_T01, TOP_323_T5, TOP_323_T01, TOP_326_T01, TCP_320_T5, KMA_LIKE_RELAY_TYPES, LOG_MESSAGES, TOP_323_HT5, TOP_326_T5,
  KCP_301_60, TOC_326_T5, TOP_326_HT5, VOLTAGE_KOEF_RELAY_TYPES, CURRENT_KOEF_RELAY_TYPES, TEMPERATURE_RELAY_TYPES,
  KCP_303_60, TOT_104_TH3, KMT_101_40, RelayNames, SensorsNames, getDefaultRelayNames, getDefaultSensorNames, TOP_RELAYS, TOT_106_TH6
} from '../constants'
import {ScheduleEventDialog} from "./schedule-modal.component";
import {MqttIntegrationModal} from "./modals/mqtt-integration.modal";
import {TelegramIntegrationModal} from "./modals/telegram-integration.modal";
import {WebhookIntegrationModal} from "./modals/webhook-integration.modal";

const DEFAULT_SYNC_COUNTER: SyncCounterType = {
  show: false,
  value: 0,
  offset: 0,
};

interface EnergyType {
  reactive: boolean;
  full: boolean;
  voltageKoef: number;
  voltageKoefU1: number;
  voltageKoefU2: number;
  showVoltageTT: boolean;
  currentKoef: number;
  currentKoefI1: number;
  currentKoefI2: number;
  showCurrentTT: boolean;
}

const DEFAULT_ENERGY_SETTINGS: EnergyType = {
  reactive: false,
  full: false,
  voltageKoef: 1,
  voltageKoefU1: 230,
  voltageKoefU2: 230,
  showVoltageTT: false,
  currentKoef: 1,
  currentKoefI1: 60,
  currentKoefI2: 60,
  showCurrentTT: false,
};

export interface PublicTabs {
  voltage: boolean;
  current: boolean;
  energy: boolean;
  monitoring: boolean;
  temperature: boolean;
}

interface MQTT {
  status: 'on' | 'off';
  broker: string;
  port: number | null;
  user: string;
  pass: string;
}

const DEFAULT_MQTT: MQTT = {
  status: 'off',
  broker: '',
  port: null,
  user: '',
  pass: ''
}

@Component({
  selector: "relay-settings-cmp",
  templateUrl: "../templates/relay-settings.html",
  styleUrls: ["../styles/relay-settings.scss"]
})
export class RelaySettingsCmp implements OnInit, OnDestroy {
  TEMPERATURE_RELAY_TYPES = [...TEMPERATURE_RELAY_TYPES];
  loading: boolean = true;
  relayId: string;
  relaySN: string;
  isOwner: boolean = false;
  isActualSettings: boolean = false;
  isPublicRelay: boolean;
  publicTabs: PublicTabs = {
    voltage: true,
    current: true,
    energy: true,
    monitoring: true,
    temperature: false,
  };
  isOnFrontPage: boolean;
  publicRelayName: string;
  //rateSettings
  rateSettings: any = {};
  zoneType: string = '';
  KMA_LIKE_RELAY_TYPES: string[] = KMA_LIKE_RELAY_TYPES;
  showVoltageKoefs: boolean = false;
  showCurrentKoefs: boolean = false;
  KCP_301_60: string = KCP_301_60;
  KCP_303_60: string = KCP_303_60;
  TOC_326_T5: string = TOC_326_T5;
  TOT_104_TH3: string = TOT_104_TH3;
  TOT_106_TH6: string = TOT_106_TH6;
  KMT_101_40: string = KMT_101_40;
  TOP_RELAYS: string[] = TOP_RELAYS;

  rateDomesticTypes: any = [
    {
      value: 1,
      description: 'Населенню (у тому числі яке проживає в житлових будинках, обладнаних кухонними електроплитами) (у тому числі в сільській місцевості)',
      text: 'Населенню, у томі числі з кухонними електроплитами'
    },
    {
      value: 2,
      description: 'Населенню, яке проживає в житлових будинках (у тому числі в житлових будинках готельного типу, квартирах та гуртожитках), обладнаних у встановленому порядку електроопалювальними установками (у тому числі в сільській місцевості)',
      text: 'З електроопалювальними установками'
    },
    {
      value: 3,
      description: 'Населенню, яке проживає в багатоквартирних будинках, не газифікованих природним газом і в яких відсутні або не функціонують системи централізованого теплопостачання (у тому числі в сільській місцевості)',
      text: 'Не газифікованих природним газом'
    },
    {
      value: 4,
      description: 'Для багатодітних, прийомних сімей та дитячих будинків сімейного типу незалежно від обсягів споживання електроенергії',
      text: 'Для багатодітних та дитячих будинків'
    },
    {
      value: 5,
      description: 'Населенню, яке розраховується з енергопостачальною організацією за загальним розрахунковим засобом обліку та об’єднане шляхом створення юридичної особи, житлово-експлуатаційним організаціям, крім гуртожитків',
      text: 'З загальним розрахунковим засобом обліку'
    },
    {
      value: 6,
      description: 'Гуртожиткам (які підпадають під визначення «населення, яке розраховується з енергопостачальною організацією за загальним розрахунковим засобом обліку»)',
      text: 'Гуртожиткам'
    }
  ];

  rateZoneTypes: any = [
    {
      value: 'common',
      description: 'Єдиний тариф без диференціювання за періодами часу',
      text: 'Загальний тариф'
    },
    {
      value: '2Zone',
      description: 'За двозонним тарифом, диференційованими за періодами часу: 0,5 тарифу в години нічного мінімального навантаження енергосистеми (з 23-ї години до 7-ї години)',
      text: 'Двозонний диференційований'
    },
    {
      value: '3Zone',
      description: 'За тризонними тарифами, диференційованими за періодами часу: 1,5 тарифу в години максимального навантаження енергосистеми (з 8-ї години до 11-ї години і з 20-ї години до 22-ї години); повний тариф у напівпіковий період (з 7-ї години до 8-ї години, з 11-ї години до 20-ї години, з 22-ї години до 23-ї години); 0,4 тарифу в години нічного мінімального навантаження енергосистеми (з 23-ї години до 7-ї години).',
      text: 'Тризонний диференційований'
    }
  ]
  subscriptions: any = [];
  users: any = [];
  myUserId: string;
  settings = {
    confirm: false,
    common: {
      timeToOn: 30,
      autoTurn: true
    },
    voltage: {
      min: 198,
      max: 242,
      diff: 5,
      enable: false
    },
    current: {
      max: 60000,
      category: '2',
      enable: false
    },
    power: {
      max: 12,
      enable: false
    },
    frequency: {
      min: 49.9,
      max: 50.1,
      diff: 0.1,
      enable: false
    },
    temperature: {
      max: 70,
      enable: false
    },
    difCurrent: {
      max: '300',
      enable: false
    }
  };
  usersRestriction: any = {};
  energySettings: EnergyType = {
    ...DEFAULT_ENERGY_SETTINGS
  };

  syncCounter: SyncCounterType = {
    ...DEFAULT_SYNC_COUNTER
  };
  typeRelay: string;
  mqtt: MQTT = {...DEFAULT_MQTT};
  lastSettings: any = {};
  cards: any = {
    common: false,
    voltage: false,
    current: false,
    power: false,
    frequency: false,
    temperature: false,
    difCurrent: false
  };
  intervals: any = [];

  logs: any = [];
  page: number = 1;
  firstPage:boolean = true;
  lastPage: boolean = true;
  limit: number = 50;
  lengthLogs: number = 0;
  loadedTelegram: boolean = false;
  hasTelegramId: boolean = false;
  sendLogsTelegram: boolean = false;
  integrationEndpoint: string = '';
  integrationEndpointError: boolean = false;
  showIntegrationForm: boolean = false;
  relay: any = {};
  syncCounterChanged: boolean = false;
  syncCounterValueChanged: boolean = false;
  tot_posEnergyPA: number = 0;
  energySettingsChanged: boolean = false;
  levelSettingsChanged: boolean = false;
  changedRelayNames: boolean = false;
  changedSensors: boolean = false;
  priceZones: Zone[] = [];
  levelSettings: any = {
    zeroS20maValue: 0,
    fullS20maValue: 0,
    showLevel: false
  };
  isSuperAdmin: boolean = false;
  relayNames: RelayNames | null = null;
  sensorNames: SensorsNames | null = null;
  TEP_321_T01: string = TEP_321_T01;
  telegramModal: any;
  telegramSwitchOnCancel = false;
  mqttSwitchOnCancel = false;
  webhookSwitchOnCancel = false;

  constructor(private _relayService: RelayService,
              private _userService: UserService,
              private route: ActivatedRoute,
              private router: Router,
              private socket: Socket,
              private location: Location,
              private notifyService: NotifyService,
              private _dialog: MatDialog) {

  }

  ngOnInit() {
    let sn = this.route.snapshot.paramMap.get('sn');

    if (!sn) {
      let snArr = window.location['href'].match(/relay\/(\w*)/);

      sn = snArr?snArr[1]:'';
    }

    this.relaySN = sn

    const me = this._userService.getLocalUser();

    this.isSuperAdmin = me && !!me['isAdmin'];

    this.route.paramMap
      .subscribe((params) => {
        this.relaySN = params.get('sn')
        if (!this.relaySN) {
          let snArr = window.location.href.match(/relay\/(\w*)/);

          this.relaySN = snArr ? snArr[1] : '';
        }

        this._relayService.getSettings(this.relaySN)
          .subscribe(
            (success) => {
              for (let key in success['settings']) {
                if (success['settings'][key] && success['settings'][key]) {
                  if (key === 'current') {
                    this.settings[key].category = success['settings'][key] && '' + success['settings'][key].category;
                    this.settings[key].max = success['settings'][key] && success['settings'][key].max/1000;
                    this.settings[key].enable = !!success['settings'][key].enable;
                  }else if (key === 'difCurrent') {
                    this.settings[key].max = success['settings'][key] && '' + success['settings'][key].max;
                    this.settings[key].enable = !!success['settings'][key].enable;
                  }else if(key === 'power') {
                    this.settings[key].max = success['settings'][key] && success['settings'][key].max/1000;
                    this.settings[key].enable = !!success['settings'][key].enable;
                  }else {
                    this.settings[key] = success['settings'][key];
                  }
                }
              }

              this.relayId = success['_id'];
              this.isOwner = success['isOwner'];
              this.hasTelegramId = success['hasTelegramId'];
              this.usersRestriction = success['usersRestriction'] ? success['usersRestriction'] : {};
              this.energySettings = success['energySettings'] ? {...this.energySettings, ...success['energySettings']} : this.energySettings;
              this.isActualSettings = success['isActualSettings'];
              this.isPublicRelay = success['isPublic'];
              this.publicTabs = success['publicTabs'];
              this.isOnFrontPage = success['isOnFrontPage'];
              this.publicRelayName = success['publicName'];
              this.publicRelayName = success['publicName'];
              this.rateSettings = success['rateSettings'] || {};
              this.zoneType = success['zoneType'] || {};
              this.typeRelay = success['typeRelay'] || '';
              this.mqtt = success['mqtt'] && success['mqtt']['status']? success['mqtt']: {...DEFAULT_MQTT};
              this.showVoltageKoefs = VOLTAGE_KOEF_RELAY_TYPES.includes(this.typeRelay);
              this.showCurrentKoefs = CURRENT_KOEF_RELAY_TYPES.includes(this.typeRelay);

              this.priceZones = success['priceZones']? success['priceZones']: [];
              this.relayNames = success['relayNames'] && success['relayNames'].list && success['relayNames'].list.length?
                success['relayNames']: getDefaultRelayNames(this.typeRelay);
              this.sensorNames = success['sensors'] && success['sensors'].list && success['sensors'].list.length?
                success['sensors']: getDefaultSensorNames(this.typeRelay);

              if (this.typeRelay === TOC_326_T5) {
                this.levelSettings = success['levelSettings'] || this.levelSettings;
              }

              this.syncCounter = typeof success['syncCounter'] === 'object'? success['syncCounter']: this.syncCounter;
              this.tot_posEnergyPA = success['tot_posEnergyPA'];

              this.syncCounter = getSyncCounterData(this.syncCounter, this.tot_posEnergyPA, this.energySettings.voltageKoef || 1, this.energySettings.currentKoef || 1);

              this.users = success['users'];
              this.myUserId = success['userId'];
              this.sendLogsTelegram = this.users.find((item) => {return item._id === success['userId'] && item.sendLogsTelegram});
              this.loading = false;
              this.integrationEndpoint = success['webhook']? success['webhook'].endpoint: '';
              this.showIntegrationForm = !!this.integrationEndpoint;

              this.lastSettings = this.copySettings();
              const self = this;
              this.socket.emit('join room', {relayId: this.relayId, token: getToken()});
              if (success['users'] && success['users'].length) {
                let users = success['users'].map((item) => {
                  if (item.username) {
                    item.username = item.username;
                  }

                  if (item.email) {
                    item.email = item.email;
                  }
                })
              }

              const checkModelInterval = setInterval(this.checkModel.bind(this), 500)
              this.intervals.push(checkModelInterval);
            },
            (error) => {
              console.log('error', error);
            });

      });

    this._relayService.getRelayValue()
      .subscribe((relay: any) => {
        if (!relay || !Object.keys(relay).length) {
          return;
        }

        this.relay = relay;
      });


    let changeSettingsEvent = this.socket.fromEvent('server:settings:changed')
      .subscribe(
        (data) => {
          console.log('server:settings:changed', data);
          if (data['ok']) {
            for (let key in data['settings']) {
              if (data['settings'][key]) {
                if (key === 'current') {
                  this.settings[key].category = data['settings'][key] && '' + data['settings'][key].category;
                  this.settings[key].max = data['settings'][key] && data['settings'][key].max/1000;
                  this.settings[key].enable = !!data['settings'][key].enable;
                }else if (key === 'difCurrent') {
                  this.settings[key].max = data['settings'][key] && '' + data['settings'][key].max;
                  this.settings[key].enable = !!data['settings'][key].enable;
                }else if(key === 'power') {
                  this.settings[key].max = data['settings'][key] && data['settings'][key].max/1000;
                  this.settings[key].enable = !!data['settings'][key].enable;
                }else {
                  this.settings[key] = data['settings'][key];
                }
              }
            }

            this.settings.confirm = true;
            this.notifyService.showMessage({
              text: "Налаштування збережені",
              type: "success"
            });
          }
        }
      );

    let mqttHandler =  this.socket.fromEvent('server:mqtt:saved')
      .subscribe(
        (data) => {
          console.log('server:settings:changed', data);
          if (!data['ok'] || data['error']) {
            this.notifyService.showMessage({
              text: 'Сталася помилка при збереженні mqtt',
              type: 'error'
            });
            return;
          }

          this.mqtt = {
            status: data['status'],
            broker: data['broker'],
            port: +data['port'],
            user: data['user'],
            pass: data['pass'],
          }
        })

    this.subscriptions.push(mqttHandler);

    mqttHandler =  this.socket.fromEvent('server:mqtt:currentData')
      .subscribe(
        (data) => {
          console.log('server:mqtt:currentData', data);

          this.mqtt = {
            status: data['status'],
            broker: data['broker'],
            port: +data['port'],
            user: data['user'],
            pass: data['pass'],
          }
        })

    this.subscriptions.push(mqttHandler);
    this.subscriptions.push(changeSettingsEvent);

    this.getLogs(1)
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item) => {
      item.unsubscribe();
    });

    this.intervals.forEach((item) => {
      clearInterval(item);
    });
  }

  chooseTab(event) {
    const label = document.getElementsByClassName('mat-tab-label')[event.index];
    const container = document.getElementsByClassName('mat-tab-label-container')[0];

    if (label && container) {
      container.scrollBy(label['offsetLeft'], 0);
    }
  }

  loadTelegramWidget() {
    const host = window.location.hostname || window.location.host || window.location.href || '';
    let botname = 'buckler_bot';
    if (host.match(/elemeter\.com/)) {
      botname = 'elemeter_bot';
    }
    this.loadedTelegram = true;
    const node = document.createElement('script');
    node.src = 'https://telegram.org/js/telegram-widget.js?7';
    node.type = 'text/javascript';
    node.async = false;
    node.setAttribute('data-telegram-login', botname);
    node.setAttribute('data-size', 'large');
    // Callback function in global scope
    node.setAttribute('data-onauth', 'loginViaTelegram(user)');
    node.setAttribute('data-request-access', 'write');

    const container = document.getElementById('telegram-button');

    if (!container) {
      console.log('no container');
      return
    }

    window['loginViaTelegram'] = this.loginViaTelegram.bind(this);
    container.appendChild(node);
  }

  loginViaTelegram(user) {
    return this._userService.saveTelegram(user.id)
      .subscribe(
        (success) => {
          this.hasTelegramId = true;
          this.notifyService.showMessage({
            text: 'Успішно інтегровано телеграм',
            type: 'success'
          });
          this.telegramModal && this.telegramModal.close();
        },
        (error) => {
          this.notifyService.showMessage({
            text: 'Помилка',
            type: 'error'
          })
        }
      )
  }

  removeTelegram () {
    return this._userService.removeTelegram()
      .subscribe(
        (success) => {
          this.hasTelegramId = false;

          this.notifyService.showMessage({
            text: 'Успішно видалено телеграм',
            type: 'success'
          });
          this.telegramModal && this.telegramModal.close();
        },
        (error) => {
          this.notifyService.showMessage({
            text: 'Помилка',
            type: 'error'
          })
        }
      )

  }

  setLogsViaTelegram(event: any) {
    const { checked, source } = event;
    if (checked && !this.hasTelegramId) {
      this.notifyService.showMessage({
        text: 'Залогінтесь через телеграм',
        type: "error"
      });
      this.telegramSwitchOnCancel = true;
      this.openTelegramModal();
      return;
    }

    this.saveLogsViaTelegram();
  }

  saveLogsViaTelegram() {
    this._relayService.setLogsViaTelegram(this.relaySN, this.sendLogsTelegram)
      .subscribe(
        (success) => {
          this.notifyService.showMessage({
            message: 'Значення змінено успішно',
            type: 'success'
          })
        },
        (error) => {
          this.sendLogsTelegram = !this.sendLogsTelegram
          this.notifyService.showMessage({
            message: 'Сталася помилка',
            type: 'error'
          })
        }
      )
  }

  checkModel(){
    for ( const prop in this.settings) {
      if (typeof this.settings[prop] === 'object'){
        this.lastSettings[prop] = this.lastSettings[prop] || {};
        for(const key in this.settings[prop]) {
          if (this.settings[prop][key] !== this.lastSettings[prop][key]){
            this.cards[prop] = true
          }
        }
      }
    }

    this.lastSettings = this.copySettings();
  }

  copySettings(){
    let newSettings = {}

    for ( const prop in this.settings) {
      if (typeof this.settings[prop] === 'object') {
        newSettings[prop] = {}
        for (const key in this.settings[prop]) {
          newSettings[prop][key] = this.settings[prop][key]
        }
      }
    }

    return newSettings;
  }

  saveSettings(type: string) {
    this.settings.confirm = false;
    let settings = this.copySettings();

    let setToSend = {
      relayId: this.relayId,
      settings: {[type]: settings[type]},
      token: getToken()
    };


    if (type === 'current') {
      setToSend.settings.current.category = +setToSend.settings.current.category
      setToSend.settings.current.max = Math.round(setToSend.settings.current.max*1000)
    }

    if (type === 'difCurrent') {
      setToSend.settings.difCurrent.max = +setToSend.settings.difCurrent.max
    }

    if (type === 'power') {
      setToSend.settings.power.max = Math.round(setToSend.settings.power.max*1000)
    }

    let successSocket = this.socket.emit('user:settings:changed', setToSend, (data)=> {
      if (data['success']) {
        this.notifyService.showMessage({
          text: "Чекаємо підтвердження від приладу",
          type: "success"
        });
        this.cards[type] = false;
      }else {
        this.notifyService.showMessage({
          text: "Сталася помилка збереження налаштувань",
          type: "failed"
        });
      }
    });



    /*this._relayService.saveSettings(this.relayId, this.settings, this.usersRestriction, this.isPublicRelay)
      .subscribe(
        (success) => {
          console.log('success', success);

          this.socket.emit('settings changed', {
            relayId: this.relayId,
            settings: this.settings,
            usersRestriction: this.usersRestriction,
            isPublic: this.isPublicRelay
          });
          this.notifyService.showMessage({
            text: "Wait for confirmation from relay.",
            type: "success"
          });
        },
        (error) => {
          console.log('error', error);
          this.notifyService.showMessage({
            text: "Some problems. Settings did not changed.",
            type: "error"
          });
        });*/
  }

  settingsChanged(event: number, type: string, prop: string){

    console.log('settingsChanged', event, type, prop);
    if (typeof event === 'number') {
      this.settings[type][prop] = event;
      this.cards[type] = true;
    }
  }

  saveUsersRestriction() {
    this._relayService.saveUsersRestriction(this.relaySN, this.usersRestriction)
      .subscribe(
        (success) => {
          console.log('success', success);
          let data = {...this.usersRestriction, token: getToken()}
          this.socket.emit('user:settings:changed', {data}, (data)=> {});
          this.notifyService.showMessage({
            text: "Налаштування збережені.",
            type: "success"
          });
        },
        (error) => {
          console.log('error', error);
          this.notifyService.showMessage({
            text: "Сталася помилка при збереженні налаштувань.",
            type: "error"
          });
        });
  }

  makePublic() {
    const isAnyTabTrue = Object.values(this.publicTabs).find((val) => {
      return val
    });

    if (!isAnyTabTrue) {
      this.notifyService.showMessage({
        text: "Щонайменше 1 вкладка повинна бути доступна.",
        type: "error"
      });
      return;
    }

    if (this.isOnFrontPage && !this.publicRelayName) {
      this.notifyService.showMessage({
        text: "Вкажіть назву приладу на головній сторінці.",
        type: "error"
      });
      return;
    }

    this._relayService.makePublic(this.relaySN, this.isPublicRelay, this.publicTabs, this.isOnFrontPage, this.publicRelayName)
      .subscribe(
        (success) => {
          console.log('success', success);
          this.notifyService.showMessage({
            text: "Налаштування збережені.",
            type: "success"
          });
        },
        (error) => {
          console.log('error', error);
          this.notifyService.showMessage({
            text: "Сталася помилка при збереженні.",
            type: "error"
          });
        });
  }

  changeUser(user: any) {
    this._relayService.changeUsers(this.relaySN, user._id, user.role)
      .subscribe(
        (success) => {
          console.log('success', success);

          user.isChanged = false;
          this.notifyService.showMessage({
            text: "Користувач збережен.",
            type: "success"
          });
        },
        (error) => {
          console.log('error', error);
          let message;
          try {
            message = JSON.parse(error.error);
            message = message.message;
          } catch (e) {
            console.log('e', e);
          }

          message = message || "Some problems. Users did not changed.";
          this.notifyService.showMessage({
            text: "Сталася помилка при збереженні.",
            type: "error"
          });
        });
  }

  removeUser(user: any) {
    let id = user._id;

    this._relayService.removeUser(this.relaySN, user._id)
      .subscribe(
        (success) => {
          console.log('success', success);

          let newUsers = this.users.filter((item) => {
            let itemId = item._id;

            return itemId !== id;
          });

          this.users = newUsers;

          this.notifyService.showMessage({
            text: "Користувач видален.",
            type: "success"
          });
        },
        (error) => {
          console.log('error', error);
          let message;
          try {
            message = JSON.parse(error.error);
            message = message.message;
          } catch (e) {
            console.log('e', e);
          }

          message = message || "Some problems. Users did not changed.";
          this.notifyService.showMessage({
            text: "Сталася помилка при збереженні.",
            type: "error"
          });
        });
  }

  calculateEnergyKoefs(type: string) {
    if (type === 'voltage') {
      this.energySettings.voltageKoef = Math.round(100 * (this.energySettings.voltageKoefU1 || 1) / (this.energySettings.voltageKoefU2 || 1)) / 100
    }else if (type === 'current') {
      this.energySettings.currentKoef = Math.round(100 * (this.energySettings.currentKoefI1 || 1) / (this.energySettings.currentKoefI2 || 1)) / 100
    }
  }

  saveEnergySettings() {
    let errors = false;
   /* if (this.showKoefs && this.energySettings.voltageKoef && (!this.energySettings.voltageKoefU1 || !this.energySettings.voltageKoefU2)) {
      this.notifyService.showMessage({
        text: `Вкажіть ${this.energySettings.voltageKoefU1 ? 'вторинну': 'первинну'} напругу`,
        type: "error"
      });
      errors = true;
    }

    if (this.showKoefs && this.energySettings.currentKoef && (!this.energySettings.currentKoefI1 || !this.energySettings.currentKoefI2)) {
      this.notifyService.showMessage({
        text: `Вкажіть ${this.energySettings.currentKoefI1 ? 'вторинний': 'первинний'} струм`,
        type: "error"
      });
      errors = true;
    }*/

    let dataToSave = {};

    if (this.energySettingsChanged) {
      this.calculateEnergyKoefs('voltage');
      this.calculateEnergyKoefs('current');

      dataToSave['energySettings'] = {...this.energySettings};

      for (const key in dataToSave['energySettings']) {
        dataToSave['energySettings'][key] = dataToSave['energySettings'][key] || DEFAULT_ENERGY_SETTINGS[key];
      }

    }else {
      dataToSave = {'energySettings': {
          reactive: this.energySettings.reactive,
          full: this.energySettings.full
        }};
    }

    if (this.syncCounterChanged) {
      this.syncCounter.offset = calculateSyncCounterOffset(
        this.syncCounter,
        this.tot_posEnergyPA,
        this.energySettings.voltageKoef || 1,
        this.energySettings.currentKoef || 1
      );
      dataToSave['syncCounter'] = this.syncCounter;
    }

    if (this.levelSettingsChanged) {
      dataToSave['levelSettings'] = {
        zeroS20maValue: this.levelSettings.zeroS20maValue || 0,
        fullS20maValue: this.levelSettings.fullS20maValue || 0,
        showLevel: this.levelSettings.showLevel || false,
      }
    }

    if (this.changedSensors) {
      dataToSave['sensors'] = this.sensorNames;
    }

    if (this.changedRelayNames) {

      dataToSave['relayNames'] = this.relayNames;
    }

    const callback = (success: boolean, relay?: any) => {
      if (success) {
        this.energySettings = relay? relay['energySettings']: this.energySettings;

        this.syncCounter = this.syncCounterValueChanged || this.energySettingsChanged? getSyncCounterData(
          this.syncCounter,
          this.tot_posEnergyPA,
          this.energySettings.voltageKoef || 1,
          this.energySettings.currentKoef || 1
        ): this.syncCounter;

        this.syncCounterValueChanged = false;
        this.syncCounterChanged = false;
        this.energySettingsChanged = false;
        this.levelSettingsChanged = false;
      }
    };

    if (!errors) {
      this.saveOwnerSettings(dataToSave, callback);
    }
  }

  onChangeSyncCounter() {
    this.syncCounterChanged = true;
  }

  onChangeEnergySettings() {
    this.energySettingsChanged = true;
  }

  onChangeLevelSettings() {
    this.levelSettingsChanged = true;
  }

  onChangeRelayNames() {
    this.changedRelayNames = true;
  }

  onChangeSensors() {
    this.changedSensors = true;
  }

  saveOwnerSettings(data: object, callback?: (success: boolean, relay?: any) => void) {

    this._relayService.saveOwnerSettings(this.relaySN, data)
      .subscribe(
        (success) => {
          if (success && success['relay']) {
            this._relayService.saveLocal(success['relay']);
          }

          if (callback) {
            try {
              callback(true, success['relay'])
            }catch(e) {
              console.log(e);
            }
          }

          this.notifyService.showMessage({
            text: "Налаштування збережені.",
            type: "success"
          });
        },
        (error) => {
          console.log('error', error);
          if (callback) {
            try {
              callback(false)
            }catch(e) {
              console.log(e);
            }
          }

          let message;
          try {
            message = JSON.parse(error.error);
            message = message.message;
          } catch (e) {
            console.log('e', e);
          }

          message = message || "Some problems. Settings did not changed.";
          this.notifyService.showMessage({
            text: "Сталася помилка при збереженні.",
            type: "error"
          });
        });
  }

  goBack() {
    this.location.back();
  }

  changeProtectEnable(protectType: string) {
    this.settings[protectType].enable = !this.settings[protectType].enable
  }

  validateProtectSettings(){

  }

  getLogs(page: number) {
    const logTypes = {
      ...LOG_MESSAGES
    };

    this._relayService.getLogs(this.relaySN, page, this.limit)
      .subscribe(
        (success) => {
          const logs = []
          for (let i = 0; i < success['data'].length; i++) {
            const item = success['data'][i];

            const converted = {
              timestamp: item.cp_time * 1000,
              type: logTypes[item.code] || 'Невідомо',
              code: item.code,
              message: item.message,
              cp_time: item.cp_time,
              info: item.info,
            };

            logs.push(converted);
          }
          this.logs = logs;
          this.lengthLogs = success['length'];
          this.page = page;
          this.firstPage = this.page === 1;
          this.lastPage = this.page*this.limit >= this.lengthLogs;
          this.loading = false;
        },
        (error) => {
          this.notifyService.showMessage({
            message: error,
            type: 'error'
          });
          console.log('error', error);
          this.loading = false;
        });
  }

  changePage(page: number) {
    this.getLogs(page);
  }

  saveWebhookIntegration() {
    const urlRegex = new RegExp(/^https?:\/\/[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/);

    if (!this.integrationEndpoint || !this.integrationEndpoint.match(urlRegex)) {
      this.integrationEndpointError = true;
      return;
    }

    this.integrationEndpointError = false;
    this._relayService.saveWebhookIntegration(this.relaySN, this.integrationEndpoint)
      .subscribe(
        (success) => {

          this.notifyService.showMessage({
            text: "Налаштування збережені.",
            type: "success"
          });
        },
        (error) => {
          this.notifyService.showMessage({
            text: "Помилка при збереженні.",
            type: "error"
          });
        });
  }

  removeWebhookIntegration() {
    if (this.showIntegrationForm || !this.integrationEndpoint) {
      return;
    }

    this._relayService.removeWebhookIntegration(this.relaySN)
      .subscribe(
        (success) => {
          this.integrationEndpoint = '';
          this.notifyService.showMessage({
            text: "Налаштування збережені.",
            type: "success"
          });
        },
        (error) => {
          this.notifyService.showMessage({
            text: "Помилка при збереженні.",
            type: "error"
          });
        });
  }

  onChangePrices(changes: {zones: Zone[]}) {
    console.log('changes', changes);
    this.priceZones = changes.zones;
  }

  savePriceZones(changes: {zones: Zone[]}) {
    console.log('savePriceZones', changes);
    this.priceZones = changes.zones;

    this.saveOwnerSettings({priceZones: changes.zones});
  }

  openMqttModal(){
    let dialogRef = this._dialog.open(MqttIntegrationModal, {
      width: '500px',
      data: {
        ...this.mqtt
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('mqtt edit', result);
      if (!result || result === 'false') {
        if (this.mqttSwitchOnCancel) {
          this.mqttSwitchOnCancel = false;
          this.mqtt.status = this.mqtt.status === 'on'? 'off': 'on'
        }
        return;
      }

      this.mqttSwitchOnCancel = false;

      this.mqtt = {...result, status: this.mqtt.status}

      this.saveMqtt();
    })
  }

  saveMqtt() {
    this.socket.emit('user:mqtt:save', {...this.mqtt, relayId: this.relayId}, (data)=> {
      if (!data.success) {
        this.notifyService.showMessage({
          text: 'Сталася помилка',
          type: "error"
        })
      }
    });
  }

  validateMQTTData(data: MQTT) {
    const { broker, port, user, pass } = data;

    if (
      !broker ||
      !user ||
      !pass ||
      !port ||
      isNaN(+port)
    ) {
      return false;
    }

    return true;
  }

  toggleMqtt(event: any) {
    const { checked } = event;

    if (checked) {
      this.mqtt.status = 'on';
      if (!this.validateMQTTData(this.mqtt)) {
        this.showIntegrationForm = false;
        this.openMqttModal();
        this.mqttSwitchOnCancel = true;
        this.notifyService.showMessage({
          text: 'Заповніть всі поля',
          type: "error"
        })
        return;
      }
    }else {
      this.mqtt.status = 'off'
    }

    // save
    this.saveMqtt();
  }

  openTelegramModal() {
    let dialogRef = this._dialog.open(TelegramIntegrationModal, {
      width: '500px',
      data: {
        removeTelegram: this.removeTelegram.bind(this),
        loadTelegramWidget: this.loadTelegramWidget.bind(this),
        hasTelegramId: this.hasTelegramId,
      }
    });

    this.telegramModal = dialogRef;

    dialogRef.afterClosed().subscribe((result) => {
      console.log('openTelegramModal edit', result);
      if (!result || result === 'false') {
        if (this.telegramSwitchOnCancel) {
          this.telegramSwitchOnCancel = false;
          this.sendLogsTelegram = !this.sendLogsTelegram;
        }

        return;
      }

      this.telegramSwitchOnCancel = false;

      if (this.sendLogsTelegram) {
        this.saveLogsViaTelegram();
      }
    })
  }

  toggleWebhook(event: any) {
    const { checked } = event;

    if (checked) {
      if (this.integrationEndpoint) {
        this.saveWebhookIntegration();
      }else {
        this.webhookSwitchOnCancel = true;
        this.openWebhookModal();
      }
    } else {
      this.removeWebhookIntegration();
    }
  }

  openWebhookModal() {
    let dialogRef = this._dialog.open(WebhookIntegrationModal, {
      width: '500px',
      data: {
        integrationEndpoint: this.integrationEndpoint
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('openwebhookModal edit', result);
      if (!result || result === 'false') {
        if (this.webhookSwitchOnCancel) {
          this.webhookSwitchOnCancel = false;
          this.showIntegrationForm = !this.showIntegrationForm;
        }

        return;
      }

      this.webhookSwitchOnCancel = false;

      const {integrationEndpoint} = result;

      this.integrationEndpoint = integrationEndpoint;

      if (this.showIntegrationForm) {
        this.saveWebhookIntegration()
      }
    })
  }
}
