import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input
} from "@angular/core";

interface DropdownDataType {
  label: string
  value: string
}

@Component({
  selector: "relay-time-dropdown",
  templateUrl: "../templates/relay-time-dropdown.html",
  styleUrls: ["../styles/relay-time-dropdown.scss"]
})

export class RealTimeDropdownComponent implements OnInit {
  @Output() onClick = new EventEmitter<DropdownDataType>();
  @Input() dropDownData: DropdownDataType[];
  @Input() currentChoose: DropdownDataType | undefined;
  @Input() boldText: boolean | undefined;
  isMenuOpen: boolean = false;


  constructor() {
    this.outMenuClick = this.outMenuClick.bind(this)
  }

  ngOnInit() {
  }

  outMenuClick(){
    if(this.isMenuOpen) {
      this.isMenuOpen = false;
    }

    document.removeEventListener('click', this.outMenuClick)
  }

  toggleMenu(e) {
    e && e.stopPropagation()
    if (!this.isMenuOpen) {
      document.addEventListener('click', this.outMenuClick)
    }else {
      document.removeEventListener('click', this.outMenuClick)
    }

    this.isMenuOpen = !this.isMenuOpen;
  }

  chooseOption(option: DropdownDataType) {
    this.onClick.emit(option)
    this.currentChoose = option
    this.isMenuOpen = !this.isMenuOpen;

    document.removeEventListener('click', this.outMenuClick)
  }
}
