import {
  Inject,
  Injectable
} from "@angular/core";

import {
  Observable
} from "rxjs";

import {round} from '../../utils/math';
import {HttpClient} from '@angular/common/http';

import {ActivatedRoute} from '@angular/router';

import "rxjs/add/operator/map";

@Injectable()
export class UserService {
  private ENDPOINT: string = 'api/users/';
  user: any;
  relays: any;
  id: string;

  constructor(
    private _authHttp: HttpClient,
    @Inject(HttpClient) private _http: HttpClient,
    private route: ActivatedRoute
  ) {

    try {
      let user = localStorage.getItem('currentUser');

      let token = localStorage.getItem('token')
      let expireAt = localStorage.getItem('tokenExpireAt')
      let isExpire = true

      if (expireAt) {
        expireAt = JSON.parse(expireAt);
        isExpire = +new Date() > +expireAt;
      }


      if (user && token && !isExpire) {
        user = JSON.parse(user);
        this.user = user;
        this.id = user['_id'];
      }
    }catch(e){
      console.log(e);
    }
    console.log('user service init SN ');
  }

  getLocalUser(): Observable<any> {
    return this.user
  }

  getUser(id: string): Observable<any> {
    return this._authHttp.get(this.ENDPOINT + id)
      .map((user) => {
        this.user = user;
        this.relays = this.user.relays;
        return user;
      });
  }

  getMe(): Observable<any> {
    return this._authHttp.get(this.ENDPOINT + 'me')
      .map((user) => {
        this.user = user;
        this.relays = this.user.relays;
        this.saveLocalUser(user);
        return user;
      });
  }

  changeUserInfo(id: string, username: string, email: string, code?: string) {
    const user = {
      username,
      email,
      code
    }

    return this._authHttp.put(this.ENDPOINT + id, user)
      .map((user) => {
        const newUser = {...this.user || {}, ...user}
        this.saveLocalUser(newUser);
        this.user = newUser;

        return user;
      });
  }

  changeEmailAttempt(id: string, email: string) {
    return this._authHttp.post(this.ENDPOINT + id + '/changeEmailAttempt', {email})
      .map((res) => {
        return res;
      });
  }

  getRelaysStats(id): Observable<any> {
    return this._authHttp.get(this.ENDPOINT + this.user._id + '/relay')
      .map((success) => {
        return success;
      });
  }

  getlastRelayReadings(): Observable<any> {
    return this._authHttp.get(this.ENDPOINT + this.user._id + '/lastReadings')
      .map((success) => {
        return success;
      });
  }

  saveLocalUser(user: any): boolean{
    try {
      localStorage.setItem('currentUser', JSON.stringify(user));
      this.user = user;
      this.relays = user.relays;
    }catch(e){
      console.log(e);
      return false
    }

    return true
  }

  changeName(userId: string, id: string, name: string) {
    return this._authHttp.put(
      this.ENDPOINT +userId + "/relay/name",
      {
        relay: {
          _id: id,
          name: name
        }
      }
    )
  }

  deleteRelay(userId: string, id: string) {
    return this._authHttp.delete(this.ENDPOINT + userId + "/relay/" + id)
  }

  saveTelegram(telegramId: number): Observable<any> {
    return this._authHttp.post(this.ENDPOINT + 'saveTelegram', {telegramId: telegramId})
      .map((data) => {
        return data;
      });
  }

  removeTelegram(): Observable<any> {
    return this._authHttp.delete(this.ENDPOINT + 'saveTelegram')
      .map((data) => {
        return data;
      });
  }
}
