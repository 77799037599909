import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Validators, FormControl } from "@angular/forms";

import {UserService} from '../services/user-service'
import {NotifyService} from "../../lib/services/notify.service";

@Component({
  selector: "user-settings-cmp",
  templateUrl: "../templates/userSettings.html",
  styleUrls: ["../styles/user.scss"]
})

export class UserSettingsComponent implements OnInit{
  title: string = "Settings";
  username: string;
  email: string;
  password: string;
  id?: string;
  model: any = {};
  user: any;
  loading = false;
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  usernameFormControl = new FormControl('', [
    Validators.required
  ]);
  passwordFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern('([\\w-!@#$^&*]{6,20})')
  ]);
  codeFormControl = new FormControl('', [
    Validators.required
  ]);
  emailChanged: boolean = false;
  emailChangeCode: string = '';
  sendEmailTimestamp: number = 0;
  timeToAllowSend: string = '';

  constructor(
    private _userService: UserService,
    private _router: Router,
    private _notifyService: NotifyService
  ) {

  }

  ngOnInit() {
    let user = this._userService.getLocalUser();

    if (user && user['_id']){
      this.user = user;
      this.username = user['username'];
      this.email = user['email'];
      this.loading = false;
    }else {
      this._userService.getMe()
        .subscribe(
          (user) => {
            this.user = user;
            this.username = user['username'];
            this.email = user['email'];
            this.loading = false;
          }
        )
    }
  }

  onChangeEmail() {
    this.emailChanged = false;
    this.sendEmailTimestamp = 0;
  }

  changeEmailAttempt() {
    const allowSendEmail = Date.now() - this.sendEmailTimestamp > 30 * 1000;

    if (!allowSendEmail) {
      return;
    }

    this.emailChangeCode = '';

    this._userService.changeEmailAttempt(this.user._id, this.email)
      .subscribe(
        (res) => {
          this.emailChanged = true;
          this.sendEmailTimestamp = Date.now();

          // send email
          const interval = setInterval(() => {
            const timeToSend = 30;
            const timeSpend = Math.round((Date.now() - this.sendEmailTimestamp) / 1000);
            const timeLeft = timeToSend - timeSpend;

            if (timeLeft > 1) {
              this.timeToAllowSend = `(${timeLeft})`;
            }else {
              clearInterval(interval);
              this.timeToAllowSend = '';
            }

          }, 1000);
        },
        (err) => {
          this._notifyService.showMessage(
            {
              text: 'Сталася помилка. Спробуйте ще раз пізніше.',
              type: 'error',
              duration: 15000
            }
          );
        }
    );
  }

  changeUserInfo() {
    if (this.email !== this.user['email'] && !this.emailChanged) {
      this.changeEmailAttempt();
      return;
    }else if (this.email !== this.user['email'] && this.emailChanged && !this.emailChangeCode) {
      this._notifyService.showMessage(
        {
          text: 'Введіть код підтвердження з емейлу',
          type: 'error',
          duration: 15000
        }
      );
      return;
    }

    this._userService.changeUserInfo(this.user._id, this.username, this.email, this.emailChangeCode)
      .subscribe(
        (user) => {
          this.emailChanged = false;
          this.emailChangeCode = '';
          this.timeToAllowSend = '';
          this.user = user;
          this.username = user['username'];
          this.email = user['email'];
          this.loading = false;
          this._notifyService.showMessage(
            {
              text: 'Дані змінено',
              type: 'success'
            }
          );
        },
        (error) => {
          console.log(error);
          this.loading = false;
          const customError = error.error && error.error.customError;

          let message = 'Сталася помилка';

          if (customError === 'CODE_MISMATCH') {
            message = 'Невірний код підтвердження';
          }else if (customError === 'EMAIL_TAKEN') {
            message = 'Користувач з таким емейлом вже існує';
          }

          this._notifyService.showMessage(
            {
              text: message,
              type: 'error',
              duration: 15000
            }
          );
        }
      )
  }

}
