import {
  Component,
  OnInit,
  OnDestroy
} from "@angular/core";

import {
  Validators,
  FormGroup,
  FormControl
} from "@angular/forms";

import {
  RelayService
} from "../services/relay-service";

import { Socket } from 'ng-socket-io';
import {RelayCmp} from './relay-cmp';

import { NavigationStart, Router, ActivatedRoute, ParamMap } from '@angular/router';

import 'rxjs/add/operator/switchMap';
import {MatDialog} from "@angular/material";

import { ChartModule } from 'angular2-highcharts';
import {NotifyService} from "../../lib/services/notify.service";
import {getToken} from '../../utils/authTokens';
import {UserService} from "../../user/services/user-service";
import {HttpClient} from "@angular/common/http";

import {
  KMA_111_40,
  TOP_326_T01,
  TOP_323_T01,
  TOP_323_T5,
  TOP_323_HT5,
  TOP_RELAYS,
  TEMPERATURE_RELAY_TYPES,
  KCP_323_60,
  TOP_326_T5,
  KCP_301_60,
  KCP_303_60,
  TOT_104_TH3,
  TOT_106_TH6,
  TOP_324_T5,
  TOP_324_T01
} from '../constants';
import {PublicTabs} from "./relay-settings.component";


@Component({
  selector: "relay-cmp",
  templateUrl: "../templates/relay-public.html",
  styleUrls: ["../styles/relay.scss"]
})
export class RelayPublicCmp extends RelayCmp implements OnInit, OnDestroy {
  notPublic: boolean = false;
  publicRelays: any = [];
  TOP_326_T01: string = TOP_326_T01;
  TOP_326_T5: string = TOP_326_T5;
  TOP_323_T01: string = TOP_323_T01;
  TOP_323_T5: string = TOP_323_T5;
  TOP_323_HT5: string = TOP_323_HT5;
  KCP_301_60: string = KCP_301_60;
  TOT_104_TH3: string = TOT_104_TH3;
  TOT_106_TH6: string = TOT_106_TH6;
  SWITCHER_3_TYPES: string[] = [TOP_323_T01, TOP_323_T5, KCP_323_60, TOP_323_HT5, KCP_303_60];
  TOP_RELAYS: string[] = TOP_RELAYS;
  TEMPERATURE_RELAY_TYPES: string[] = TEMPERATURE_RELAY_TYPES;
  KMA_111_40: string = KMA_111_40;
  publicTabs: PublicTabs = {
    voltage: true,
    current: false,
    energy: false,
    monitoring: false,
    temperature: false,
  };

  constructor(
    private p_relayService: RelayService,
    private p_route: ActivatedRoute,
    private p_router: Router,
    private p_socket: Socket,
    private p_notifyService: NotifyService,
    private p_dialog: MatDialog,
    private p_userService: UserService,
    private _http: HttpClient
  ) {
    super(p_relayService, p_route, p_router, p_socket, p_notifyService, p_dialog, p_userService);

    this.relaySN = this.p_route.snapshot.paramMap.get('sn');

    this.p_route.paramMap
      .subscribe((params) => {
          this.relaySN = params.get('sn')
          if (!this.relaySN) {
            let snArr = window.location.href.match(/relay\/(\w*)/);

            this.relaySN = snArr ? snArr[1] : '';
          }

        this.p_relayService.get(this.relaySN)
          .subscribe((success) => {
            const relay = success["relay"];

            this.publicTabs = relay && relay.publicTabs || this.publicTabs;
        });
        }
      );

    this._http
      .get('api/relay/public')
      .subscribe(
        (response) => {
          this.publicRelays = response['relays'];

          this.loading = false;
        },
        (error) => {
          console.log('errr', error);
          this.loading = false;
        }
      );
  }

  makeAction(on: boolean): void {
    // do not do anything, because its public relay
  }

  choosePublicRelay() {
    let subpage
    let match = window.location.href.match(/relay\/\w*\/(\w+)/);
    subpage = match ? match[1].toString() : 'energy';
    this.p_router.navigate([`/public/relay/${this.relaySN}/${subpage}`]);
  }
}
